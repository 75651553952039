import { reify } from '../normalize';
import { UserLevel, CompanyUserRole } from '../user';
import { SearchResultCompany, SearchResultUser } from './types';

export const SearchUsersResultEntityName = 'SearchUsersResult';
export const SearchCompaniesResultEntityName = 'SearchCompaniesResult';

export function normalizeSearchResultUser(
  inputData: any = {},
): SearchResultUser {
  const data = reify(inputData);
  return {
    id: data.getString('id'),
    level: data.getString('level') as UserLevel,
    fullName: data.getString('full_name'),
    login: data.getString('login'),
    email: data.getString('email'),
    status: data.getString('status'),
    roles: data.getArray('roles') as CompanyUserRole[],
    creation: data.getString('creation'),
    lastLogin: data.getString('last_login'),
    lastInvitation: data.getString('last_invitation'),
    supervisorName: data.getString('supervisor_name'),
    companyId: data.getString('company_id'),
    companyName: data.getString('company_name'),
    companyStatus: data.getString('company_status'),
    domainId: data.getString('domain_id'),
    domainName: data.getString('domain_name'),
  };
}

export function normalizeSearchResultCompany(
  inputData: any = {},
): SearchResultCompany {
  const data = reify(inputData);
  return {
    id: data.getString('id'),
    domainId: data.getString('domain_id'),
    domainName: data.getString('domain_name'),
    companyName: data.getString('company_name'),
    country: data.getString('country'),
    status: data.getString('status'),
    creation: data.getString('creation'),
    defaultCurrency: data.getString('default_currency'),
  };
}
