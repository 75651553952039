import Button, { ButtonKind, ButtonTheme } from 'components/elements/Button';
import { useLayoutContext } from 'contexts/layout';
import Logo from '../Logo';

import styles from './styles.module.css';
import UserActions from '../UserActions';

const MobileHeader = () => {
  const {
    actions: { toggleNavMenu },
  } = useLayoutContext();

  return (
    <header className={styles.container} data-testid="mobileHeader">
      <Button
        label="Show navigation"
        iconOnly
        wrapperClassName={styles.closeBtn}
        onClick={toggleNavMenu}
        iconName="burger"
        theme={ButtonTheme.Discrete}
        kind={ButtonKind.Neutral}
        dark
      />
      <div className={styles.logoWrapper}>
        <Logo />
      </div>
      <UserActions isDark />
    </header>
  );
};

export default MobileHeader;
