import { ReactNode } from 'react';
import isFunction from 'lodash/isFunction';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { DeviceSize, useWindowContext } from 'contexts/window';
import MultiStepTracker, {
  Step as MultiStepTrackerStep,
} from 'components/elements/MultistepTracker';
import Content, { Theme } from './Content';
import useConfirmDialog from './useConfirmDialog';
import Modal from '../Modal';
import styles from './MultiStepModalForm.module.css';

export interface Step extends MultiStepTrackerStep {
  hideHeader?: boolean;
  content: ReactNode;
  sideContent?: ReactNode;
  initialValues?: Record<string, any>;
  validate?: (
    values: Record<string, any>,
  ) =>
    | FormikErrors<Record<string, any>>
    | Promise<FormikErrors<Record<string, any>>>;
  onSubmit?: (
    values: Record<string, any>,
    actions: FormikHelpers<Record<string, any>>,
  ) => void;
  onSubmitError?: (
    // this, in case of an error came while submitting
    error: Error,
    actions: FormikHelpers<Record<string, any>>,
  ) => void;
  // in some cases after submit we don't want to go to next step
  isPartialSubmit?: (values: Record<string, any>) => boolean;
}

type Props = {
  title?: ReactNode;
  currentStep: MultiStepTrackerStep['key'];
  onChangeStep: (key: MultiStepTrackerStep['key']) => void;
  steps: Step[];
  onSubmitError?: (error: any) => void;
  onClose?: (arg: any) => void;
  withBackground?: boolean;
  isOpen?: boolean;
  testId?: string;
  withHeadline?: boolean;
  theme?: Theme;
  logo?: ReactNode;
  contentDesktopWidth?: number;
};

const MultiStepForm = ({
  isOpen,
  onClose,
  title,
  steps,
  currentStep,
  onChangeStep,
  testId,
  withBackground,
  withHeadline,
  onSubmitError,
  theme,
  logo,
  contentDesktopWidth,
}: Props) => {
  const { deviceSize } = useWindowContext();

  const mainContentWidth =
    deviceSize === DeviceSize.desktop ? contentDesktopWidth : undefined;

  const currentStepIdx = Math.max(
    steps.findIndex((step) => step.key === currentStep),
    0,
  );

  const { confirmDialog, handleOnClose } = useConfirmDialog(onClose, isOpen);

  const {
    key,
    initialValues,
    validate,
    onSubmit,
    onSubmitError: onStepSubmitError,
    isPartialSubmit,
    sideContent,
    content,
    hideHeader,
    label,
  } = steps[currentStepIdx];

  const goToNextStep = () => {
    const nextStep = currentStepIdx + 1;

    if (nextStep < steps.length) {
      onChangeStep(steps[nextStep].key);
    }
  };

  const handleSubmit = async (
    values: Record<string, any>,
    actions: FormikHelpers<Record<string, any>>,
  ) => {
    try {
      if (onSubmit) {
        await onSubmit(values, actions);
      }
      if (!isPartialSubmit || (isPartialSubmit && !isPartialSubmit(values))) {
        goToNextStep();
      }
    } catch (err) {
      if (isFunction(onStepSubmitError)) {
        onStepSubmitError(err, actions);
      } else if (isFunction(onSubmitError)) {
        onSubmitError(err);
      }
    }
  };

  if (!isOpen) {
    return null;
  }
  return (
    <Formik
      // use the key property of each step here to force formik remount when changing step
      // to make sure it always has the values/initialValues/onSubmit/validate of the current step
      key={key}
      initialValues={initialValues || {}}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ values, dirty }) => (
        <Modal
          isOpen
          showCloseBtnOnly
          className={styles.modal}
          contentClassName={styles.content}
          withBackground={withBackground}
          onClose={onClose ? () => handleOnClose(values, dirty) : undefined}
        >
          <Form data-testid={testId}>
            <Content
              logo={logo}
              title={title || `Step ${currentStepIdx + 1} - ${label}`}
              subTitle={<MultiStepTracker steps={steps} currentStepKey={key} />}
              testId={testId}
              content={content}
              sideContent={sideContent}
              hideHeader={hideHeader}
              withHeadline={withHeadline}
              mainContentWidth={mainContentWidth}
              theme={theme}
            />
          </Form>
          {confirmDialog}
        </Modal>
      )}
    </Formik>
  );
};
MultiStepForm.defaultProps = {
  title: undefined,
  onClose: undefined,
  withBackground: true,
  onSubmitError: undefined,
  isOpen: false,
  testId: '',
  withHeadline: false,
  theme: undefined,
  logo: undefined,
  contentDesktopWidth: undefined,
};
export default MultiStepForm;
