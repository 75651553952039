import classNames from 'classnames';
import RadioButtonInput from 'components/composed/RadioButtonInput';
import { ChangeEvent } from 'react';
import styles from './RadioGroupTimeline.module.css';

export interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value: string;
  values: string[];
  disabled?: boolean;
  name: string;
}

function RadioGroupTimeline({
  className,
  onChange,
  disabled,
  value,
  values,
  name,
}: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.line}>
        {values.map((v) => (
          <RadioButtonInput
            className={styles.radio}
            key={`${name}-${v}`}
            value={v}
            name={name}
            disabled={disabled}
            checked={value === v}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
}

RadioGroupTimeline.defaultProps = {
  disabled: false,
  className: undefined,
};

export default RadioGroupTimeline;
