import { Entity } from '../types';
import { UUID } from '../';
import { reify } from '../normalize';
import { TxSummary, normalizeTxSummary } from './txSummary';
import { mapSegmentCoding } from './budget';

type Amount = {
  amount: number;
  currency: string;
};

export interface ArchivedBudget extends Entity {
  id: UUID;
  approvers: Record<string, string>[];
  closedAt: string;
  closer: Record<string, string>;
  codings: Record<string, string>;
  company: Record<string, string>;
  creation: string;
  endDate: string;
  lastUpdate: string;
  maxAmount: Amount;
  members: Record<string, string>[];
  merchant: string;
  name: string;
  orderNumber: string;
  owner: Record<string, string>;
  spentAmount: Amount;
  startDate: string;
  linkedTxs: TxSummary[];
}

export function normalizeArchivedBudget(result: any): ArchivedBudget {
  const data = reify(result);
  return {
    entityType: 'archivedBudget',
    id: data.getUuid('budget_id'),
    approvers: data.getArray('approvers'),
    closedAt: data.getString('end_date'),
    closer: data.get('closer'),
    codings: mapSegmentCoding(data.getArray('codings')),
    company: data.get('company'),
    creation: data.getString('creation'),
    endDate: data.getString('end_date'),
    lastUpdate: data.getString('last_update'),
    maxAmount: data.get('max_amount'),
    members: data.getArray('members'),
    merchant: data.getString('merchant'),
    name: data.getString('name'),
    orderNumber: data.getString('order_number'),
    owner: data.get('owner'),
    spentAmount: data.get('spent_amount'),
    startDate: data.getString('start_date'),
    linkedTxs: data.getArray('linked_txs').map(normalizeTxSummary),
  };
}
