import { reify } from '../normalize';
import { normalizeAddress } from '../address';
import {
  Company,
  CompanyStatus,
  COMPANY_EXTERNAL_ACCOUNTING_LINKS,
  Vendor,
  UserVendor,
  AccountingConfig,
  AccountingConfigStatus,
  CreditCardMappingPreferences,
  CashAccountMappingPreferences,
  CompanyExtractService,
} from './types';

export const CompanyEntityName = 'Company';

export function normalizeVendor(inputData: any = {}): Vendor {
  const data = reify(inputData);
  return {
    id: data.getString('id'),
    displayName: data.getString('display_name'),
  };
}
export function normalizeUserVendor(inputData: any = {}): UserVendor {
  const data = reify(inputData);
  return {
    userId: data.getUuid('user_id'),
    vendorId: data.getString('vendor_id'),
  };
}

export function normalizeAccountiongConfig(
  inputData: any = {},
): AccountingConfig {
  const data = reify(inputData);
  return {
    configStatus: data.mapEnum(
      AccountingConfigStatus,
      'accountingConfigStatus',
    ),
    cardTxMappingPreferences: data.mapEnum(
      CreditCardMappingPreferences,
      'cardTxMappingPreferences',
    ),
    singleCardTxVendorId: data.getString('singleCardTxVendorId'),
    cashTxMappingPreferences: data.mapEnum(
      CashAccountMappingPreferences,
      'cashTxMappingPreferences',
    ),
    singleCashTxVendorId: data.getString('singleCashTxVendorId'),
  };
}
export function normalizeAccountClassificationCount(inputData: any = {}): {
  assets: number;
  expenses: number;
} {
  return {
    // TODO in case we rework other accounting systems update this
    assets: inputData.asset,
    expenses: inputData.expense,
  };
}
export function normalizeCompany(input: object): Company {
  const data = reify(input);

  const companyAddress = data.map('address', normalizeAddress);

  // We set business name as address name by default
  if (companyAddress && !companyAddress.name) {
    Object.assign(companyAddress, { name: data.getString('name') });
  }

  return {
    accountingLink: data.get('accounting_link'),
    // accountingLink: CompanyExtractService.QuickBooks,
    entityType: CompanyEntityName,
    accountingName: data.getString('accounting_company_name'),
    accountingCurrency: data.getString('accounting_company_currency'),
    defaultCurrency: data.getString('default_currency'),
    purchaseServices: data.getArray('configured_purchase_services'),
    address: companyAddress,
    id: data.getString('company_id'),
    bankingIds: data.getArray('banking_ids'),
    company_id: data.getUuid('company_id'),
    creation: data.getDate('creation'),
    countryCode: data.getString('country_code'),
    domain_id: data.getUuid('domain_id'),
    domainId: data.getUuid('domain_id'),
    features: data.getArray('features'),
    lastUpdate: data.getDate('lastUpdate'),
    links: data.get('links', { parent: null, children: [] }),
    name: data.getString('name'),
    normalizedName: data.getString('normalized_name'),
    contactEmail: data.getStringOrNull('contact_email'),
    siblings: [],
    status: data.mapEnum(
      CompanyStatus,
      'status',
      CompanyStatus.PendingActivation,
    ),
    phoneNumber: data.get('phone_number'),
    website: data.getString('website'),
    fiscalPeriodStart: data.getString('fiscal_period_start'),
    fiscalPeriodEnd: data.getString('fiscal_period_end'),
    defaultDashboardLayout: data.getString('default_dashboard_layout', ''),
    travelContactPhone1: data.getString('voice_phone_number1', ''),
    travelContactPhone2: data.getString('voice_phone_number2', ''),
    whatsAppPhone: data.getString('whatsapp_phone_number', ''),
    travelContactEmail: data.getString('travel_contact_email', ''),
  };
}

export function normalizeCompanyNameVerification(input: object): {
  isExisting: boolean;
} {
  const data = reify(input);
  return {
    isExisting: data.getBoolean('is_existing'),
  };
}

export function isConnectedToExternalAccountingSystem(company: Company) {
  return COMPANY_EXTERNAL_ACCOUNTING_LINKS.includes(company?.accountingLink);
}

export const getAvalailableStatuses = (
  status: CompanyStatus,
): CompanyStatus[] => {
  const availableStatuses = [status];
  if (
    status === CompanyStatus.PendingActivation ||
    status === CompanyStatus.Active
  ) {
    return availableStatuses.concat(CompanyStatus.Locked);
  }
  if (
    status === CompanyStatus.ActivationInProgress ||
    status === CompanyStatus.DueDiligenceFailed
  ) {
    return availableStatuses.concat([
      CompanyStatus.Locked,
      CompanyStatus.Active,
    ]);
  }
  return availableStatuses;
};
