import {
  AccountingConfig,
  CashTxVendorCreationResult,
  CompanyExtractService,
  CashAccountMappingPreferences,
  UserMapping,
  UserVendor,
  Vendor,
  normalizeUserVendor,
  normalizeVendor,
  normalizeAccountiongConfig,
  normalizeAccountClassificationCount,
  CreditCardMappingPreferences,
} from '@neo1/client';
import { getClient } from '../../../utils';

type ConnectExtractServiceResponse = {
  url: string;
};

export type CashTxConfig = {
  mappingPreferences: CashAccountMappingPreferences;
  vendorId: string;
  usersMapping: UserMapping[];
};
export type CardTxConfig = {
  mappingPreferences: CreditCardMappingPreferences;
  vendorId: string;
};
export const toCashTxConfig = (config: CashTxConfig) => ({
  mapping_preferences: config.mappingPreferences,
  vendor_id: config.vendorId,
  users_mapping: config.usersMapping?.map((x) => ({
    user_id: x.userId,
    mapping: {
      mapping_type: x.mapping.mappingType,
      vendor_id: x.mapping.vendorId,
    },
  })),
});
export const toCardTxConfig = (config: CardTxConfig) => ({
  mapping_preferences: config.mappingPreferences,
  vendor_id: config.vendorId,
});

export function connectExtractService(
  companyId: string,
  service: CompanyExtractService,
  callbackUrl: string,
): Promise<ConnectExtractServiceResponse> {
  return getClient().sendCommand({
    method: 'login_accounting',
    params: {
      company_id: companyId,
      accounting_tool: service,
      callback_url: callbackUrl,
    },
  });
}

export function disconnectExtractService(companyId: string) {
  return getClient().sendCommand({
    method: 'logout_accounting',
    params: { company_id: companyId },
  });
}

export function syncExtractService(companyId: string): Promise<{
  assets: number;
  expenses: number;
}> {
  return getClient()
    .sendCommand({
      method: 'sync_accounting',
      params: { company_id: companyId },
    })
    .then((result) => normalizeAccountClassificationCount(result));
}

export function getVendors(companyId: string): Promise<Vendor[]> {
  return getClient()
    .sendCommand({
      method: 'get_vendors',
      params: { company_id: companyId },
    })
    .then((vendors) => vendors.map(normalizeVendor));
}

export function createCashTxConfig(
  companyId: string,
  config: CashTxConfig,
): Promise<CashTxVendorCreationResult> {
  return getClient().sendCommand({
    method: 'save_cash_txs_configuration',
    params: {
      company_id: companyId,
      cash_txs_accounting_config: toCashTxConfig(config),
    },
  });
}
export function createCardTxConfig(companyId: string, config: CardTxConfig) {
  return getClient().sendCommand({
    method: 'save_card_txs_configuration',
    params: {
      company_id: companyId,
      card_txs_accounting_config: toCardTxConfig(config),
    },
  });
}
export function getUsersVendors(companyId: string): Promise<UserVendor[]> {
  return getClient()
    .sendCommand({
      method: 'get_users_vendors_mapping',
      params: { company_id: companyId },
    })
    .then((usersVendors) => usersVendors.map(normalizeUserVendor));
}

export function getCompanyAccountingConfig(
  companyId: string,
): Promise<AccountingConfig> {
  return getClient()
    .sendCommand({
      method: 'get_company_accounting_config',
      params: { company_id: companyId },
    })
    .then((result) => normalizeAccountiongConfig(result));
}

export async function reconnectExtractService(
  companyId: string,
  service: CompanyExtractService,
  callbackUrl: string,
) {
  await disconnectExtractService(companyId);
  return connectExtractService(companyId, service, callbackUrl);
}
