import {
  useRef,
  ReactNode,
  MouseEventHandler,
  InputHTMLAttributes,
} from 'react';
import uniqueId from 'lodash/fp/uniqueId';
import classNames from 'classnames';

import { ReactComponent as CheckIcon } from './icons/check.svg';
import { ReactComponent as MinusIcon } from './icons/minus.svg';
import styles from './index.module.css';

interface Props {
  checked: boolean;
  onChange(checked: boolean): void;
  partiallyChecked?: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
  onClick?: MouseEventHandler<HTMLLabelElement>;
  id?: string;
  name?: string;
  value?: InputHTMLAttributes<HTMLInputElement>['value'];
  children?: ReactNode;
  className?: string;
  testId?: string;
}

const defaultProps: Partial<Props> = {
  partiallyChecked: false,
  disabled: false,
  isInvalid: false,
  onClick: null,
  id: null,
  name: null,
  value: '',
  children: null,
  className: null,
  testId: undefined,
};

const Checkbox = ({
  id,
  name,
  value,
  checked,
  partiallyChecked,
  disabled,
  isInvalid,
  onChange,
  onClick,
  children,
  className,
  testId,
}: Props) => {
  const refId = useRef(id ?? uniqueId('input'));

  const handleKeyPress = (e) => {
    if (disabled) return;
    // Toggle check when the space bar is pressed
    if (e.charCode === 32) {
      onChange(!checked);
    }
  };

  const handleChange = () => {
    if (disabled) return;
    onChange(!checked);
  };

  return (
    // eslint-disable-next-line
    <label
      className={classNames(styles.container, className)}
      htmlFor={refId.current}
      onClick={onClick}
    >
      <input
        type="checkbox"
        id={refId.current}
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        className={styles.defaultCheckbox}
        aria-hidden="true"
        disabled={disabled}
      />
      <div
        className={classNames(styles.checkbox, {
          [styles.isChecked]: checked || partiallyChecked,
          [styles.isDisabled]: disabled,
          [styles.isInvalid]: isInvalid,
        })}
        tabIndex={!disabled ? 0 : -1}
        role="checkbox"
        aria-checked={partiallyChecked ? 'mixed' : checked}
        onKeyPress={handleKeyPress}
        data-testid={testId}
      >
        {partiallyChecked ? (
          <MinusIcon className={styles.icon} />
        ) : (
          <CheckIcon className={styles.icon} />
        )}
      </div>
      {children ? (
        <div className={classNames(styles.label, 'textM')}>{children}</div>
      ) : null}
    </label>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
