import { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Addr } from '@neo1/core/utils';
import { CompanyActivationData } from '@neo1/core/modules/persisted/types';
import { useAppReferentialSingleton } from 'modules/App/useAppReferential';
import SelectCountry from 'components/composed/SelectCountry';
import SelectCountryRegion, {
  getStateValue,
} from 'components/composed/SelectCountryRegion';
import FormGroup from 'components/elements/form/Group';
import TextInput from 'components/elements/form/fields/TextInput';
import fieldMessages from 'i18n/fields';
import { useFormikContext } from 'formik';
import FormField from 'components/elements/form/Field';
import AutocompleteOptions from 'components/elements/Google/AutocompleteOptions';
import { SelectedAddress } from 'components/elements/Google/utils';
import PhoneNumberInput from 'components/elements/form/fields/PhoneNumberInput';
import { useAddressUtils } from 'hooks/address';
import styles from '../../ActivateCompany.module.css';

const STREET_FIELD_NAME = 'address';

const addressValuesMap: Array<[keyof Addr, string]> = [
  ['streetLine1', 'address'],
  ['streetLine2', 'address2'],
  ['zipCode', 'zipCode'],
  ['city', 'city'],
  ['state', 'state'],
  ['countryCode', 'country'],
];

const getNewValues = (addr: SelectedAddress) =>
  addressValuesMap.reduce(
    (res, [googleKey, valueKey]) => {
      // Do not change the country data since it comes from the initial data of signup process.
      if (googleKey === 'countryCode') {
        return res;
      }
      const value = addr[googleKey];
      return {
        ...res,
        [valueKey]: value,
      };
    },
    { state: '' },
  );

function Form() {
  const intl = useIntl();
  const addressInputRef = useRef();
  const {
    values: { country, address },
    values,
    setValues,
  } = useFormikContext<CompanyActivationData>();

  const { isCountryWithStates, getCountry } = useAppReferentialSingleton();
  const isUSCompany = country === 'US';
  const hasStates = isCountryWithStates(country);
  const { getStateFieldLabel, getZipCodeFieldLabel } = useAddressUtils();
  const countryStates = getCountry(country)?.states;

  const onSelectedOptionAvailable = useCallback(
    (selectedAddress: SelectedAddress) => {
      let newValues = getNewValues(selectedAddress);
      if (hasStates) {
        newValues = {
          ...newValues,
          state: getStateValue(newValues.state, countryStates),
        };
      }
      setValues(
        {
          ...values,
          ...newValues,
        },
        true,
      );
    },
    [values, setValues, countryStates, hasStates],
  );

  return (
    <>
      <main className={styles.formMain}>
        <AutocompleteOptions
          query={address}
          inputRef={addressInputRef}
          key="AutocompleteOptions"
          onPlaceSelected={onSelectedOptionAvailable}
        >
          <FormGroup>
            <FormField
              title={intl.formatMessage(fieldMessages.address.address)}
              id={STREET_FIELD_NAME}
              name={STREET_FIELD_NAME}
              key={STREET_FIELD_NAME}
              required
            >
              <TextInput ref={addressInputRef} autoComplete="new-password" />
            </FormField>
          </FormGroup>
        </AutocompleteOptions>
        <FormGroup>
          <FormField
            title={intl.formatMessage(fieldMessages.address.streetLine2)}
            name="address2"
          >
            <TextInput autoComplete="new-password" />
          </FormField>
        </FormGroup>
        <FormGroup>
          <FormField
            title={intl.formatMessage(fieldMessages.address.city)}
            name="city"
            required
          >
            <TextInput autoComplete="new-password" />
          </FormField>
          <FormField
            title={getStateFieldLabel(country)}
            name="state"
            required={isUSCompany}
          >
            <SelectCountryRegion countryCode={country} />
          </FormField>
        </FormGroup>
        <FormGroup>
          <FormField
            title={getZipCodeFieldLabel(country)}
            name="zipCode"
            required
          >
            <TextInput autoComplete="new-password" />
          </FormField>
          <FormField
            title={intl.formatMessage(fieldMessages.address.countryCode)}
            name="country"
            required
            disabled
          >
            <SelectCountry />
          </FormField>
        </FormGroup>
        <FormGroup>
          <FormField title="Business email" name="contactEmail" required>
            <TextInput />
          </FormField>
          <FormField title="Business number" name="phoneNumber" required>
            <PhoneNumberInput
              defaultCountry={country}
              autoComplete="new-password"
            />
          </FormField>
        </FormGroup>
      </main>
    </>
  );
}

export default Form;
