export const Reports = {
  BUILDER: 'reportBuilder',
  DASHBOARD: 'dashboard',
};

export const ReportDataTypes = {
  CHART: 'chart',
  TABLE: 'table',
};

export const ReportId = {
  AUTHORIZATIONS_DECLINES: 'authorizations_declines',
  CONFIGURATION_REVIEW: 'configuration_review',
  CREDIT_SPEND_REVERSALS: 'credit_spend_reversals',
  DELEGATIONS: 'delegations',
  FLEET_ACTIVITY: 'fleet_activity',
  FLEET_SPEND_ANALYSIS: 'fleet_spend_analysis',
  FRAUD_DUPLICATE_SPEND: 'fraud_duplicate_spend',
  FRAUD_FREQUENT_LOW_VALUE_SPEND: 'fraud_frequent_low_value_spend',
  NON_DOMESTIC_SPEND: 'non_domestic_spend',
  PAYMENT_ACCOUNT_STATUS: 'payment_account_status',
  PROCESSING_KPI: 'processing_kpi',
  RECONCILIATION: 'reconciliation',
  SPEND_BY_COUNTRY: 'spend_by_country',
  SPEND_BY_EXPENSE_TYPE: 'spend_by_expense_type',
  SPEND_BY_MCC: 'spend_by_merchant_category_code',
  SPEND_BY_MCG: 'spend_by_mcg',
  SPEND_BY_MERCHANT: 'spend_by_merchant',
  SPEND_BY_PERSONAL_PAY_ACCOUNT: 'spend_by_personal_pay_account',
  SPEND_BY_PAYMENT_TYPE: 'spend_by_payment_type',
  SPEND_BY_PAYMENT_INSTRUMENT: 'spend_by_payment_instrument',
  SPEND_BY_USER: 'spend_by_user',
  SPEND_HISTORY: 'spend_history',
  SPEND_PARTICIPANTS: 'spend_participants',
  SPEND_SUMMARY: 'spend_summary',
  SPEND_POLICY_EXCEPTIONS: 'spend_policy_exceptions',
  STATEMENT: 'statement',
  TAX_AND_RECEIPTS_ANALYSIS: 'tax_and_receipts_analysis',
  TRANSACTION_AUDIT: 'transaction_audit',
  USER_PROFILE_DETAILS: 'user_profile_details',
  USE_OF_OWN_VEHICLE: 'use_of_own_vehicle',
  SPEND_TO_JUSTIFY: 'spend_to_justify',
  SPEND_TO_APPROVE: 'spend_to_approve',
  SPEND_TO_REVIEW: 'spend_to_review',
  SPEND_TO_PROCESS: 'spend_to_process',
  BUDGET_SPEND_SUMMARY: 'budget_spend_summary',
  TRIP_BY_TRAVELER: 'trip_by_traveler',
  TRIP_BY_SERVICE_TYPE: 'trip_by_service_type',
  TRIP_BY_STATUS: 'trip_by_status',
  SPEND_BY_STATUS: 'spend_by_status',
};

export const ReportCategories = {
  SPEND_REPORTS: [
    ReportId.SPEND_SUMMARY,
    ReportId.SPEND_BY_COUNTRY,
    ReportId.SPEND_BY_EXPENSE_TYPE,
    ReportId.SPEND_BY_MERCHANT,
    ReportId.SPEND_BY_MCC,
    ReportId.SPEND_BY_MCG,
    ReportId.NON_DOMESTIC_SPEND,
    ReportId.SPEND_BY_PAYMENT_INSTRUMENT,
    ReportId.SPEND_BY_PAYMENT_TYPE,
    ReportId.SPEND_BY_USER,
  ],
  PRE_SPEND_REPORTS: [ReportId.BUDGET_SPEND_SUMMARY],
  ANALYTICS_REPORTS: [
    ReportId.FRAUD_FREQUENT_LOW_VALUE_SPEND,
    ReportId.FRAUD_DUPLICATE_SPEND,
    ReportId.SPEND_POLICY_EXCEPTIONS,
  ],
  SPEND_REPORTS_LIVE: [
    ReportId.SPEND_TO_JUSTIFY,
    ReportId.SPEND_TO_APPROVE,
    ReportId.SPEND_TO_REVIEW,
    ReportId.SPEND_TO_PROCESS,
    ReportId.SPEND_BY_STATUS,
  ],
  TRAVEL_REPORTS: [
    ReportId.TRIP_BY_TRAVELER,
    ReportId.TRIP_BY_SERVICE_TYPE,
    ReportId.TRIP_BY_STATUS,
  ],
};

export const ReportTypes = {
  DASHBOARD: 'dashboard',
  PREDEFINED: 'predefined',
};

export const ChartType = {
  BAR: 'bar',
  CUBE: 'cube',
  HORIZONTAL_BAR: 'horizontalBar',
  PIE: 'pie',
  DOUGHNUT: 'doughnut',
  LINE: 'line',
};

export const REPORT_COLORS = ['rgba(0, 111, 207, .7)', 'rgb(102,116,156)'];

export const ReportingScopeFilterType = {
  COMPANY: 'company',
  USER: 'user',
  ORGANIZATION: 'organization',
  CUSTOM: 'custom',
};

export const REPORTING_SCOPE_FILTER_TYPES = [
  ReportingScopeFilterType.COMPANY,
  ReportingScopeFilterType.USER,
  ReportingScopeFilterType.ORGANIZATION,
];

export const ReportFieldClass = {
  STRING: 'string',
  UUID: 'uuid',
  DATE_TIME: 'date_time',
  DATE: 'date',
  YEAR_MONTH: 'year_month',
  AMOUNT: 'amount',
  CURRENCY: 'currency',
  BOOLEAN: 'boolean',
  DECIMAL: 'decimal',
  INTEGER: 'integer',
  PERCENTAGE: 'percentage',
  ACCOUNTING_SEGMENT: 'accounting_segment',
  URL: 'url',
  BILLING_PERIOD: 'billing_period',
  CREDIT_CARD_DIGITS: 'credit_card_digits',
  COUNTRY: 'country',
  ENUMERATION: 'enumeration',
  CODE_WITH_LABEL: 'code_with_label',
};
