import Button, { ButtonTheme } from 'components/elements/Button';
import Popover from 'components/elements/Popover';
import Tooltip from 'components/elements/Tooltip';
import Badge from 'components/elements/Badge';
import { Cta } from '../types';
import styles from './CtaButton.module.css';

type Props = {
  cta: Cta;
  theme?: ButtonTheme;
  withIcon?: boolean;
  isDark?: boolean;
};

const CtaButton = ({ cta, theme, withIcon, isDark }: Props) => {
  const {
    label,
    iconName,
    onClick,
    disabled,
    testId,
    popover,
    counter,
    tooltip,
  } = cta;

  const render = () => (
    <div className={styles.buttonWrapper} data-testid="ctaBtn">
      <Button
        label={label}
        iconName={withIcon ? iconName : undefined}
        onClick={onClick}
        disabled={disabled}
        testId={testId}
        theme={theme}
        dark={isDark}
      />
      {counter ? <Badge count={counter} className={styles.badge} /> : undefined}
    </div>
  );

  if (popover) {
    return (
      <Popover
        content={popover.content}
        placement={popover.placement ?? 'bottom-start'}
        showOnClick
      >
        {render()}
      </Popover>
    );
  }

  if (tooltip) {
    return <Tooltip content={tooltip}>{render()}</Tooltip>;
  }

  return render();
};

CtaButton.defaultProps = {
  theme: ButtonTheme.Filled,
  withIcon: false,
  isDark: false,
};

export default CtaButton;
