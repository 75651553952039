import {
  Company as CompanyType,
  CompanyGroupUser,
  Domain as DomainType,
  User,
} from '@neo1/client';
import { SortTypes } from '@neo1/core/modules/config/constants';

export enum AdminView {
  System = 'system',
  Domain = 'domain',
  Company = 'company',
}
export type FiltersData = {
  sort: {
    prop: string;
    dir: SortTypes;
  };
  search: string | null;
};

export type HomeViewState = {
  systemAdmins: {
    ids: User['id'][];
    filters: FiltersData;
  };
  domainAdmins: {
    ids: User['id'][];
    filters: FiltersData;
  };
  domains: {
    ids: DomainType['id'][];
    filters: FiltersData;
  };
  companies: {
    ids: CompanyType['id'][];
    filters: FiltersData;
  };
  users: {
    ids: User['id'][];
    companyGroupUserIds: CompanyGroupUser['id'][];
    filters: FiltersData;
  };
  linkedCompanies: {
    filters: FiltersData;
  };
};
