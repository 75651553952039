import isNil from 'lodash/isNil';
import { Company } from '@neo1/client';

export function hasAddress(company: Company) {
  return !isNil(company.address);
}

export function getCompanyAddress(company: Company) {
  return company.address;
}
