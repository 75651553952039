import { NEVER } from 'rxjs';
import { AnyAction } from 'redux';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { wrapResults } from '@neo1/client/lib/rpc/batch';
import {
  isAdmin,
  isCompanyUser,
  isFinance,
} from '@neo1/core/modules/users/utils';

import {
  RpcBatchCommand,
  RpcBatchResult,
  ReifiedDataWrapper,
  reify,
  BudgetEntityName,
  SupplyEntityName,
} from '@neo1/client';
import { getClient } from '@neo1/core/utils/client';
import {
  getChildrenIds,
  isOrganizationRoot,
} from '@neo1/core/modules/companies/utils';
import { ActionTypes as EntitiesAction } from 'redux/entities/actions';
import {
  selectActingCompany,
  selectActingUser,
  selectActingUserPreferredCurrency,
  selectIsLoggedAsCompany,
} from 'modules/Authentification/redux/selectors';
import { setCounters } from 'redux/counter/actions';
import {
  State as CounterState,
  Actions as CounterActions,
} from 'redux/counter/types';
import ScopeType from 'redux/scopeType';
import { TxEntityName } from '@neo1/client/lib/entities/spend/tx';

const entitiesActions = [
  EntitiesAction.Set,
  EntitiesAction.SetMulti,
  EntitiesAction.Delete,
];
const relatedEntityTypes = [BudgetEntityName, SupplyEntityName, TxEntityName];

// eslint-disable-next-line import/prefer-default-export
export const fetchSpendCounts = (action$, state$) =>
  action$.pipe(
    ofType<AnyAction, AnyAction['type']>(
      CounterActions.FETCH_COUNTERS,
      ...entitiesActions,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { type, entityType } = action;
      // skip the fetching if is a action for entities that is not related to budget, supply or tx.
      // (Among  the entities actions only the ones for budget or supply could change the counters)
      if (
        entitiesActions.includes(type) &&
        !relatedEntityTypes.includes(entityType)
      ) {
        return NEVER;
      }

      const user = selectActingUser(state);
      if (!user || !isCompanyUser(user)) {
        return NEVER;
      }

      const currency = selectActingUserPreferredCurrency(state);
      const company = selectActingCompany(state);
      const isLoggedAsCompany = selectIsLoggedAsCompany(state);

      const commands: RpcBatchCommand = [
        {
          id: 1,
          method: 'get_user_spend_counters',
          params: {
            user_id: user.id,
            currency,
          },
        },
      ];

      if (!isLoggedAsCompany) {
        commands.push({
          id: 2,
          method: 'get_supervision_spend_counters',
          params: {
            supervisor_id: user.id,
            currency,
          },
        });
      }
      if ((isAdmin(user) || isFinance(user)) && company) {
        commands.push({
          id: 3,
          method: 'get_companies_spend_counters',
          params: {
            user_id: user.id,
            company_ids: [company.id],
            currency,
          },
        });

        if (isOrganizationRoot(company)) {
          commands.push({
            id: 4,
            method: 'get_companies_spend_counters',
            params: {
              user_id: user.id,
              company_ids: [company.id, ...getChildrenIds(company)],
              currency,
            },
          });
        }
      }

      return getClient().batchCommands(commands);
    }),
    mergeMap(async (result: RpcBatchResult) => {
      const batchResults = wrapResults(result);

      const dataByScope: Record<ScopeType, ReifiedDataWrapper> = {
        user: reify(batchResults.get(1)),
        supervisor: reify(batchResults.get(2)),
        company: reify(batchResults.get(3)),
        organization: reify(batchResults.get(4)),
      };

      const counters = Object.values(ScopeType).reduce((acc, scope) => {
        const data = dataByScope[scope];

        return {
          ...acc,
          [scope]: {
            tx: {
              inProgress: {
                count: data.getNumber('cnt_tx_in_progress'),
                amount: data.getAmount('sum_tx_in_progress')?.amount,
                currency: data.getAmount('sum_tx_in_progress')?.currency,
              },
              inApprove: {
                count: data.getNumber('cnt_tx_in_approve'),
                amount: data.getAmount('sum_tx_in_approve')?.amount,
                currency: data.getAmount('sum_tx_in_approve')?.currency,
              },
              inReview: {
                count: data.getNumber('cnt_tx_in_review'),
                amount: data.getAmount('sum_tx_in_review')?.amount,
                currency: data.getAmount('sum_tx_in_review')?.currency,
              },
              inExtract: {
                count: data.getNumber('cnt_tx_in_extract'),
                amount: data.getAmount('sum_tx_in_extract')?.amount,
                currency: data.getAmount('sum_tx_in_extract')?.currency,
              },
              toApprove: {
                count: data.getNumber('cnt_tx_to_approve'),
                amount: data.getAmount('sum_tx_to_approve')?.amount,
                currency: data.getAmount('sum_tx_to_approve')?.currency,
              },
              toReview: {
                count: data.getNumber('cnt_tx_to_review'),
                amount: data.getAmount('sum_tx_to_review')?.amount,
                currency: data.getAmount('sum_tx_to_review')?.currency,
              },
            },
            budget: {
              inProgress: {
                count: data.getNumber('cnt_budget_in_progress'),
                amount: data.getAmount('sum_budget_in_progress')?.amount,
                currency: data.getAmount('sum_budget_in_progress')?.currency,
              },
              inApprove: {
                count: data.getNumber('cnt_budget_in_approve'),
                amount: data.getAmount('sum_budget_in_approve')?.amount,
                currency: data.getAmount('sum_budget_in_approve')?.currency,
              },
              toApprove: {
                count: data.getNumber('cnt_budget_to_approve'),
                amount: data.getAmount('sum_budget_to_approve')?.amount,
                currency: data.getAmount('sum_budget_to_approve')?.currency,
              },
              approved: {
                count: data.getNumber('cnt_budget_approved'),
                amount: data.getAmount('sum_budget_approved')?.amount,
                currency: data.getAmount('sum_budget_approved')?.currency,
              },
            },
            purchase: {
              inProgress: {
                count: data.getNumber('cnt_purchase_in_progress'),
                amount: data.getAmount('sum_purchase_in_progress')?.amount,
                currency: data.getAmount('sum_purchase_in_progress')?.currency,
              },
              inApprove: {
                count: data.getNumber('cnt_purchase_in_approve'),
                amount: data.getAmount('sum_purchase_in_approve')?.amount,
                currency: data.getAmount('sum_purchase_in_approve')?.currency,
              },
              toApprove: {
                count: data.getNumber('cnt_purchase_to_approve'),
                amount: data.getAmount('sum_purchase_to_approve')?.amount,
                currency: data.getAmount('sum_purchase_to_approve')?.currency,
              },
              approved: {
                count: data.getNumber('cnt_purchase_approved'),
                amount: data.getAmount('sum_purchase_approved')?.amount,
                currency: data.getAmount('sum_purchase_approved')?.currency,
              },
            },
            trips: {
              current: {
                count: data.getNullableNumber('cnt_current_trips'),
              },
              future: {
                count: data.getNullableNumber('cnt_future_trips'),
              },
            },
          },
        };
      }, {} as CounterState);

      return setCounters(counters);
    }),
    catchError(() => NEVER),
  );
