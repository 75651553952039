import {
  Address,
  CompanyKind,
  CompanyNumberType,
  PhoneNumber,
} from '@neo1/client';

export type CompanyActivationOwner = {
  city: string;
  state: string;
  birthDate: string;
  zipCode: string;
  country: string;
  countryOfBirth: string;
  lastName: string;
  firstName: string;
  street: string;
};

export enum CompanyFeatureUsage {
  Immediately = 'immediately',
  NearFuture = 'near_future',
  NotNow = 'not_now',
}

export type CompanyActivationData = {
  id: string | null;
  city: string;
  name: string;
  numberOfEmployee: number;
  editedOwnerIndex: number | null;
  owners: CompanyActivationOwner[];
  industry: string;
  dismissedTips?: string[];
  zipCode: string;
  address: string;
  address2: string;
  companyKind: CompanyKind;
  yearOfCreation: string;
  hasOwners: boolean;
  phoneNumber: PhoneNumber;
  contactEmail: string;
  registrationNumber?: string;
  registrationNumberType?: CompanyNumberType;
  tradeName?: string;
  vatNumber?: string;
  state?: string;
  currentStep: number;
  country: string;
  expenseFeatureUsage: CompanyFeatureUsage;
  purchasingFeatureUsage: CompanyFeatureUsage;
  travelFeatureUsage: CompanyFeatureUsage;
};

export type UserPersistedData = {
  companyActivation?: CompanyActivationData;
  reportTemplates?: any;
  calendarProvider?: any;
  amazonShippingAddress?: Address;
  dashboardLayouts?: any;
};

export type UserPersitedDataKey = keyof UserPersistedData;
