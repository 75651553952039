import async from 'utils/wrapAsync';
import { hasSpendManagement, AppMenuRoute } from 'config/routes/utils';
import {
  ROUTE_PATH_LOYALTY_PROGS,
  ROUTE_NAME_LOYALTY_PROGS,
} from './constants';

const route: AppMenuRoute = {
  title: 'Loyalty programs',
  show: hasSpendManagement,
  exact: true,
  menu: {
    iconClass: 'loyaltyProgram',
  },
  path: ROUTE_PATH_LOYALTY_PROGS,
  name: ROUTE_NAME_LOYALTY_PROGS,
  component: async(
    () =>
      import(
        /* webpackChunkName: "loyaltyProgram" */
        '.'
      ),
  ),
};

export default route;
