import { UUID } from '@neo1/client';
import { RpcCommand } from '../../utils';

type CountryEulaParams = {
  country_code: string;
};

type CompanyEulaParams = {
  company_id: UUID;
};

export const getCountryEula = (
  countryCode: string,
): RpcCommand<CountryEulaParams> => {
  return {
    method: 'get_country_eula_link',
    params: {
      country_code: countryCode,
    },
  };
};

export const getCompanyEula = (
  companyId: string,
): RpcCommand<CompanyEulaParams> => {
  return {
    method: 'get_company_eula_link',
    params: {
      company_id: companyId,
    },
  };
};
