import superagent from 'superagent';

/**
 * Fetches JSON file contents
 */
export const fetchJson = (url: string) =>
  new Promise((resolve, reject) =>
    superagent
      .get(url)
      .set('Accept', 'application/json')
      .end((err, res) => (err ? reject(err) : resolve(res.body))),
  );
