import { CompanyFeature, UserLevel } from '@neo1/client';
import { AppMenuRoute, hasGuestManagement } from 'config/routes/utils';
import wrapAsync from 'utils/wrapAsync';
import {
  ROUTE_NAME_GUESTS,
  ROUTE_NAME_TRAVEL,
  ROUTE_NAME_TRIPS,
  ROUTE_PATH_GUESTS,
  ROUTE_PATH_TRAVEL,
  ROUTE_PATH_TRIPS,
  ROUTE_PATH_USER_TRIPS,
} from './constants';

const travelRoutes: AppMenuRoute = {
  path: ROUTE_PATH_TRAVEL,
  name: ROUTE_NAME_TRAVEL,
  menu: {
    path: ROUTE_PATH_USER_TRIPS,
    iconClass: 'luggage',
    userLevels: [UserLevel.COMPANY],
    companyFeatures: [CompanyFeature.Spend],
  },
  children: [
    {
      title: 'Trips',
      defaultPath: ROUTE_PATH_USER_TRIPS,
      path: ROUTE_PATH_TRIPS,
      name: ROUTE_NAME_TRIPS,
      strict: true,
      exact: true,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "trips" */
            './pages/Trips'
          ),
      ),
    },
    {
      title: 'Guests',
      path: ROUTE_PATH_GUESTS,
      name: ROUTE_NAME_GUESTS,
      strict: true,
      exact: true,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "guests" */
            './pages/Guests'
          ),
      ),
      show: ({ user }) => hasGuestManagement(user),
    },
  ],
};

export default travelRoutes;
