import { Dispatch, Store } from 'redux';
import { AppCoreState } from 'redux/types';
import { isCompanyUser } from '@neo1/core/modules/users/utils';
import {
  selectActingCompany,
  selectCurrentUser,
} from 'modules/Authentification/redux/selectors';
import {
  getCompanyTaxes,
  deleteCompanyTax,
  setCompanyTax,
  setTaxConfiguration,
  getTaxConfiguration,
  updateDefaultTaxesConfig,
  updateManualTaxConfig,
} from '@neo1/core/modules/settings/taxes/api';
import { sleep } from '@neo1/core/utils';
import {
  DefaultTaxesConfig,
  ManualTaxConfig,
  Tax,
  TaxConfig,
  TaxValues,
} from '@neo1/core/modules/settings/taxes/types';
import { TaxEntityName } from '@neo1/core/modules/settings/taxes/utils';
import { delEntity, setEntities, setEntity } from 'redux/entities/thunks';
import {
  TaxesActions,
  setTaxes,
  delTax,
  setTax as setTaxAction,
  setTaxConfig as setTaxConfigAction,
} from './actions';

export const load = async (
  dispatch: Dispatch<TaxesActions>,
  getState: Store<AppCoreState>['getState'],
) => {
  const company = selectActingCompany(getState());

  const result: Tax[] = await getCompanyTaxes(company.id);

  setEntities<Tax>({
    entityType: TaxEntityName,
    entities: result,
    action: setTaxes(result.map((tax) => tax.taxCode)),
  })(dispatch);
};

export const setTax =
  ({ tax, companyId }: { tax: TaxValues; companyId: string }) =>
  async (dispatch: Dispatch<TaxesActions>) => {
    await setCompanyTax(tax, companyId);
    setEntity<TaxValues>({
      entityType: TaxEntityName,
      entity: tax,
      action: setTaxAction(tax.id),
    })(dispatch);
  };

export const deleteTax =
  (taxCode: Tax['taxCode']) =>
  async (
    dispatch: Dispatch<TaxesActions>,
    getState: Store<AppCoreState>['getState'],
  ) => {
    const company = selectActingCompany(getState());

    await deleteCompanyTax(company.id, taxCode);

    delEntity({
      entityType: TaxEntityName,
      entityId: taxCode,
      action: delTax(taxCode),
    })(dispatch);
  };

export const setDefaultTaxesConfig =
  (values: DefaultTaxesConfig) =>
  async (
    dispatch: Dispatch<TaxesActions>,
    getState: Store<AppCoreState>['getState'],
  ) => {
    const currentUser = selectCurrentUser(getState());
    const company = selectActingCompany(getState());
    const userCompanyId = isCompanyUser(currentUser)
      ? currentUser.companyId
      : company.id;
    await sleep(1000);
    const config = await updateDefaultTaxesConfig(userCompanyId, values);
    dispatch(setTaxConfigAction(config));
  };

export const setManualTaxConfig =
  (values: ManualTaxConfig) =>
  async (
    dispatch: Dispatch<TaxesActions>,
    getState: Store<AppCoreState>['getState'],
  ) => {
    const currentUser = selectCurrentUser(getState());
    const company = selectActingCompany(getState());
    const userCompanyId = isCompanyUser(currentUser)
      ? currentUser.companyId
      : company.id;
    await sleep(1000);
    const config = await updateManualTaxConfig(userCompanyId, values);
    dispatch(setTaxConfigAction(config));
  };

export const setTaxConfig =
  (values: TaxConfig) =>
  async (
    dispatch: Dispatch<TaxesActions>,
    getState: Store<AppCoreState>['getState'],
  ) => {
    const currentUser = selectCurrentUser(getState());
    const company = selectActingCompany(getState());
    const userCompanyId = isCompanyUser(currentUser)
      ? currentUser.companyId
      : company.id;
    const config = await setTaxConfiguration({
      ...values,
      companyId: userCompanyId,
    });
    dispatch(setTaxConfigAction(config));
  };

export const loadTaxConfig = async (
  dispatch: Dispatch<TaxesActions>,
  getState: Store<AppCoreState>['getState'],
) => {
  const currentUser = selectCurrentUser(getState());
  const company = selectActingCompany(getState());
  const userCompanyId = isCompanyUser(currentUser)
    ? currentUser.companyId
    : company.id;

  const config = await getTaxConfiguration(userCompanyId);
  dispatch(setTaxConfigAction(config));
};
