import {
  AccountingConfig,
  CompanyExtractService,
  UserVendor,
  Vendor,
} from '@neo1/client';
import { Action, IntegrationActions } from './types';

export function setIntegrationSettings(
  service: CompanyExtractService,
  isConfigured: boolean,
): IntegrationActions {
  return {
    type: Action.SET,
    service,
    isConfigured,
  };
}

export function setVendors(
  service: CompanyExtractService,
  vendors: Vendor[],
): IntegrationActions {
  return {
    type: Action.SET_VENDORS,
    service,
    vendors,
  };
}

export function setUsersVendors(
  service: CompanyExtractService,
  usersVendors: UserVendor[],
): IntegrationActions {
  return {
    type: Action.SET_USERS_VENDORS,
    service,
    usersVendors,
  };
}
export function setConfig(
  service: CompanyExtractService,
  config: AccountingConfig,
): IntegrationActions {
  return {
    type: Action.SET_CONFIG,
    service,
    config,
  };
}
