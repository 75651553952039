import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { CompanyActivationData } from '@neo1/core/modules/persisted/types';
import Button, { ButtonTheme } from 'components/elements/Button';
import messages from '../../messages';

type ActionProps = {
  className?: string;
  onGoBack: () => void;
  isSubmitDisabled?: boolean;
};

const Actions = ({ className, onGoBack, isSubmitDisabled }: ActionProps) => {
  const { isSubmitting } = useFormikContext<CompanyActivationData>();
  const intl = useIntl();

  return (
    <footer className={className}>
      <Button
        label="Back"
        theme={ButtonTheme.Ghost}
        iconName="left"
        id="backButton"
        onClick={onGoBack}
        disabled={isSubmitting}
      />
      <Button
        label={intl.formatMessage(messages.footer.btnNext)}
        type="submit"
        id="btnContinue"
        disabled={isSubmitDisabled}
        loading={isSubmitting}
      >
        {intl.formatMessage(messages.footer.btnNext)}
      </Button>
    </footer>
  );
};

Actions.defaultProps = {
  className: undefined,
  isSubmitDisabled: false,
};

export default Actions;
