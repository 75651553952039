import dayjs, { ConfigType as DateInput } from 'dayjs';

// Day.js default locale
export const DAYJS_LOCALE_DEFAULT = 'en-gb';

// locales for the appilication
export const EN_US = 'en-US';
export const EN_GB = 'en-GB';
export type Locale = 'en-US' | 'en-GB';

export const DISPLAY_DATE_TIME_FORMAT_DEFAULT = 'D MMM YYYY HH:mm';
export const DISPLAY_DATE_FORMAT_DEFAULT = 'D MMM YYYY';
export const DISPLAY_DATE_WITHOUT_YEAR_FORMAT_DEFAULT = 'DD MMM';
export const DISPLAY_MONTH_FORMAT_DEFAULT = 'MMM YYYY';
export const INPUT_DATE_FORMAT_DEFAULT = 'dd/MM/yyyy';
export const INPUT_DATE_FORMAT_LABEL_DEFAULT = 'dd/mm/yyyy';
export const INPUT_YEAR_PLACEHOLDER_DEFAULT = 'yyyy';
export const INPUT_MONTH_PLACEHOLDER_DEFAULT = 'mm';
export const INPUT_DAY_PLACEHOLDER_DEFAULT = 'dd';
export const API_DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';

export const DEFAULT_LOCALE = EN_GB;

const SUPPORTED_LOCALES = [EN_GB, EN_US];

export function isSupportedLocale(locale: string): locale is Locale {
  return SUPPORTED_LOCALES.includes(locale);
}

export function displayDate(date: DateInput) {
  if (!date) {
    return '';
  }
  return dayjs(date).format(DISPLAY_DATE_FORMAT_DEFAULT);
}
