import { UUID } from '../';
import { reify } from '../normalize';

type Amount = {
  amount: number;
  currency: string;
};

export enum SummaryType {
  TransactionSummary = 'transaction_summary',
  ObfuscatedSummary = 'obfuscated_summary',
}

export type TxSummary = {
  summaryType?: SummaryType;
  billingAccountName?: string;
  billingAmount: Amount;
  merchant?: string;
  ownerFullName?: string;
  transactionDate: string;
  transactionId: UUID;
};

export function normalizeTxSummary(data: any): TxSummary {
  const rawData = reify(data);
  return {
    summaryType: rawData.get('summary_type', undefined),
    billingAccountName: rawData.getString('billing_account_name'),
    billingAmount: rawData.get('billing_amount'),
    merchant: rawData.getString('merchant'),
    ownerFullName: rawData.getString('owner_full_name'),
    transactionDate: rawData.getString('transaction_date'),
    transactionId: rawData.getUuid('transaction_id'),
  };
}
