import { AppCoreState } from 'redux/types';

import { selectEntitiesByIds } from 'redux/entities/selector';
import { Tax } from '@neo1/core/modules/settings/taxes/types';
import { TaxEntityName } from '@neo1/core/modules/settings/taxes/utils';

export const selectCompanyTaxesIds = (state: AppCoreState) =>
  state.settings.tax.ids;

export const selectTaxes = (state: AppCoreState) =>
  selectEntitiesByIds<Tax>(TaxEntityName, selectCompanyTaxesIds(state))(state);

export const selectFilters = (state: AppCoreState) =>
  state.settings.tax.filters;

export const selectTaxConfig = (state: AppCoreState) =>
  state.settings.tax.config;
