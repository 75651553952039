import { Country, Currency } from '@neo1/core/modules/app/entities';
import { AppCoreState } from 'redux/types';

const selectReferentialCountries = ({
  app: {
    referential: { countries },
  },
}: AppCoreState): Record<string, Country> => countries || {};

const selectReferentialCurrencies = ({
  app: {
    referential: { currencies },
  },
}: AppCoreState): Record<string, Currency> => currencies || {};

const selectAppReferential = (state: AppCoreState) => ({
  countries: selectReferentialCountries(state),
  currencies: selectReferentialCurrencies(state),
});

export default selectAppReferential;
