import superagent from 'superagent';
import get from 'lodash/get';

const EXCEEDS_MAX_SIZE_ERR_MSG =
  'Your proposed upload exceeds the maximum allowed size';

/**
 * Make post request to s3 to upload the given file blob
 * @param file
 * @param fileName
 * @param params
 */
export const upload = (file, fileName = 'file', params = {}) => {
  return new Promise((resolve, reject) =>
    superagent
      .post(params.action)
      .field('x-amz-date', params['x-amz-date'])
      .field('x-amz-credential', params['x-amz-credential'])
      .field('x-amz-security-token', params['x-amz-security-token'])
      .field(
        'x-amz-server-side-encryption',
        params['x-amz-server-side-encryption'],
      )
      .field('x-amz-algorithm', params['x-amz-algorithm'])
      .field('x-amz-signature', params['x-amz-signature'])
      .field('key', params.key)
      .field('acl', params.acl)
      .field('policy', params.policy)
      .field('success_action_status', params.success_action_status)
      .attach('file', file, fileName)
      .end((err, res) => {
        const errorResponseText = err?.response?.text || res?.text || '';
        const isMaxUploadSizeExceeded =
          errorResponseText.includes(EXCEEDS_MAX_SIZE_ERR_MSG) ||
          // Sometimes sending a file the exceeds the max size results in no response at all,
          // and is treated as a crossDomain error.
          (err?.crossDomain && res === undefined);

        if (isMaxUploadSizeExceeded) {
          return reject(
            new Error(
              'Your proposed upload file exceeds the maximum size allowed. Max size accepted: 9 MB.',
            ),
          );
        }

        if (err) {
          return reject(get(err, 'response.body.error', err));
        }

        if (params.success_action_status === `${res.status}`) {
          return resolve(res.body);
        }

        return reject(new Error('Could not upload the file'));
      }),
  );
};
