import { Guest } from '@neo1/client';

export enum Actions {
  SET_GUESTS = 'one/travel/SET_GUESTS',
  SET_GUEST = 'one/travel/SET_GUEST',
  DELETE_GUEST = 'one/travel/DELETE_GUEST',
}

export type Action =
  | {
      type: Actions.SET_GUESTS;
      guestsIds: Guest['id'][];
    }
  | {
      type: Actions.SET_GUEST;
      guestId: Guest['id'];
    }
  | {
      type: Actions.DELETE_GUEST;
      guestId: Guest['id'];
    };

export function setGuests(guestsIds: Guest['id'][]): Action {
  return {
    type: Actions.SET_GUESTS,
    guestsIds,
  };
}

export function setGuest(guestId: Guest['id']): Action {
  return {
    type: Actions.SET_GUEST,
    guestId,
  };
}

export function deleteGuest(guestId: Guest['id']): Action {
  return {
    type: Actions.DELETE_GUEST,
    guestId,
  };
}
