import { CompanyUser, DomainUser, SystemUser, UserLevel } from '@neo1/client';
import { rolesContainsAdminRole } from './utils';

type CreateSystemUserParams = Pick<
  SystemUser,
  'level' | 'email' | 'displayName'
>;
type CreateDomainUserParams = Pick<
  DomainUser,
  'level' | 'additionalDomains' | 'email' | 'domainId' | 'displayName'
>;
type CreateCompanyUserParams = Pick<
  CompanyUser,
  | 'level'
  | 'email'
  | 'companyId'
  | 'displayName'
  | 'firstName'
  | 'lastName'
  | 'phone'
  | 'externalId'
  | 'locale'
  | 'roles'
  | 'supervisorId'
  | 'preferredCurrency'
  | 'title'
  | 'travelerGroup'
>;

export type CreateUserParams =
  | CreateSystemUserParams
  | CreateDomainUserParams
  | CreateCompanyUserParams;

type UpdateSystemUserParams = Pick<
  SystemUser,
  'level' | 'id' | 'status' | 'email' | 'displayName' | 'password'
>;
type UpdateDomainUserParams = Pick<
  DomainUser,
  | 'level'
  | 'id'
  | 'domainId'
  | 'status'
  | 'email'
  | 'displayName'
  | 'additionalDomains'
  | 'password'
>;
type UpdateCompanyUserParams = Pick<
  CompanyUser,
  | 'level'
  | 'id'
  | 'companyId'
  | 'status'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'preferredCurrency'
  | 'locale'
  | 'title'
  | 'phone'
  | 'password'
  | 'birthDate'
  | 'roles'
  | 'supervisorId'
  | 'externalId'
  | 'birthDate'
  | 'travelerGroup'
>;
export type UpdateUserParams =
  | UpdateSystemUserParams
  | UpdateDomainUserParams
  | UpdateCompanyUserParams;

export const getCreateSystemUserPayload = (params: CreateSystemUserParams) => ({
  email: params.email,
  fullname: params.displayName,
});

export const getCreateDomainUserPayload = (params: CreateDomainUserParams) => ({
  domain_id: params.domainId,
  email: params.email,
  fullname: params.displayName,
  additional_domain_ids: params.additionalDomains,
});

export const getCreateCompanyUserPayload = (
  params: CreateCompanyUserParams,
) => ({
  company_id: params.companyId,
  email: params.email,
  first_name: params.firstName,
  last_name: params.lastName,
  phone: params.phone,
  external_id: params.externalId,
  locale: params.locale,
  roles: params.roles,
  supervisor_id: params.supervisorId,
  preferred_currency: params.preferredCurrency,
  title: params.title,
  traveler_group: params.travelerGroup,
});

function getUpdateDomainUserAdditionalDomainsPayload({
  additionalDomains,
}: UpdateDomainUserParams) {
  return {
    operation: 'set_domain_user_additional_domains',
    additional_domain_ids: additionalDomains,
  };
}

function getUpdateSystemUserDetailsPayload(params: UpdateSystemUserParams) {
  return {
    operation: 'update_system_user',
    email: params.email,
    full_name: params.displayName,
  };
}

function getUpdateDomainUserDetailsPayload(params: UpdateDomainUserParams) {
  return {
    operation: 'update_domain_user',
    email: params.email,
    full_name: params.displayName,
  };
}

function getUpdateCompanyUserDetailsPayloaduser(
  params: UpdateCompanyUserParams,
) {
  return {
    operation: 'update_company_user',
    email: params.email,
    first_name: params.firstName,
    last_name: params.lastName,
    preferred_currency: params.preferredCurrency,
    locale: params.locale,
    title: params.title,
    phone: params.phone,
    birth_date: params.birthDate || null,
    traveler_group: params.travelerGroup,
  };
}

function getUpdateUserStatusPayload({ status }: UpdateUserParams) {
  return {
    operation: 'update_status',
    status,
  };
}

function getUpdateCompanyUserExternalIdPayload({
  externalId = null,
}: UpdateCompanyUserParams) {
  return {
    operation: 'set_user_external_id',
    external_id: externalId,
  };
}

function getUpdateCompanyUserSupervisorPayload(
  params: UpdateCompanyUserParams,
) {
  return {
    operation: 'set_supervisor',
    supervisor_id: params.supervisorId || null,
  };
}

function getUpdateCompanyUserRolesPayloaduser({
  roles = [],
}: UpdateCompanyUserParams) {
  return {
    operation: 'set_company_roles',
    roles,
  };
}

export function getUpdateUserPayload(
  params: UpdateUserParams,
  applyAll: boolean,
) {
  const updates = [];

  switch (params.level) {
    case UserLevel.SYSTEM:
      updates.push(
        getUpdateSystemUserDetailsPayload(params),
        getUpdateUserStatusPayload(params),
      );
      break;

    case UserLevel.DOMAIN:
      updates.push(
        getUpdateDomainUserDetailsPayload(params),
        getUpdateUserStatusPayload(params),
      );
      if (applyAll) {
        updates.push(getUpdateDomainUserAdditionalDomainsPayload(params));
      }
      break;

    case UserLevel.COMPANY:
      updates.push(getUpdateCompanyUserDetailsPayloaduser(params));
      if (rolesContainsAdminRole(params.roles) || applyAll) {
        updates.push(
          getUpdateCompanyUserSupervisorPayload(params),
          getUpdateCompanyUserExternalIdPayload(params),
          getUpdateCompanyUserRolesPayloaduser(params),
          getUpdateUserStatusPayload(params),
        );
      }
      break;

    default:
      throw new Error(
        'error in function getUpdateUserPayload : cannot update a user without a level',
      );
  }

  return {
    user_id: params.id,
    updates,
  };
}
