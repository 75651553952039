import { SortTypes } from '@neo1/core/modules/config/constants';
import { ActionTypeName as usersActions, Actions } from './actions';
import { State } from './types';

const initialState: State = {
  companyUserIds: [],
  defaultCodingByUserId: {},
  filters: {
    search: '',
    sort: { prop: 'displayName', dir: SortTypes.ASC },
  },
};

export default function reducer(state = initialState, action: Actions = null) {
  switch (action?.type) {
    case usersActions.SET_COMPANY_USER_IDS:
      return {
        ...state,
        companyUserIds: action.companyUserIds,
      };
    case usersActions.ADD_COMPANY_USER:
      return {
        ...state,
        companyUserIds: [...state.companyUserIds, action.companyUserId],
      };

    case usersActions.DELETE_COMPANY_USER:
      return {
        ...state,
        companyUserIds: state.companyUserIds.filter(
          (id) => id !== action.companyUserId,
        ),
      };

    case usersActions.SET_DEFAULT_CODING_BY_USER_ID:
      return {
        ...state,
        defaultCodingByUserId: action.defaultCodingByUserId,
      };

    case usersActions.UPDATE_USER_DEFAULT_CODING:
      return {
        ...state,
        defaultCodingByUserId: {
          ...(state.defaultCodingByUserId || {}),
          [action.payload.userId]: action.payload.defaultCoding,
        },
      };

    case usersActions.SET_SEARCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.search,
        },
      };

    case usersActions.SET_SORT_OPTIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: {
            ...state.filters.sort,
            ...action.options,
          },
        },
      };

    default:
      return state;
  }
}
