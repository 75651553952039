import { createContext, useCallback } from 'react';
import selectActingUserPersistedData from 'modules/App/redux/persisted/selector';
import { useSelector, useDispatch } from 'react-redux';
import {
  UserPersitedDataKey,
  UserPersistedData,
} from '@neo1/core/modules/persisted/types';
import {
  set as setPersistedData,
  del as deletePersistedData,
} from 'modules/App/redux/persisted/thunks';
import { selectActingUser } from 'modules/Authentification/redux/selectors';

export const PersistedDataContext = createContext(null);

export const usePersistedData = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectActingUser);
  const data = useSelector(selectActingUserPersistedData) || {};

  const setData = useCallback(
    async (key: UserPersitedDataKey, value: UserPersistedData[typeof key]) => {
      await dispatch(setPersistedData(key, value));
    },
    [dispatch, user],
  );

  const delData = useCallback(
    async (key: UserPersitedDataKey, value: UserPersistedData[typeof key]) => {
      await dispatch(deletePersistedData(key, value));
    },
    [dispatch, user],
  );

  const actions = {
    setData,
    delData,
  };

  return { data, actions };
};
