import { CompanyFeature } from '@neo1/client';
import wrapAsync from 'utils/wrapAsync';
import {
  ROUTE_PATH_PURCHASE_BUDGETS,
  ROUTE_NAME_PURCHASE_BUDGETS,
} from './constants';

const route = {
  path: ROUTE_PATH_PURCHASE_BUDGETS,
  name: ROUTE_NAME_PURCHASE_BUDGETS,
  title: 'Budget',
  strict: true,
  exact: true,
  component: wrapAsync(
    () =>
      import(
        /* webpackChunkName: "budgets" */
        '.'
      ),
  ),
  menu: {
    iconClass: 'budget',
    userLevels: ['company'],
    companyFeatures: [CompanyFeature.Spend],
    hasBadge: true,
  },
};

export default route;
