import { UUID } from '../common/strings';
import { reify } from '../normalize';
import { Entity } from '../types';
import {
  RejectBudgetReasons,
  RejectChangesReasons,
} from '../../rpc/api/purchase';

export enum VirtualBudgetHistoryEvent {
  Created = 'created',
  Updated = 'updated',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  ChangesRequested = 'changes_requested',
  ChangesApproved = 'changes_approved',
  ChangesRejected = 'changes_rejected',
  Frozen = 'frozen',
  Unfrozen = 'unfrozen',
  Canceled = 'canceled',
}

interface VirtualBudgetHistoryBase extends Entity {
  event: VirtualBudgetHistoryEvent;
  time: string;
  effectiveUser: string;
  effectiveUserId: UUID;
  realUser: string;
  realUserId: UUID;
}

interface VirtualBudgetCreated extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.Created;
  startDate: string;
  endDate: string;
  requestedAmount: {
    amount: number;
    currency: string;
  };
}

interface VirtualBudgetUpdated extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.Updated;
  startDate: string;
  endDate: string;
  requestedAmount: {
    amount: number;
    currency: string;
  };
}

interface VirtualBudgetSubmitted extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.Submitted;
  startDate: string;
  endDate: string;
  requestedAmount: {
    amount: number;
    currency: string;
  };
}

interface VirtualBudgetApproved extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.Approved;
  startDate: string;
  endDate: string;
  requestedAmount: {
    amount: number;
    currency: string;
  };
  approvedBy: UUID;
}

interface VirtualBudgetRejected extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.Rejected;
  rejectReason: RejectBudgetReasons;
  rejectComment: string | null;
  rejectedBy: {
    source: RejectSource;
    userId?: UUID; // field is present only if source value is 'user'
    userFullName?: string; // field is present only if source value is 'user'
  };
  rejectedAmount: {
    amount: number;
    currency: string;
  };
}

interface VirtualBudgetChangesRequested extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.ChangesRequested;
  justification: string;
  additionalAmount: {
    amount: number;
    currency: string;
  };
  newEndDate: string;
}

interface VirtualBudgetChangesApproved extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.ChangesApproved;
  additionalAmount: {
    amount: number;
    currency: string;
  };
  newEndDate: string;
}

interface VirtualBudgetChangesRejected extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.ChangesRejected;
  rejectReason: RejectChangesReasons;
  rejectComment: string | null;
  rejectedBy: {
    source: RejectSource;
    userId?: UUID; // field is present only if source value is 'user'
    userFullName?: string; // field is present only if source value is 'user'
  };
  additionalAmount: {
    amount: number;
    currency: string;
  };
  newEndDate: string;
}
interface VirtualBudgetFrozen extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.Frozen;
  reason: string;
  frozenBy: {
    userId?: UUID;
    userFullName?: string;
  };
}
interface VirtualBudgetUnfrozen extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.Unfrozen;
  reason: string;
  unfrozenBy: {
    userId?: UUID;
    userFullName?: string;
  };
}
interface VirtualBudgetCanceled extends VirtualBudgetHistoryBase {
  event: VirtualBudgetHistoryEvent.Canceled;
  canceledBy: {
    userId?: UUID;
    userFullName?: string;
  };
}
enum RejectSource {
  User = 'user',
  System = 'system',
}

export type VirtualBudgetHistory =
  | VirtualBudgetCreated
  | VirtualBudgetUpdated
  | VirtualBudgetSubmitted
  | VirtualBudgetApproved
  | VirtualBudgetRejected
  | VirtualBudgetChangesRequested
  | VirtualBudgetChangesApproved
  | VirtualBudgetChangesRejected
  | VirtualBudgetUnfrozen
  | VirtualBudgetFrozen
  | VirtualBudgetCanceled;

const VirtualBudgetHistoryEntityName = 'BudgetHistory';

export function normalizeVirtualBudgetHistory(
  result: any,
): VirtualBudgetHistory {
  const data = reify(result);

  const event = data.getString('event_type');

  const base = {
    entityType: VirtualBudgetHistoryEntityName,
    id: data.getNumber('event_id').toString(),
    time: data.getString('time'),
    effectiveUser: data.getString('effective_user_name'),
    effectiveUserId: data.getUuid('effective_user_id'),
    realUser: data.getString('real_user_name'),
    realUserId: data.getUuid('real_user_id'),
  };

  const startDate = data.getString('start_date');
  const endDate = data.getString('end_date');
  const requestedAmount = {
    amount: data.getNumber('requested_amount.amount'),
    currency: data.getString('requested_amount.currency'),
  };

  switch (event) {
    case VirtualBudgetHistoryEvent.Created:
    case VirtualBudgetHistoryEvent.Updated:
    case VirtualBudgetHistoryEvent.Submitted:
      return {
        ...base,
        event,
        startDate,
        endDate,
        requestedAmount,
      };
    case VirtualBudgetHistoryEvent.Approved:
      return {
        ...base,
        event,
        startDate,
        endDate,
        requestedAmount,
        approvedBy: data.getUuid('approved_by'),
      };
    case VirtualBudgetHistoryEvent.Rejected:
      return {
        ...base,
        event,
        rejectReason: data.getString(
          'reject_reason',
        ) as unknown as RejectBudgetReasons,
        rejectComment: data.getStringOrNull('reject_comment'),
        rejectedBy: {
          source: data.getString('rejected_by.source') as RejectSource,
          userId: data.getUuid('rejected_by.user_id'),
          userFullName: data.getString('rejected_by.user_full_name'),
        },
        rejectedAmount: {
          amount: data.getNumber('rejected_amount.amount'),
          currency: data.getString('rejected_amount.currency'),
        },
      };
    case VirtualBudgetHistoryEvent.ChangesRequested:
      return {
        ...base,
        event,
        justification: data.getStringOrNull('justification'),
        additionalAmount: {
          amount: data.getNumber('additional_amount.amount'),
          currency: data.getString('additional_amount.currency'),
        },
        newEndDate: data.getString('new_end_date'),
      };
    case VirtualBudgetHistoryEvent.ChangesApproved:
      return {
        ...base,
        event,
        additionalAmount: {
          amount: data.getNumber('additional_amount.amount'),
          currency: data.getString('additional_amount.currency'),
        },
        newEndDate: data.getString('new_end_date'),
      };
    case VirtualBudgetHistoryEvent.ChangesRejected:
      return {
        ...base,
        event,
        rejectReason: data.getString(
          'reject_reason',
        ) as unknown as RejectChangesReasons,
        rejectComment: data.getStringOrNull('reject_comment'),
        rejectedBy: {
          source: data.getString('rejected_by.source') as RejectSource,
          userId: data.getUuid('rejected_by.user_id'),
          userFullName: data.getString('rejected_by.user_full_name'),
        },
        additionalAmount: {
          amount: data.getNumber('additional_amount.amount'),
          currency: data.getString('additional_amount.currency'),
        },
        newEndDate: data.getString('new_end_date'),
      };
    case VirtualBudgetHistoryEvent.Frozen:
      return {
        ...base,
        event,
        reason: data.getString('reason'),
        frozenBy: {
          userId: data.getUuid('frozen_by.userId'),
          userFullName: data.getString('frozen_by.user_full_name'),
        },
      };
    case VirtualBudgetHistoryEvent.Unfrozen:
      return {
        ...base,
        event,
        reason: data.getString('reason'),
        unfrozenBy: {
          userId: data.getUuid('unfrozen_by.userId'),
          userFullName: data.getString('unfrozen_by.user_full_name'),
        },
      };
    case VirtualBudgetHistoryEvent.Canceled:
      return {
        ...base,
        event,
        canceledBy: {
          userId: data.getUuid('canceled_by.userId'),
          userFullName: data.getString('canceled_by.user_full_name'),
        },
      };
    default:
      return null;
  }
}
