import { AmexEnrollmentStatus } from '@neo1/core/modules/settings/amexVirtualAccounts/types';
import { AmexVirtualAccountsState } from './types';
import {
  AmexVitualAccountsActionsType,
  AmexVitualAccountsAction,
} from './actions';

const initialState: AmexVirtualAccountsState = {
  enrollmentStatus: null,
  enrollmentErrors: null,
  accountIds: [],
  enabledUsers: [],
};

export default (
  state: AmexVirtualAccountsState = initialState,
  action: AmexVitualAccountsAction = null,
): AmexVirtualAccountsState => {
  switch (action?.type) {
    case AmexVitualAccountsActionsType.SetEnrollmentStatus:
      return {
        ...state,
        enrollmentStatus: action.status,
      };

    case AmexVitualAccountsActionsType.SetEnrollmentErrors:
      return {
        ...state,
        enrollmentErrors: action.errors,
      };

    case AmexVitualAccountsActionsType.SetAccounts:
      return {
        ...state,
        accountIds: action.ids,
      };

    case AmexVitualAccountsActionsType.SetEnabledUsers:
      return {
        ...state,
        enabledUsers: action.enabledUsers,
      };

    case AmexVitualAccountsActionsType.DisconnectOK:
      return {
        ...state,
        enrollmentStatus: AmexEnrollmentStatus.NOT_STARTED,
      };

    default:
      return state;
  }
};
