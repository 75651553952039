import classNames from 'classnames';

import { TableProps } from '../types';
import { useSelection } from '../selectionContext';
import TableSelectAllCell from './SelectAllInput';
import ExportButton from './ExportButton';
import SortButton from './SortButton';
import styles from './TableHead.module.css';

type Props = {
  sort: TableProps['sort'];
  onSortChange: TableProps['onSortChange'];
  exportActions: TableProps['exportActions'];
  columns: TableProps['columns'];
  hasRowDetail: boolean;
};

const TableHead = ({
  columns,
  sort,
  onSortChange,
  exportActions,
  hasRowDetail,
}: Props) => {
  const selectionContext = useSelection();

  return (
    <thead data-testid="tableHead">
      <tr className={styles.row}>
        {selectionContext && (
          <th className={classNames(styles.cell, styles.smallCell)}>
            <TableSelectAllCell />
          </th>
        )}
        {hasRowDetail && (
          <th
            className={classNames(styles.cell, styles.smallCell)}
            data-testid="rowDetailToggleHeadCell"
          >
            <span />
          </th>
        )}
        {columns.map(({ title, prop, sortProp, style }) => (
          <th
            key={prop}
            className={classNames(styles.cell, styles[style?.alignment])}
          >
            <div
              className={styles.cellContent}
              title={onSortChange ? `Sort by ${title}` : title}
            >
              {onSortChange ? (
                <SortButton
                  prop={sortProp ?? prop}
                  sort={sort}
                  onSortChange={onSortChange}
                >
                  {title}
                </SortButton>
              ) : (
                title
              )}
            </div>
          </th>
        ))}
        {exportActions?.length ? (
          <th className={classNames(styles.smallCell, styles.exportCell)}>
            <div className={styles.exportBtnWrapper}>
              <ExportButton exportActions={exportActions} />
            </div>
          </th>
        ) : null}
      </tr>
    </thead>
  );
};

export default TableHead;
