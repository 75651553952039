import { Company, CompanyFeature } from '@neo1/client/lib/entities/company';

export function hasPurchaseService(company: Company, service: string) {
  return company.purchaseServices.includes(service);
}

export function hasSpendManagementFeature(company: Company) {
  return company && company.features.includes(CompanyFeature.Spend);
}

export function hasReportingFeature(company: Company) {
  return company && company.features.includes(CompanyFeature.Reporting);
}

export function hasNeoTravelFeature(company: Company) {
  return company && company.features.includes(CompanyFeature.Travel);
}

export function hasAmexVirtualAccountsFeature(company: Company) {
  return (
    company && company.features.includes(CompanyFeature.AmexVirtualAccounts)
  );
}

export function hasFeature(company: Company, feature: CompanyFeature) {
  return company && company.features.includes(feature);
}
