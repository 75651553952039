import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { isAmountLike } from '@neo1/client/lib/entities/common/amount';

const isJsons = (array: any) =>
  Array.isArray(array) &&
  array.every((row) => typeof row === 'object' && !(row instanceof Array));

const isArrays = (array: any) =>
  Array.isArray(array) && array.every((row) => Array.isArray(row));

const jsonsHeaders = (array: Record<string, any>[]) =>
  Array.from(
    array
      .map((json) => Object.keys(json))
      .reduce((a, b) => Array.from(new Set([...a, ...b])), []),
  );

const jsons2arrays = (
  jsons: Record<string, any>[],
  headers: string[],
): string[][] => {
  headers = headers || jsonsHeaders(jsons);
  const data = jsons.map((object) =>
    headers.map((header) => (header in object ? object[header] : '')),
  );
  return [headers, ...data];
};

const joiner = (data: string[][], separator: string = ',') =>
  data
    .map((row) =>
      row.map((element: string) => '"' + element + '"').join(separator),
    )
    .join(`\n`);

const arrays2csv = (data: string[][], headers: string[], separator: string) =>
  joiner(headers ? [headers, ...data] : data, separator);

const jsons2csv = (data: Record<string, any>[], headers: string[], separator) =>
  joiner(jsons2arrays(data, headers), separator);

export const toCSV = (
  data: Record<string, any>[] | string[][],
  headers: string[],
  separator: string,
) => {
  if (isJsons(data))
    return jsons2csv(data as Record<string, any>[], headers, separator);
  if (isArrays(data)) return arrays2csv(data as string[][], headers, separator);
  throw `Data should be an "Array of arrays" OR "Array of objects" `;
};

/**
 * Wraps a csv value
 * @param {*} val
 */
export const csvValue = (val: string) => {
  if (Array.isArray(val)) {
    return val.join(', ');
  }

  if (isAmountLike(val)) {
    return `${val.currency} ${val.amount.toFixed(2)}`;
  }

  if (isNil(val)) {
    return '';
  }

  if (isString(val)) {
    return val.trim();
  }

  return val;
};

export const buildURI = (
  data: Record<string, any>[] | string[][],
  headers: string[],
  separator: string,
) =>
  `data:text/csv;charset=utf-8,\uFEFF${encodeURIComponent(
    toCSV(data, headers, separator),
  )}`;
