import amplitude from 'amplitude-js';
import { ROOT_UUID } from '@neo1/client';

import { replaceZeroWidthChars } from '@neo1/core/utils';
import { EventParams } from './types';

let isAmplitudeInstanceInitialized: boolean = false;

export const getIsAmplitudeInstanceInitialized = () =>
  isAmplitudeInstanceInitialized;
export const setIsAmplitudeInstanceIntialized = (value: boolean) => {
  isAmplitudeInstanceInitialized = value;
};

const project = amplitude.getInstance();

export function initializeAmplitudeInstance(APIKey: string | undefined) {
  if (APIKey && !getIsAmplitudeInstanceInitialized()) {
    project.init(APIKey);
    setIsAmplitudeInstanceIntialized(true);
  }
}

export const logEvent = ({ event, eventCallback, data }: EventParams) => {
  if (getIsAmplitudeInstanceInitialized()) {
    project.logEvent(replaceZeroWidthChars(event), data, eventCallback);
  }
};
export const regenerateDeviceId = () => {
  //  https://amplitude.github.io/Amplitude-JavaScript/#amplitudeclientregeneratedeviceid
  if (getIsAmplitudeInstanceInitialized()) {
    project.regenerateDeviceId();
  }
};

export const setUserId = (id: string) => {
  if (getIsAmplitudeInstanceInitialized()) {
    // Somehow our ROOT_UUID does not work for amplitude, so we replace it by 'root'.
    if (id === ROOT_UUID) {
      project.setUserId('root');
      return;
    }
    project.setUserId(id);
  }
};

export * from './utils';
