import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import * as rpcBatchUtils from '@neo1/client/lib/rpc/batch';
import { getClient } from '../../../utils/client';
import { getChildrenIds, hasChildren } from '../utils/links';
import { makePayload } from '../../../utils/rpcPayload';
import {
  Company,
  CompanyGroupUser,
  CompanyUser,
  normalizeCompany,
  normalizeCompanyGroupUser,
  normalizeUser,
  RpcMethodName,
} from '@neo1/client';
import { CompanyAddress } from '@neo1/client/test-utils/factory/rpc';

async function getCompaniesByIds(
  ...companiesIds: string[]
): Promise<Company[]> {
  if (companiesIds.length === 0) {
    return Promise.resolve([]);
  }

  return rpcBatchUtils.unwrapResults(
    await getClient().batchCommands(
      companiesIds.map((companyId) => ({
        method: 'get_company',
        params: { company_id: companyId },
      })),
    ),
    (result: any) => (isNil(result) ? undefined : normalizeCompany(result)),
  );
}

export function fetchCompanyChildren(company: Company): Promise<Company[]> {
  return hasChildren(company)
    ? getCompaniesByIds(...getChildrenIds(company))
    : Promise.resolve([]);
}

export type SaveCompanyPayload = {
  banking_ids: Company['bankingIds'];
  name: Company['name'];
  contact_email: Company['contactEmail'];
  trade_name?: string;
  phone_number: Company['phoneNumber'];
  address: CompanyAddress;
  status?: Company['status'];
  features?: Company['features'];
  company_id?: Company['id'];
  default_dashboard_layout: Company['defaultDashboardLayout'];
  defaultDashboardLayoutChanged?: boolean;
  travel_contact_phone1?: Company['travelContactPhone1'];
  travel_contact_phone2?: Company['travelContactPhone2'];
  whatsapp_phone?: Company['whatsAppPhone'];
  travel_contact_email?: Company['travelContactEmail'];
};

export async function save(payload: SaveCompanyPayload): Promise<Company> {
  const updates = [
    makePayload({
      operation: 'update_details',
      banking_ids: payload.banking_ids,
      name: payload.name,
      contact_email: payload.contact_email,
      trade_name: payload.trade_name,
      phone_number: payload.phone_number,
      address: payload.address,
      default_dashboard_layout: payload.default_dashboard_layout,
    }),
  ];

  if (payload.status) {
    updates.push({
      operation: 'update_status',
      status: payload.status,
    });
  }

  if (payload.features) {
    updates.push({
      operation: 'set_features',
      features: payload.features,
    });
  }

  if (
    !isUndefined(payload.travel_contact_email) ||
    !isUndefined(payload.travel_contact_phone1) ||
    !isUndefined(payload.travel_contact_phone2) ||
    !isUndefined(payload.whatsapp_phone)
  ) {
    updates.push({
      operation: 'update_contact_details',
      voice_phone_number1: payload.travel_contact_phone1,
      voice_phone_number2: payload.travel_contact_phone2,
      whatsapp_phone_number: payload.whatsapp_phone,
      travel_contact_email: payload.travel_contact_email,
    });
  }

  const companyData = await getClient().sendCommand({
    method: 'update_company',
    params: {
      company_id: payload.company_id,
      updates,
    },
  });
  if (payload.defaultDashboardLayoutChanged) {
    await getClient().sendCommand({
      method: 'delete_company_users_data_by_storage_key',
      params: {
        company_id: payload.company_id,
        key: 'dashboardLayouts',
      },
    });
  }
  return normalizeCompany(companyData);
}

export function createCompany(params: any) {
  return getClient()
    .sendCommand({
      method: 'new_company',
      params: {
        address: params.address || null,
        banking_ids: params.banking_ids || [],
        company_id: params.company_id,
        country_code: params.country_code,
        domain_id: params.domain_id,
        features: params.features,
        name: params.name,
        contact_email: params.contact_email,
        phone_number: params.phone_number,
        website: params.website,
        trade_name: params.trade_name || null,
        status: params.status,
        default_dashboard_layout: params.default_dashboard_layout,
        voice_phone_number1: params.travel_contact_phone1,
        voice_phone_number2: params.travel_contact_phone2,
        whatsapp_phone_number: params.whatsapp_phone,
        travel_contact_email: params.travel_contact_email,
      },
    })
    .then(normalizeCompany);
}

export function fetchById(companyId: string) {
  return getClient()
    .sendCommand({ method: 'get_company', params: { company_id: companyId } })
    .then(normalizeCompany);
}

export function linkCompanies(parent: Company, child: Company) {
  return getClient()
    .sendCommand({
      method: 'link_company',
      params: {
        parent_id: parent.company_id || null,
        child_id: child.company_id || null,
      },
    })
    .then(() => getCompaniesByIds(parent.company_id, child.company_id));
}

export function unlinkCompanies(parent: Company, child: Company) {
  return getClient()
    .sendCommand({
      method: 'unlink_company',
      params: {
        parent_id: parent.company_id || null,
        child_id: child.company_id || null,
      },
    })
    .then(() => getCompaniesByIds(parent.company_id, child.company_id));
}

export function fetchCompanyGroupUsers(
  companyId: string,
): Promise<CompanyGroupUser[]> {
  return getClient()
    .sendCommand({
      method: RpcMethodName.GetCompanyGroupUsers,
      params: { company_id: companyId },
    })
    .then((users) => users.map(normalizeCompanyGroupUser));
}

export function fetchCompanyUsers(companyId: string): Promise<CompanyUser[]> {
  return getClient()
    .sendCommand({
      method: RpcMethodName.GetCompanyUsers,
      params: { company_id: companyId },
    })
    .then((users) => users.map(normalizeUser));
}

export async function getCompanySiblings(company: Company) {
  if (!company.links.parent) {
    return [];
  }

  const parentCompany = await fetchById(company.links.parent);
  const siblings = await getCompaniesByIds(...parentCompany.links.children);

  return (siblings || []).concat(parentCompany);
}

export function syncCompany(companyId: Company['id']) {
  return getClient().sendCommand({
    method: 'sync_company_travel_integration',
    params: {
      company_id: companyId,
    },
  });
}
