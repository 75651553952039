import { useEffect } from 'react';

import Button, {
  ButtonKind,
  ButtonSize,
  ButtonTheme,
} from 'components/elements/Button';
import Icon, { IconName } from 'components/elements/Icon';
import {
  Toaster,
  ToasterType,
} from 'modules/App/redux/notifications/toaster/types';
import classNames from 'classnames';
import ProgressBar from 'components/elements/ProgressBar';

import styles from './Message.module.css';

const iconByType: Record<ToasterType, IconName> = {
  error: 'warning',
  success: 'check',
  warning: 'info',
  progress: 'info',
};

type Props = {
  onHideNotification?: Function;
  notification: Toaster;
};

const NotificationMessage = ({ notification, onHideNotification }: Props) => {
  let hideTimeout: NodeJS.Timeout;

  const disappear = () => {
    const { onDismiss } = notification;

    if (typeof onDismiss === 'function') {
      onDismiss();
    }

    if (typeof onHideNotification === 'function') {
      onHideNotification(notification);
    }
  };

  const cancelDisappearTimeout = () => {
    if (hideTimeout) {
      clearTimeout(hideTimeout);
      hideTimeout = undefined;
    }
  };

  const setDisappearTimeout = () => {
    cancelDisappearTimeout();

    if (typeof notification.timeout === 'number' && notification.timeout > 0) {
      hideTimeout = setTimeout(disappear, notification.timeout);
    }
  };

  useEffect(() => {
    if (notification.visible) {
      setDisappearTimeout();
    }
  }, []);

  const onClickAction = () => {
    const {
      actionButton: { onClick },
    } = notification;

    if (onClick) {
      onClick();
    }

    disappear();
  };

  const { type, message, actionButton, showCancel, progress } = notification;

  const handleMouseOver = () => cancelDisappearTimeout();

  const handleMouseOut = () => setDisappearTimeout();

  const containerClassName = classNames(
    styles[notification.type] || styles.success,
    styles.container,
  );

  return (
    <div
      className={containerClassName}
      onFocus={handleMouseOver}
      onMouseOver={handleMouseOver}
      onBlur={handleMouseOut}
      onMouseOut={handleMouseOut}
    >
      <div className={styles.main} data-testid="notification">
        <div className={styles.iconContainer}>
          <Icon name={iconByType[type]} className={styles.icon} />
        </div>
        <div className={styles.messageContainer}>
          <h3 className={styles.errorTitle}>{type}</h3>
          <p className={styles.message}>{message}</p>
        </div>
      </div>
      {actionButton && (
        <footer className={styles.actions}>
          {showCancel && (
            <Button
              label="Cancel"
              theme={ButtonTheme.Ghost}
              kind={ButtonKind.Neutral}
              onClick={() => disappear()}
            />
          )}
          <Button
            label={actionButton.label}
            theme={ButtonTheme.Outlined}
            kind={ButtonKind.Neutral}
            onClick={() => onClickAction()}
          />
        </footer>
      )}
      {type === 'progress' && (
        <ProgressBar value={progress} kind="warning" height={5} />
      )}
      <Button
        label="Close notification"
        iconName="close"
        iconOnly
        wrapperClassName={styles.btnQuit}
        theme={ButtonTheme.Discrete}
        size={ButtonSize.Small}
        kind={ButtonKind.Neutral}
        onClick={() => disappear()}
        testId="notificationCloseBtn"
      />
    </div>
  );
};

NotificationMessage.defaultProps = {
  onHideNotification: undefined,
};

export default NotificationMessage;
