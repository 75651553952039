import { searchPlaces } from '@neo1/core/utils/maps';

import { useCallback, useEffect, useState } from 'react';

import useAsyncState from 'hooks/asyncState';
import useViewerLocation from './useViewerLocation';

export type Option = {
  label: string;
  placeId: string;
};

type Props = {
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  searchPlacesError: any;
  placesSuggestions: Option[];
  clearSuggestions: () => void;
};

const useAddressSuggestions = (): Props => {
  const [address, setAddress] = useState<string>('');
  const [placesSuggestions, setSuggestions] = useState<Option[]>([]);
  const clearSuggestions = () => {
    // discard pending suggestions.
    setSuggestions([]);
  };
  const location = useViewerLocation();

  const {
    execute: executeSearchPlaces,
    data: placesPredictions,
    error: searchPlacesError,
  } = useAsyncState(searchPlaces);

  useEffect(() => {
    if (placesPredictions) {
      setSuggestions(
        placesPredictions.map(({ description, place_id }) => ({
          placeId: place_id,
          label: description,
        })),
      );
    }
  }, [placesPredictions]);

  const handleAddressChange = useCallback(() => {
    executeSearchPlaces(address, location);
  }, [address, executeSearchPlaces, location]);

  useEffect(handleAddressChange, [handleAddressChange, address]);

  return {
    setAddress,
    searchPlacesError,
    placesSuggestions,
    clearSuggestions,
  };
};

export default useAddressSuggestions;
