import { EulaConfig } from '@neo1/core/modules/eula/api';
import useLogout from 'modules/Authentification/useLogout';
import EditForm from 'components/composed/EditForm';
import CheckBox from 'components/elements/form/fields/CheckBox';
import FormField from 'components/elements/form/Field';
import { FormikErrors } from 'formik';
import { useIntl } from 'react-intl';
import Link, { LinkFontSize } from 'components/elements/Link';
import { useEulaConfig } from './hook';
import messages from './messages';

type Props = {
  companyId: string;
  setIsTermsModalOpen: (arg: boolean) => void;
  isTermsModalOpen: boolean;
  onAccept(version: EulaConfig['version']): Promise<void>;
  onError: (e: Error) => void;
};

type FormValue = { accept: boolean };

const validate = (values: FormValue) => {
  const errors: FormikErrors<FormValue> = {};

  if (!values.accept) {
    errors.accept = 'This field is required';
  }

  return errors;
};

const EulaModal = ({
  companyId,
  setIsTermsModalOpen,
  isTermsModalOpen,
  onAccept,
  onError,
}: Props) => {
  const { eulaConfig, isLoading, eulaError } = useEulaConfig({ companyId });
  const intl = useIntl();
  const { execute: logout } = useLogout();

  const onAcceptTerms = async () => {
    try {
      await onAccept(eulaConfig.version);
      return setIsTermsModalOpen(false);
    } catch (error) {
      return onError(error);
    }
  };

  return (
    <EditForm
      initialValues={{ accept: false }}
      title="Terms & Conditions"
      isOpen={isTermsModalOpen}
      onClose={logout}
      testId="eulaModal"
      onSubmit={onAcceptTerms}
      validate={validate}
    >
      <FormField name="accept" required>
        <CheckBox
          disabled={isLoading || !!eulaError}
          label={
            <span>
              {intl.formatMessage(messages.IAgree)}
              <Link
                aria-label="viewTerms"
                fontSize={LinkFontSize.Inherit}
                title="view terms and conditions"
                target="_blank"
                rel="noopener noreferrer"
                to={{ pathname: eulaConfig?.url }}
              >
                {intl.formatMessage(messages.termsAndConditions)}
              </Link>
            </span>
          }
        />
      </FormField>
    </EditForm>
  );
};

export default EulaModal;
