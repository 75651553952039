import { ReactNode } from 'react';
import { isDark } from '@neo1/core/utils';
import classNames from 'classnames';
import Logo from 'components/layout/PublicPage/Logo';
import Headline from 'components/layout/PublicPage/Headline';
import { getConfigValue } from 'config';

import { ReactComponent as Flourish } from '../../../../public/images/backgrounds/flourish_cropped.svg';

import styles from './Content.module.css';

export enum Theme {
  Grey = 'grey',
  Green = 'green',
}

export interface Props {
  hideHeader?: boolean;
  content: ReactNode;
  sideContent?: ReactNode;
  title: ReactNode;
  subTitle: ReactNode;
  testId?: string;
  withHeadline?: boolean;
  theme?: Theme;
  logo?: ReactNode;
  mainContentWidth?: number;
}

const Content = ({
  hideHeader,
  content,
  sideContent,
  title,
  subTitle,
  testId,
  withHeadline,
  theme,
  logo,
  mainContentWidth,
}: Props) => (
  <div
    className={classNames(styles.wrapper, theme && styles[`${theme}`])}
    data-testid="content"
  >
    <div
      data-testid="branding"
      className={classNames(
        styles.branding,
        !sideContent && styles.noSideContent,
        !isDark(getConfigValue('branding')?.primaryColor) && styles.dark,
      )}
    >
      <header className={styles.header}>
        {logo}
        <div className={classNames(styles.headline, 'textMSemibold')}>
          {withHeadline && <Headline />}
        </div>
      </header>
      <div className={styles.sideContent}>{sideContent}</div>
      <div className={styles.flourishWrapper}>
        <Flourish className={styles.flourish} />
      </div>
    </div>
    <div
      data-testid="form"
      className={styles.form}
      style={{ width: mainContentWidth ? `${mainContentWidth}px` : 'unset' }}
    >
      {!hideHeader && (
        <div className={styles.headerWrapper}>
          <header className={styles.header}>
            <div
              className={classNames(styles.title, 'header3')}
              data-testid={testId}
            >
              {title}
            </div>
          </header>
          {subTitle}
        </div>
      )}
      {content}
    </div>
  </div>
);

Content.defaultProps = {
  hideHeader: false,
  withHeadline: false,
  sideContent: undefined,
  theme: undefined,
  testId: '',
  logo: <Logo className={styles.logo} height={48} />,
  mainContentWidth: undefined,
};

export default Content;
