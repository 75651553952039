import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './RadioBox.module.css';

interface Props extends HTMLAttributes<HTMLInputElement> {
  text?: string;
  className?: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
}

function RadioBox({ text, id, className, disabled, ...props }: Props) {
  return (
    <label className={styles.container} htmlFor={id}>
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={id}
        type="radio"
        className={disabled ? styles.disabled : undefined}
        disabled={disabled}
      />
      {text && (
        <span
          className={classNames(
            styles.text,
            className,
            disabled && styles.disabled,
          )}
        >
          {text}
        </span>
      )}
    </label>
  );
}

RadioBox.defaultProps = {
  text: undefined,
  className: undefined,
  checked: false,
  disabled: false,
};

export default RadioBox;
