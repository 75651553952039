import { CompanyFeature, UserLevel } from '@neo1/client';
import { AppMenuRoute } from 'config/routes/utils';
import wrapAsync from 'utils/wrapAsync';
import Page from 'components/layout/Page';

import {
  ROUTE_NAME_RECEIPTS,
  ROUTE_PATH_RECEIPTS,
  ROUTE_PATH_RECEIPTS_TRAVEL,
  ROUTE_NAME_RECEIPTS_TRAVEL,
  ROUTE_PATH_RECEIPTS_SPEND,
  ROUTE_NAME_RECEIPTS_SPEND,
} from './constants';

const loadingPlaceholder = <Page isLoading />;

const receiptsRoute: AppMenuRoute = {
  path: ROUTE_PATH_RECEIPTS,
  name: ROUTE_NAME_RECEIPTS,
  menu: {
    iconClass: 'receipts',
    userLevels: [UserLevel.COMPANY],
    path: ROUTE_PATH_RECEIPTS_SPEND,
    companyFeatures: [CompanyFeature.Spend],
    hasBadge: true,
  },
  children: [
    {
      title: 'Spend Receipts',
      path: ROUTE_PATH_RECEIPTS_SPEND,
      name: ROUTE_NAME_RECEIPTS_SPEND,
      strict: true,
      exact: true,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "spendReceipts" */
            './pages/Spend'
          ),
        loadingPlaceholder,
      ),
    },
    {
      title: 'Travel Receipts',
      path: ROUTE_PATH_RECEIPTS_TRAVEL,
      name: ROUTE_NAME_RECEIPTS_TRAVEL,
      exact: true,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "travelReceipts" */
            './pages/Travel'
          ),
        loadingPlaceholder,
      ),
    },
  ],
};

export default receiptsRoute;
