import { CompanyUser, EMAIL_REGEX, User } from '@neo1/client';
import isObject from 'lodash/isObject';
import { isCompanyUser } from '@neo1/core/modules/users/utils';
import { EventParams } from './types';

export type LocationData = {
  pathname: string;
  search: string;
};

export interface ScreenData {
  zoomPercentage?: number;
  screenHeight?: number;
  screenWidth?: number;
}

interface LoginEventData extends ScreenData {
  roleList?: CompanyUser['rolesList'];
  isSupervisor: boolean;
}

export function getWindowData(): ScreenData {
  let zoomPercentage: number;
  const pixelRatio = Number(window?.devicePixelRatio);
  if (!Number.isNaN(pixelRatio)) {
    zoomPercentage = Math.ceil(pixelRatio * 100);
  }
  return {
    zoomPercentage,
    screenHeight: window?.screen?.height,
    screenWidth: window?.screen?.width,
  };
}

export function loginEventData(
  user: User,
  isSupervisor: boolean,
): LoginEventData {
  return {
    ...getWindowData(),
    isSupervisor,
    roleList: isCompanyUser(user) ? user.rolesList : undefined,
  };
}

export function removesEmail(data: any): any {
  if (typeof data === 'string' || data instanceof String) {
    return data.replace(EMAIL_REGEX, '***@***.***');
  }

  if (isObject(data)) {
    const newData = { ...data };
    Object.keys(newData).forEach((key) => {
      newData[key] = removesEmail(newData[key]);
    });
    return newData;
  }

  return data;
}

export const eventBasedOnLocation = (
  location: LocationData,
  event: string,
): EventParams => {
  const { pathname, search } = location;
  return {
    event,
    data: {
      location: { pathname, search },
    },
  };
};
