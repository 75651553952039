import isEqual from 'lodash/isEqual';
import { CompanyUser, Company } from '@neo1/client';
import { ReportingScopeFilterType } from '../constants';
import { hasChildren } from '../../companies/utils';
import {
  ReportDateRangeFilter,
  ReportParams,
  ReportScopeFilter,
} from '../types';
import {
  getDateRangeForLastMonths,
  getDateRangeToNow,
} from '../../../utils/dates';

/**
 * Generates a user level reporting scope filter
 * @param user
 */
const createUserScopeFilter = (user: CompanyUser): ReportScopeFilter => ({
  type: 'user',
  user_ids: [user.id],
  company_ids: [],
});

/**
 * Generates a company level reporting scope filter
 * @param user
 */
export const createCompanyScopeFilter = (
  user: CompanyUser,
): ReportScopeFilter => ({
  type: 'company',
  company_ids: [user.companyId],
  user_ids: [],
});

/**
 * Generates a organization level reporting scope filter
 * @param user
 */
const createOrganizationScopeFilter = (
  company: Company,
): ReportScopeFilter => ({
  type: 'company',
  company_ids: [company.company_id, ...company.links.children],
  user_ids: [],
});

/**
 * Makes a date range filter for last monthCount months
 * @param {*} monthCount
 */
const getDateRangeFilterForLastMonths = (
  monthCount: number = 1,
): ReportDateRangeFilter => {
  const [startDate, endDate] = getDateRangeForLastMonths(monthCount);
  return {
    start_date: startDate,
    end_date: endDate,
  };
};

/**
 * Makes a date range filter for last dayCount days
 * @param {*} daysCount
 */
export const getDateRangeFilterForLastDays = (
  daysCount: number = 1,
): ReportDateRangeFilter => {
  const [startDate, endDate] = getDateRangeToNow(daysCount, 'day');
  return {
    start_date: startDate,
    end_date: endDate,
  };
};

/**
 * Checks if given scope filter is company type
 * @param scopeFilter
 */
const isCompanyScopeFilter = (scopeFilter: ReportScopeFilter): boolean =>
  scopeFilter &&
  scopeFilter.type === 'company' &&
  scopeFilter.company_ids.length === 1;

/**
 * Checks if given scope filter is user type
 * @param scopeFilter
 */
const isUserScopeFilter = (scopeFilter: ReportScopeFilter): boolean =>
  scopeFilter && scopeFilter.type === 'user';

/**
 * Checks if given scope filter is organization type
 * @param scopeFilter
 */
const isOrganizationScopeFilter = (scopeFilter: ReportScopeFilter): boolean =>
  scopeFilter &&
  scopeFilter.type == 'company' &&
  scopeFilter.company_ids &&
  scopeFilter.company_ids.length > 1;

/**
 * Create Scope filter from given options
 * @param {*} context
 */
export const createScopeFilter = (context: any = {}): ReportScopeFilter => {
  const { user, company, scopeFilterType } = context;

  switch (scopeFilterType) {
    case ReportingScopeFilterType.ORGANIZATION:
      return createOrganizationScopeFilter(company);
    case ReportingScopeFilterType.COMPANY:
      return createCompanyScopeFilter(user);
    case ReportingScopeFilterType.CUSTOM:
      if (company) {
        return hasChildren(company)
          ? createOrganizationScopeFilter(company)
          : createCompanyScopeFilter(company);
      }
      return createUserScopeFilter(user);
    default:
      return createUserScopeFilter(user);
  }
};

/**
 * Generates default report options for given (report, user)
 * @param options
 * @returns {*}
 */
export const getDefaultParams = (options: any): ReportParams => {
  const {
    user: { preferredCurrency },
  } = options;
  return {
    scope: createScopeFilter(options),
    dateRange: getDateRangeFilterForLastMonths(6),
    fields: [],
    currency: preferredCurrency,
    topN: 6,
  };
};

export const scopeFilterTypeFromProps = (props: any) =>
  (props.match && props.match.params.scope) || ReportingScopeFilterType.USER;

/**
 * Detects given scope filter type
 * @param {*} scopeFilter to analyze
 */
export const getScopeFilterType = (scopeFilter: ReportScopeFilter) => {
  if (isOrganizationScopeFilter(scopeFilter)) {
    return ReportingScopeFilterType.ORGANIZATION;
  } else if (isCompanyScopeFilter(scopeFilter)) {
    return ReportingScopeFilterType.COMPANY;
  } else if (isUserScopeFilter(scopeFilter)) {
    return ReportingScopeFilterType.USER;
  }
  return ReportingScopeFilterType.CUSTOM;
};

/**
 * Sets params scope filter for given scope filter type
 */
export const setParamsScopeFilter = (params: any, options: any) => ({
  ...params,
  scope: createScopeFilter(options),
});

/**
 * Asserts if given reports params are same
 */
export const eqParams = (p1: ReportParams, p2: ReportParams) => {
  return p1 && isEqual(p1, p2);
};
