import { FormikErrors } from 'formik';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import not from 'lodash/negate';
import isString from 'lodash/isString';
import {
  CompanyActivationOwner,
  CompanyActivationData,
} from '@neo1/core/modules/persisted/types';
import { isValidString } from '@neo1/core/utils';
import {
  COMPANY_NAME_RULES,
  FIRST_NAME_RULES,
  isCompanyName,
  isEmail,
  isFirstName,
  isLastName,
  isTradeName,
  LAST_NAME_RULES,
  TRADE_NAME_RULES,
} from '@neo1/client';

import { useAddressUtils } from 'hooks/address';
import { isValidPhoneNumber } from 'utils/phoneNumbers';

export function isValidRegistrationNumber(
  registrationNumber: string,
  countryCode: string,
): boolean {
  switch (countryCode) {
    case 'GB':
      return !registrationNumber || /^\w{8}$/i.test(registrationNumber);
    case 'US':
      return isValidString(registrationNumber);
    default:
      return true;
  }
}

export function validateAboutStep({
  registrationNumber,
  yearOfCreation,
  companyKind,
  name,
  tradeName,
  industry,
  numberOfEmployee,
  country,
}: CompanyActivationData): FormikErrors<CompanyActivationData> {
  const errors: FormikErrors<CompanyActivationData> = {};

  if (!isValidRegistrationNumber(registrationNumber, country)) {
    errors.registrationNumber = 'This number is not valid';
  }

  const yearAsNumber = Number(yearOfCreation);

  if (!yearAsNumber || yearAsNumber < 1900) {
    errors.yearOfCreation = 'Business creation year must be valid';
  }

  if (yearAsNumber && yearAsNumber > new Date().getFullYear()) {
    errors.yearOfCreation = 'Business creation year can not be in the future';
  }

  if (!companyKind) {
    errors.companyKind = 'Your type of business is mandatory';
  }

  if (!name) {
    errors.name = 'Your legal name is mandatory';
  } else if (!isCompanyName(name)) {
    errors.name = COMPANY_NAME_RULES;
  }

  if (tradeName && !isTradeName(tradeName)) {
    errors.tradeName = TRADE_NAME_RULES;
  }

  if (!industry) {
    errors.industry = 'Industry is mandatory';
  }

  if (!numberOfEmployee) {
    errors.numberOfEmployee = 'Number of employees is mandatory';
  }

  return errors;
}

export function useValidateStep() {
  const { validateAddressValues } = useAddressUtils();

  function validateOwner(owner: CompanyActivationOwner) {
    const errors: FormikErrors<CompanyActivationOwner> = {};
    const ownerAddressErrors = validateAddressValues({
      streetLine1: owner.street,
      zipCode: owner.zipCode,
      city: owner.city,
      state: owner.state,
      countryCode: owner.country,
    });

    Object.assign(
      errors,
      omitBy(
        {
          ...ownerAddressErrors,
          street: ownerAddressErrors.streetLine1,
          country: ownerAddressErrors.countryCode,
        },
        isNil,
      ),
    );

    if (!isValidString(owner.firstName)) {
      errors.firstName = 'First name is mandatory';
    } else if (!isFirstName(owner.firstName)) {
      errors.firstName = FIRST_NAME_RULES;
    }

    if (!isValidString(owner.lastName)) {
      errors.lastName = 'Last name is mandatory';
    } else if (!isLastName(owner.lastName)) {
      errors.lastName = LAST_NAME_RULES;
    }

    if (!isValidString(owner.countryOfBirth)) {
      errors.countryOfBirth = 'Birth country is mandatory';
    }

    if (!owner.birthDate) {
      errors.birthDate = 'Date of birth must be a valid date';
    } else if (dayjs(owner.birthDate).add(18, 'years').isAfter(dayjs())) {
      errors.birthDate = 'Owner must be over 18 years old';
    }

    return errors;
  }

  const validateAddressStep = (
    values: CompanyActivationData,
  ): FormikErrors<CompanyActivationData> => {
    const errors = validateAddressValues({
      streetLine1: values.address,
      zipCode: values.zipCode,
      state: values.state,
      city: values.city,
      countryCode: values.country,
      streetLine2: values.address2,
    });

    if (errors.streetLine1) {
      errors.address = errors.streetLine1;
    }

    if (errors.streetLine2) {
      errors.address2 = errors.streetLine2;
    }

    if (
      !isString(values.phoneNumber) ||
      !isValidPhoneNumber(values.phoneNumber)
    ) {
      errors.phoneNumber = 'You must enter a valid phone number';
    }

    if (!isEmail(values.contactEmail)) {
      errors.contactEmail = 'You must enter a valid email';
    }

    return errors;
  };

  function validateOwnersStep({
    owners,
    editedOwnerIndex,
  }: CompanyActivationData): FormikErrors<CompanyActivationData> {
    // If 'editedOwnerIndex' is null, that means the user is viewing <OwnerDetails/>,
    // ready to send the form data, validation should not stop her/him at this point.
    const editedOwner = owners ? owners[editedOwnerIndex] : undefined;
    const errors: FormikErrors<CompanyActivationData> = {};

    if (!editedOwner) {
      return errors;
    }

    // Performs the validation only in the owner that is being edited.
    const ownersErrors = [{}, {}, {}, {}].map((ownerErrors, ownerIndex) => {
      if (ownerIndex === editedOwnerIndex) {
        return editedOwner ? validateOwner(editedOwner) : {};
      }
      return ownerErrors;
    });

    if (ownersErrors.some(not(isEmpty))) {
      errors.owners = ownersErrors;
    }

    return errors;
  }

  function isValidOwner(owner: CompanyActivationOwner) {
    return isEmpty(validateOwner(owner));
  }

  const validateFeatureUsage = ({
    travelFeatureUsage,
    purchasingFeatureUsage,
    expenseFeatureUsage,
  }: CompanyActivationData): FormikErrors<CompanyActivationData> => {
    const errors: FormikErrors<CompanyActivationData> = {};
    if (!expenseFeatureUsage) {
      errors.expenseFeatureUsage = 'This field is required';
    }
    if (!travelFeatureUsage) {
      errors.travelFeatureUsage = 'This field is required';
    }
    if (!purchasingFeatureUsage) {
      errors.purchasingFeatureUsage = 'This field is required';
    }
    return errors;
  };

  return {
    validateAboutStep,
    validateAddressStep,
    validateOwnersStep,
    isValidOwner,
    validateFeatureUsage,
  };
}
