import * as batchHelpers from '@neo1/client/lib/rpc/batch';
import { normalizeCompany, RpcMethodName } from '@neo1/client';
import {
  Company,
  normalizeCompanyGroupUser,
} from '@neo1/client/lib/entities/index';
import { getClient } from '../../utils/client';
import { normalizeFundingApproversData } from '../virtualAccounts/utils';

export async function loadAuthCompany(companyId: Company['id']) {
  const commands = [
    {
      method: RpcMethodName.GetCompany,
      params: { company_id: companyId },
      id: 1,
    },
    {
      method: RpcMethodName.GetCompanyGroupUsers,
      params: { company_id: companyId },
      id: 2,
    },
    {
      method: RpcMethodName.GetCompanyFundingApprovers,
      params: { company_id: companyId },
      id: 3,
    },
  ];

  const results = await getClient().batchCommands(commands, true);
  const batchResults = batchHelpers.wrapResults(results);

  const company = normalizeCompany(batchResults.get(1));
  const companyGroupUsers = batchResults.get(2).map(normalizeCompanyGroupUser);
  const fundingApproversData = normalizeFundingApproversData(
    batchResults.get(3),
  );

  return {
    company,
    companyGroupUsers,
    fundingApproversData,
  };
}

/**
 * Finds out which user is logged in to API
 * and fetches all his information
 */
export function whoAmI() {
  return getClient().sendCommand({ method: 'whoami', params: {} });
}

/**
 * Authenticates user to the API with given credentials
 * @param credentials
 */

export function login(params) {
  return getClient().sendCommand({ method: 'login', params }, false);
}

/**
 * Logout a user from the backend
 */
export function logout() {
  return getClient().sendCommand({ method: 'logout', params: {} }, false);
}

/**
 * Refresh user auth token
 */
export function refreshToken() {
  return getClient().sendCommand({ method: 'refresh_token', params: {} });
}
