import { ReactNode, useEffect } from 'react';
import PublicPage from 'components/layout/PublicPage';
import { useHistory } from 'react-router';
import { isTravelSSOActive } from '@neo1/core/utils';

type Props = {
  children: ReactNode;
};

const Login = ({ children }: Props) => {
  const history = useHistory();
  const isSso = isTravelSSOActive();

  useEffect(() => {
    if (!isSso) {
      history.replace('/');
    }
  }, []);

  return (
    <PublicPage
      title="Log In"
      showTitle={false}
      description="Already a Neo1 user? Log in now."
      showFlourish
    >
      {children}
    </PublicPage>
  );
};

export default Login;
