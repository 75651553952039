export const FileType = {
  JPG: 'jpg',
  GIF: 'gif',
  PNG: 'png',
  PDF: 'pdf',
  UNKNOWN: 'n/a',
};

export const getFileType = (file: File) => fileTypeFromMime(file.type);

export const fileTypeFromMime = (fileMimeType: string) => {
  const fileTypeParts = /\/([^/]+)$/.exec(fileMimeType) || [];
  return fileType(fileTypeParts[1]);
};

export const getFileTypeFromFileName = (filename: string) =>
  fileType(filename.split('.').pop().toLowerCase());

const fileType = (type: string) => {
  switch (type) {
    case 'jpg':
    case 'jpeg':
      return FileType.JPG;
    case 'png':
      return FileType.PNG;
    case 'pdf':
      return FileType.PDF;
    case 'gif':
      return FileType.GIF;
    default:
      return FileType.UNKNOWN;
  }
};
