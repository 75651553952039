import sortBy from 'lodash/sortBy';
import { denormalizeById, getClient } from '../../utils';
import { AppConfigReferentialData } from './types';
import { normalizeCountry, normalizeCurrency } from './entities';
import { wrapResults } from '@neo1/client/lib/rpc/batch';

/**
 * Fetches app config referential
 */
export const fetchAppReferentialData =
  async (): Promise<AppConfigReferentialData> => {
    const results = await getClient().batchCommands(
      [
        { method: 'get_countries', params: {}, id: 0 },
        { method: 'get_currencies', params: {}, id: 1 },
      ],
      false,
    );

    const data = wrapResults(results);

    const countries = data.get(0).map(normalizeCountry);
    const currencies = data.get(1).map(normalizeCurrency);

    return {
      countries: denormalizeById(countries, 'code'),
      currencies: denormalizeById(currencies, 'code'),
    };
  };

export function fetchCountries() {
  return getClient()
    .sendCommand({ method: 'get_countries', params: {} })
    .then((countries) => sortBy(countries.map(normalizeCountry), 'name'));
}

export function fetchCurrencies() {
  return getClient()
    .sendCommand({ method: 'get_currencies', params: {} })
    .then((currencies) => sortBy(currencies.map(normalizeCurrency), 'name'));
}
