import { isBlankString } from './strings';

/**
 * Checks if given value is valid payload value
 * @param value
 * @param optional
 */
const isValidPayloadValue = (value: any, optional: boolean = true) =>
  !isBlankString(value) &&
  value !== undefined &&
  typeof value !== 'function' &&
  (optional || value !== null);

const nullifyEmptyValues = (payload: any, defaults: object = {}) =>
  Object.keys(payload).reduce((resultPayload, k) => {
    let payloadValue = payload[k];

    if (!isValidPayloadValue(payloadValue)) {
      payloadValue = (<any>resultPayload)[k] || null;
    }

    return {
      ...resultPayload,
      [k]: payloadValue,
    };
  }, defaults);

/**
 * Constructs a JSON-RPC paylod
 * @param payload
 * @param defaults
 */
export const makePayload = (payload: any = {}, defaults: object = {}) =>
  nullifyEmptyValues(payload, defaults);

export const nullIfMissing = (payload: any) =>
  Object.entries(payload).reduce((result, [key, value]) => {
    return {
      ...result,
      [key]: value || null,
    };
  }, {});
