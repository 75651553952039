import isEmpty from 'lodash/isEmpty';
import { hasSupervisor } from '@neo1/core/modules/users/utils';
import { isCompanyRestricted } from '@neo1/core/modules/companies/utils';
import {
  isInProgressPurchase,
  isReadOnlyPurchase,
  isWaitingApprovalPurchase,
  normalizePurchaseStatus,
  PurchaseStatus,
  PurchaseStatusCode,
} from './status';
import {
  AccountingSegmentData,
  AcountingCodingData,
  normalizeAcountingCodingData,
  normalizeSegmentData,
} from './purchase';
import { Entity } from '../types';
import { UUID } from '../common';
import { Company, CompanyUser, User } from '../';
import { normalizeCompanyUser } from '../user/user';
import { reify } from '../normalize';

/**
 * TODO: stop using this, only left here to avoid regressions, eventually we should stop using this function.
 * (archived budgets should be normalized as normal budgets but for that segments data should be present in the backend payload
 * so we can stablish a relation between codings and segments)
 *
 * Transforms a list of segment codings to a segment coding object
 */
export function mapSegmentCoding(codings: any) {
  return codings.reduce(
    (segmentCoding: any, { code }: any, segmentId: number) => ({
      ...segmentCoding,
      [segmentId]: code,
    }),
    {},
  );
}

export const BudgetEntityName = 'Budget';

enum RequestType {
  Virtual = 'virtual_budget',
  Standard = 'standard_budget',
}

export type RequestedChangesSupervision = {
  supervisorId: CompanyUser['id'];
  hasApproved: boolean;
};

export type RequestedChanges = {
  additionalAmount: number;
  endDate: string;
  justification?: string;
  supervision: RequestedChangesSupervision[];
  createdAt?: string;
  lastUpdate?: string;
};

export interface Budget extends Entity {
  completenessErrors?: string[];
  merchant: string;
  companyId: string;
  startDate: string;
  lastStatusUpdate?: string;
  spentAmount: number;
  version: number;
  currency: string;
  ownerId: User['id'];
  owner: CompanyUser;
  maxAmount: number;
  orderNumber?: string;
  name: string;
  lastUpdate?: string;
  endDate: string;
  status?: PurchaseStatus;
  supervisorsUsers: CompanyUser[];
  codings: AcountingCodingData[];
  members: UUID[];
  membersUsers: CompanyUser[];
  createdAt: string;
  segments: AccountingSegmentData[];
  virtualCardId?: UUID;
  requestType: RequestType;
  requestedChanges?: RequestedChanges;
}

const normalizeRequestedChanges = (data: any): RequestedChanges => ({
  additionalAmount: data.additional_amount,
  endDate: data.new_end_date,
  justification: data.justification,
  supervision: (data.supervision || []).map((s: any) => ({
    supervisorId: s.supervisor_id,
    hasApproved: s.has_approved,
  })),
  createdAt: data.created_at,
  lastUpdate: data.last_update,
});

export function normalizeBudget(result: any): Budget {
  const data = reify(result);
  const status = data.get('status', undefined);
  const requestedChanges = data.get('modification_request', undefined);
  return {
    entityType: BudgetEntityName,
    version: data.getNumber('version'),
    maxAmount: data.getNumber('max_amount'),
    codings: data.getArray('codings', (d) =>
      normalizeAcountingCodingData(reify(d)),
    ),
    companyId: data.getUuid('company_id'),
    completenessErrors: data.getArray<string>('completeness_errors'),
    createdAt: data.getString('created_at'),
    currency: data.getString('currency'),
    endDate: data.getString('end_date'),
    id: data.getUuid('budget_id'),
    lastStatusUpdate: data.getString('last_status_update'),
    lastUpdate: data.getString('last_update'),
    members: data.getArray('members'),
    membersUsers: data.getArray('members_user', (d) =>
      normalizeCompanyUser(reify(d)),
    ),
    merchant: data.getStringOrNull('merchant_name'),
    orderNumber: data.getStringOrNull('order_number'),
    ownerId: data.getUuid('owner_id'),
    owner: normalizeCompanyUser(reify(data.get('owner'))),
    name: data.getString('name'),
    requestedChanges:
      requestedChanges && normalizeRequestedChanges(requestedChanges),
    spentAmount: data.getNumber('spent_amount'),
    startDate: data.getString('start_date'),
    status: status && normalizePurchaseStatus(status),
    supervisorsUsers: data.getArray('supervisors_user', (d) =>
      normalizeCompanyUser(reify(d)),
    ),
    segments: data.getArray('segments', (d) => normalizeSegmentData(reify(d))),
    virtualCardId: data.getStringOrNull('virtual_card_id'),
    requestType:
      data.getString('request_type') === RequestType.Virtual
        ? RequestType.Virtual
        : RequestType.Standard,
  };
}

export const isBudget = (obj: any): obj is Budget =>
  obj?.entityType === BudgetEntityName;

export const isOverspentBudget = (budget: Budget) =>
  budget.spentAmount > budget.maxAmount;

export const isApprovedBudget = (budget: Budget) =>
  budget.status.code === PurchaseStatusCode.Approved;

export const hasBudgetCompletenessErrors = (budget: Budget) =>
  !isEmpty(budget.completenessErrors);

export const hasBudgetInvalidCodings = (budget: Budget) =>
  budget?.codings.some(({ status }) => status === 'invalid');

export const isBudgetOwnedBy = (user: User) => (budget: Budget) =>
  user.id === budget.ownerId;

export const isBudgetUsableBy = (user: User) => (budget: Budget) =>
  budget.members.includes(user.id);

export const isSupervisedBy = (user: User) => (budget: Budget) => {
  return budget.supervisorsUsers.some(
    (supervisor) => supervisor.id === user.id,
  );
};

export const isUserBudget = (user: User) => (budget: Budget) => {
  const isUserMember = isBudgetUsableBy(user);
  const isUserOwner = isBudgetOwnedBy(user);
  return (
    isUserOwner(budget) || (isUserMember(budget) && isApprovedBudget(budget))
  );
};

export const isVirtualBudget = (budget: Budget): boolean =>
  Boolean(budget && budget.virtualCardId);

export const canEditBudget = (user: User) => (budget: Budget) =>
  !isReadOnlyPurchase(budget) && isBudgetOwnedBy(user)(budget);

/**
 * Asserts if budget is closable
 * @param budget
 */
export const canCloseBudget = (budget: Budget) =>
  !isWaitingApprovalPurchase(budget) && !isInProgressPurchase(budget);
