import { isCompanyUser } from './user';
import { User, CompanyUserRole } from '@neo1/client/lib/entities/user';

export function isPrivileged(user: User) {
  return (
    isCompanyUser(user) &&
    (user.roles.includes(CompanyUserRole.Admin) ||
      user.roles.includes(CompanyUserRole.Finance))
  );
}
