import { defineMessage } from 'react-intl';

const messages = {
  title: defineMessage({
    id: 'ActivateCompany__Owners__title',
    defaultMessage:
      'Do you have any individuals who own a share of 25% or greater in your business ?',
  }),
  btnAddOwner: defineMessage({
    id: 'ActivateCompany__Owners__btnAddOwner',
    defaultMessage: 'Add beneficial owner',
  }),
  editingTitle: defineMessage({
    id: 'ActivateCompany__Owners__editingTitle',
    defaultMessage: 'Editing owner {no}',
  }),
  infoTitle: defineMessage({
    id: 'ActivateCompany__Owners__infoTitle',
    defaultMessage:
      'Anti-money laundering and know your customer initiatives require us to verify the identity of the businesses and individuals we are doing business with.',
  }),
  infoP1: defineMessage({
    id: 'ActivateCompany__Owners__infoP1',
    defaultMessage:
      'Our customer due diligence process screens the requested information against several governmental watchlists and PEP (politically exposed persons) databases.',
  }),
  infoP2: defineMessage({
    id: 'ActivateCompany__Owners__infoP3',
    defaultMessage:
      'Note only individuals owning 25% stake or more are concerned by these checks.',
  }),
  infoP3: defineMessage({
    id: 'ActivateCompany__Owners__infoP4',
    defaultMessage:
      "If you are one of the business's owners, and own at least 25% of the business, please include yourself in the list.",
  }),
};

export default messages;
