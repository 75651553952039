import { Country } from '@neo1/core/modules/app/entities';
import { useAppReferentialSingleton } from 'modules/App/useAppReferential';
import SelectBox from 'components/elements/form/fields/SelectBox';
import { SelectBoxProps } from 'components/elements/form/fields/SelectBox/utils';
import CountryOption from './Option';

type CountryProp = keyof Country;

type CountryRestrictProp = 'active' | 'referenced';

interface SelectCountryProps extends SelectBoxProps {
  isInvalid?: boolean;
  propName?: CountryProp;
  restrict?: CountryRestrictProp;
  onValueChange?: (value: string) => void;
}

const mapCountryToOption = (propName?: CountryProp) => (country: Country) => ({
  label: country.name,
  value: propName ? country[propName] : country,
});

const isCountryUsable = (prop: CountryRestrictProp) => (country: Country) =>
  country[prop];

/**
 * Select component for app countries
 * @param props
 * @returns
 */
const SelectCountry = ({
  id,
  value,
  onChange,
  className,
  onFocus,
  onBlur,
  propName,
  restrict,
  disabled,
  isInvalid,
  placeholder,
  onValueChange,
  title,
  errorText,
  isRequired,
  name,
}: SelectCountryProps) => {
  const referential = useAppReferentialSingleton();

  const countries = referential.getCountries();

  const displayedCountries = restrict
    ? countries.filter(isCountryUsable(restrict))
    : countries;

  const options = displayedCountries.map(mapCountryToOption(propName));

  const handleOnChange = (newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <div className={className}>
      <SelectBox
        name={name}
        title={title}
        errorText={errorText}
        options={options}
        isInvalid={isInvalid}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        isLoading={!countries}
        value={value}
        components={{
          Option: CountryOption,
        }}
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{ autoComplete: 'new-password' }}
        isRequired={isRequired}
      />
    </div>
  );
};

SelectCountry.defaultProps = {
  isInvalid: false,
  propName: 'code',
  restrict: undefined,
  onValueChange: undefined,
};

export default SelectCountry;
