import isEmpty from 'lodash/isEmpty';
import { hasSupervisor } from '@neo1/core/modules/users/utils';
import { isCompanyRestricted } from '@neo1/core/modules/companies/utils';
import { Address, AddressEntityName } from '../address';
import { CompanyUser, User, normalizeCompanyUser } from '../user';
import { Company } from '../company';
import { Amount, UUID } from '../common';
import { reify } from '../normalize';
import { Entity } from '../types';
import {
  AccountingSegmentData,
  AcountingCodingData,
  CodingDataPayload,
  normalizeAcountingCodingData,
  normalizeSegmentData,
} from './purchase';
import {
  normalizePurchaseStatus,
  PurchaseStatus,
  PurchaseRejectionReason,
} from './status';

export type BillingAddressParams = {
  zip_code: Address['zipCode'];
  city: Address['city'];
  state: Address['state'];
  country_code: Address['countryCode'];
  street_line_1: Address['streetLine1'];
  street_line_2: Address['streetLine2'];
};

export type SupplyCartItem = {
  quantity: number;
  price: Amount;
  description: string;
  shippingAddress: Address;
  supplierId?: string;
  supplierAuxId?: string;
  condition?: string;
  soldBy?: string;
};

export type SupplyCart = {
  tax?: Amount;
  shipping?: Amount;
  items: SupplyCartItem[];
  total: Amount;
  creation: string;
};

export type SupplyCompletenessError = string;

export const SupplyEntityName = 'Supply';

export interface Supply extends Entity {
  startDate: string;
  lastStatusUpdate?: string;
  version: number;
  currency: string;
  companyId: string;
  id: string;
  name: string;
  cart: SupplyCart;
  maxAmount: number;
  members: UUID[];
  membersUsers: CompanyUser[];
  billingAddress?: Address;
  orderNumber: string;
  ownerId: UUID;
  owner: CompanyUser;
  purpose: string;
  lastUpdate?: string;
  endDate: string;
  status?: PurchaseStatus;
  supervisorsUsers: CompanyUser[];
  codings: AcountingCodingData[];
  createdAt?: string;
  completenessErrors: SupplyCompletenessError[];
  isPurchased: boolean;
  segments: AccountingSegmentData[];
}

export function normalizeBillingAddress(rawData: any): Address {
  if (typeof rawData !== 'object') {
    return;
  }

  const data = reify(rawData);

  return {
    entityType: AddressEntityName,
    id: data.getUuid('address_id'),
    zipCode: data.getString('zip_code'),
    city: data.getString('city'),
    name: data.getString('name'),
    state: data.getString('state'),
    countryCode: data.getString('country_code'),
    streetLine1: data.getString('street_line_1'),
    streetLine2: data.getString('street_line_2'),
  };
}

export const normalizeCartItem = ({
  quantity,
  price,
  description,
  shipping_address,
  supplier_id = '',
  supplier_aux_id = '',
  condition = '',
  sold_by = '',
}: any) => ({
  quantity,
  price,
  description,
  shippingAddress: normalizeBillingAddress(shipping_address),
  supplierId: supplier_id,
  suppplierAuxId: supplier_aux_id,
  condition,
  soldBy: sold_by,
});

export function normalizeSupply(result: any): Supply {
  const data = reify(result);
  const status = data.get('status', undefined);
  return {
    entityType: SupplyEntityName,
    version: data.getNumber('version'),
    maxAmount: data.getNumber('max_amount'),
    codings: data.getArray('codings', (d) =>
      normalizeAcountingCodingData(reify(d)),
    ),
    companyId: data.getUuid('company_id'),
    completenessErrors: data.getArray('completeness_errors'),
    createdAt: data.getString('created_at'),
    currency: data.getString('currency'),
    endDate: data.getString('end_date'),
    id: data.getUuid('purchase_id'),
    lastStatusUpdate: data.getString('last_status_update'),
    lastUpdate: data.getString('last_update'),
    name: data.getString('name'),
    members: data.getArray('members'),
    membersUsers: data.getArray('members_user', (d) =>
      normalizeCompanyUser(reify(d)),
    ),
    orderNumber: data.getStringOrNull('order_number'),
    ownerId: data.getUuid('owner.user_id'),
    owner: normalizeCompanyUser(reify(data.get('owner'))),
    purpose: data.getString('purpose'),
    startDate: data.getString('start_date'),
    status: status && normalizePurchaseStatus(status),
    supervisorsUsers: data.getArray('supervisors_user', (d) =>
      normalizeCompanyUser(reify(d)),
    ),
    billingAddress: data.map('billing_address', normalizeBillingAddress),
    cart: {
      tax: data.getAmount('cart.tax.fee'),
      total: data.getAmount('cart.total'),
      items: data.getArray('cart.items', normalizeCartItem),
      shipping: data.getAmount('cart.shipping.fee'),
      creation: data.getString('cart.creation'),
    },
    isPurchased: data.getBoolean('is_purchased'),
    segments: data.getArray('segments', (d) => normalizeSegmentData(reify(d))),
  };
}

export const isSupply = (obj: any): obj is Supply =>
  obj?.entityType === SupplyEntityName;

export const hasSupplyCompletenessErrors = (supply: Supply) =>
  !isEmpty(supply.completenessErrors);

export const hasSupplyInvalidCodings = (supply: Supply) =>
  supply.codings.some(({ status }) => status === 'invalid');

export const isSupplyOwnedBy = (user: User) => (supply: Supply) =>
  user.id === supply.ownerId;

export const canSubmitSupply =
  (company: Company, user: User) => (supply: Supply) =>
    !isCompanyRestricted(company) &&
    !hasSupplyCompletenessErrors(supply) &&
    !hasSupplyInvalidCodings(supply) &&
    hasSupervisor(user);

export type SupplyApproveParams = {
  amazon_purchase_id: Supply['id'];
  supervisor_id: string;
};

export type SupplyIdParams = {
  amazon_purchase_id: Supply['id'];
};

export type GetSuppliesForApprovalParams = {
  supervisor_id: User['id'];
};

export type GetSuppliesParams = {
  user_id: User['id'];
};

export type RejectSupplyParams = {
  amazon_purchase_id: Supply['id'];
  supervisor_id: User['id'];
  reject_reason: PurchaseRejectionReason;
  comment?: string;
};

export type SupplyUpdateParams = {
  amazon_purchase_id: Supply['id'];
  name: Supply['name'];
  purpose: Supply['purpose'];
  codings: CodingDataPayload;
  billing_address: BillingAddressParams | null;
};
