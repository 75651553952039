import { RpcClientError } from '../../rpc';
import { PhoneNumber, UUID } from '../common';
import { Entity } from '../types';

export enum CompanyUserRole {
  Finance = 'finance',
  Admin = 'admin',
  Arranger = 'arranger',
  TravelHost = 'travel_host',
}

export enum UserLevel {
  SYSTEM = 'system',
  DOMAIN = 'domain',
  COMPANY = 'company',
}

export const USER_LEVELS = [
  UserLevel.SYSTEM,
  UserLevel.DOMAIN,
  UserLevel.COMPANY,
];

export enum UserStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  LOCKED = 'locked',
  PENDING_ACTIVATION = 'pending_activation',
}

export const COMPANY_USER_STATUSES = [
  UserStatus.ACTIVE,
  UserStatus.DEACTIVATED,
  UserStatus.LOCKED,
  UserStatus.PENDING_ACTIVATION,
];

enum UserJob {
  OWNER = 'owner',
  FINANCE_TEAM = 'finance_team',
  OFFICE_MANAGER = 'office_manager',
  VALUABLE_EMPLOYEE = 'valuable_employee',
}

export const UserJobs = [
  UserJob.OWNER,
  UserJob.FINANCE_TEAM,
  UserJob.OFFICE_MANAGER,
  UserJob.VALUABLE_EMPLOYEE,
];

export enum UserTitle {
  MR = 'mr',
  MRS = 'mrs',
  MS = 'ms',
  MISS = 'miss',
  DR = 'dr',
  MX = 'mx',
}

export const COMPANY_USER_TITLES = [
  UserTitle.MR,
  UserTitle.MRS,
  UserTitle.MS,
  UserTitle.MISS,
  UserTitle.DR,
  UserTitle.MX,
];

export interface SystemUser extends Entity {
  id: UUID;
  creation: Date;
  displayName: string;
  email: string;
  status: string;
  lastLogin?: Date;
  lastUpdate: Date;
  login: string;
  user_id?: string;
  level: UserLevel.SYSTEM;
  password?: string;
  freezed?: boolean;
  isFetching: boolean;
  fetchError: RpcClientError | null;
}

export interface DomainUser extends Omit<SystemUser, 'level'> {
  id: string;
  domainId: string;
  level: UserLevel.DOMAIN;
  additionalDomains: string[];
}

type LatestAcceptedEula = {
  url: string;
  version: string;
  acceptedAt: string;
  isLatest: boolean;
};

export enum TravelerGroup {
  Employees = 'employees',
  Executives = 'executives',
}

export interface CompanyUser extends Omit<SystemUser, 'level'> {
  id: UUID;
  rolesList: string;
  companyId: string;
  creation: Date;
  preferredCurrency: string;
  domainId?: string;
  externalId?: string;
  firstName: string;
  lastName: string;
  latestAcceptedEula: LatestAcceptedEula | null;
  locale: string;
  roles: Array<CompanyUserRole>;
  supervisorId: string | null;
  title: string;
  phone?: PhoneNumber;
  birthDate?: string;
  level: UserLevel.COMPANY;
  travelerGroup: TravelerGroup;
}

/*
 This corresponds to the payload from the method get_company_group_users AKA GetCompanyGroupUsers
*/
export interface CompanyGroupUser
  extends Pick<
      CompanyUser,
      | 'id'
      | 'firstName'
      | 'lastName'
      | 'displayName'
      | 'lastName'
      | 'title'
      | 'email'
      | 'roles'
      | 'supervisorId'
      | 'companyId'
      | 'status'
      | 'travelerGroup'
    >,
    Entity {
  level: UserLevel.COMPANY;
  companyName: string;
}

export type User = SystemUser | DomainUser | CompanyUser;
