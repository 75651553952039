import {
  ReactNode,
  Children,
  cloneElement,
  FocusEvent,
  ReactElement,
} from 'react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import isFunction from 'lodash/isFunction';
import snakeCase from 'lodash/snakeCase';
import { useField, useFormikContext, FormikProps } from 'formik';
import { replaceZeroWidthChars } from '@neo1/core/utils';
import { useAmplitude } from 'contexts/instrumentation';
import styles from './Field.module.css';

type FieldTrackingProps = {
  canLogInstrumentation?: boolean;
  instrumentationEventNameOnBlur?: string;
  canInstrumentationEventLogValue?: boolean;
  logInstrumentationAnonymously?: boolean;
};

interface Props<T> extends FieldTrackingProps {
  name: string;
  id?: string;
  title?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isSuccess?: boolean;
  successText?: string;
  helperText?: string;
  errorText?: string;
  keepBottomSpace?: boolean;
  className?: string;
  info?: ReactNode;
  children: ReactElement;
  onChange?: (value: T) => void;
  onFocus?: React.FocusEventHandler<Element>;
  onBlur?: (value: T) => void;
  constraintValue?: (value: T) => T;
}

function isBlurEvent(obj: any): obj is FocusEvent<HTMLInputElement> {
  return obj?.type === 'blur';
}

/**
 * Connects an input to its formik field
 * Displays errors
 * @param param0
 */
export default function Field<T>({
  children,
  className,
  id,
  info,
  name,
  onChange: onChangeProp,
  constraintValue,
  required,
  canLogInstrumentation,
  instrumentationEventNameOnBlur,
  canInstrumentationEventLogValue,
  logInstrumentationAnonymously,
  title,
  disabled,
  readOnly,
  isSuccess,
  successText,
  helperText,
  errorText,
  keepBottomSpace,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
}: Props<T>) {
  const form: FormikProps<unknown> = useFormikContext();
  const { isSubmitting } = form;
  const { logEvent, logEventAnonymously } = useAmplitude();

  const [field, meta, helpers] = useField<T>(replaceZeroWidthChars(name));

  function getInputId() {
    return id || (field && camelCase(field.name || ''));
  }

  /**
   * Compute event name for given field
   */
  function getBlurEventName(): string {
    if (instrumentationEventNameOnBlur) {
      return instrumentationEventNameOnBlur;
    }
    return `has_filled_${snakeCase(field.name)}`;
  }

  function onChange(changedValue: T) {
    let value = changedValue;

    if (isFunction(constraintValue)) {
      value = constraintValue(value);
    }

    if (isFunction(onChangeProp)) {
      onChangeProp(value);
    }

    helpers.setValue(value);
    // This forces the validation of the form to run at a later time, so it uses the new value for the field.
    // This is because formik does not garantee that the validation will run using the last values when using `setValue`,
    // see the coment on the behavior from the creator of formik: https://github.com/formium/formik/issues/2083#issuecomment-571259235
    setTimeout(() => {
      form.validateForm();
    });
  }

  function onFocus(event) {
    if (isFunction(onFocusProp)) {
      onFocusProp(event);
    }
  }

  function onBlur(event: any) {
    const isEvent = isBlurEvent(event);
    const currentValue = field.value;

    if (!meta.touched) {
      // set field touched when field onBlur
      helpers.setTouched(true);
    }

    // Only in the case where we have no errors, we send amplitude event
    if (canLogInstrumentation && !meta.error) {
      const log = logInstrumentationAnonymously
        ? logEventAnonymously
        : logEvent;
      log({
        event: getBlurEventName(),
        data: {
          value: canInstrumentationEventLogValue ? currentValue : undefined,
        },
      });
    }

    if (isFunction(onBlurProp)) {
      onBlurProp(event.target.value);
    }

    if (isEvent) {
      field.onBlur(event);
    } else {
      field.onBlur(field.name);
    }
  }

  const inputChild = Children.only(children);

  const isDisabled = isSubmitting || disabled || inputChild.props.disabled;

  const isInvalid = Boolean(errorText) || (meta.error && meta.touched);

  const input = cloneElement(inputChild, {
    title,
    ...field,
    value: field.value ?? '',
    disabled: isDisabled,
    readOnly,
    onChange,
    onBlur,
    onFocus,
    className: inputChild.props.className,
    id: getInputId(),
    info,
    isInvalid,
    errorText: !readOnly ? errorText || meta.error : undefined,
    successText: !readOnly ? successText : undefined,
    helperText: !readOnly ? helperText : undefined,
    keepBottomSpace,
    isRequired: required,
    isSuccess,
  });

  return <div className={classNames(styles.container, className)}>{input}</div>;
}

Field.defaultProps = {
  id: undefined,
  title: undefined,
  required: false,
  disabled: false,
  readOnly: false,
  isSuccess: false,
  successText: undefined,
  helperText: undefined,
  errorText: undefined,
  keepBottomSpace: false,
  className: undefined,
  info: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onChange: undefined,
  constraintValue: undefined,
  canLogInstrumentation: false,
  instrumentationEventNameOnBlur: undefined,
  canInstrumentationEventLogValue: false,
  logInstrumentationAnonymously: false,
};
