import { AccountingSegment } from '@neo1/client/lib/entities/segments/types';

export enum Actions {
  SET_SEGMENTS = 'one/settings/segments/SET_SEGMENTS',
  SET_SEGMENT = 'one/settings/segments/SET_SEGMENT',
  DELETE_SEGMENT = 'one/settings/segments/DELETE_SEGMENT',
}

export type SetAction = {
  type: Actions.SET_SEGMENT;
  segment: AccountingSegment;
};
export type SetMultiAction = {
  type: Actions.SET_SEGMENTS;
  segments: AccountingSegment[];
};
export type DeleteAction = {
  type: Actions.DELETE_SEGMENT;
  id: AccountingSegment['id'];
};

export type SegmentsState = AccountingSegment[];

export type SegmentActions = SetAction | SetMultiAction | DeleteAction;
