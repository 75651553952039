import {
  OneRpcApiClient,
  OneRpcApiClientConfig,
} from '@neo1/client/lib/rpc/client';
/**
 * Singleton instance of Rpc Client
 * This client is used accross any application using core library as singleton instance
 * and is used for most of rpc calls to the backend
 */
let client: OneRpcApiClient;

export const cleanClient = () => (client = undefined);

/**
 * Singleton instance of Safe Rpc Client
 * This client is used accross any application using core library as singleton instance
 * and is used to handle rpc calls to safe api handling sensitive credit card information
 */
let safeClient: OneRpcApiClient;

export const cleanSafeClient = () => (safeClient = undefined);

let publicClient: OneRpcApiClient;

export const cleanPublicClient = () => (publicClient = undefined);

/**
 * Gets singleton instance of Rpc Client
 */
export const getClient = (config?: OneRpcApiClientConfig): OneRpcApiClient => {
  if (!client) {
    client = new OneRpcApiClient(config);
  }

  return client;
};

/**
 * Gets singleton instance of Safe Rpc Client (For Safe Api)
 */
export const getSafeClient = (): OneRpcApiClient => {
  if (!safeClient) {
    // We initiate safe client with main rpc client configuration
    safeClient = new OneRpcApiClient(getClient().config);
  }

  return safeClient;
};

/**
 * Gets singleton instance of Rpc Public Client
 */
export const getPublicClient = (): OneRpcApiClient => {
  if (!publicClient) {
    const config = { ...getClient().config };
    delete config.authToken;
    publicClient = new OneRpcApiClient(config);
  }

  return publicClient;
};

/**
 * Retrieves authorization headers mapping rpc client configuration
 */
const getAuthorizationHeaders = () => {
  const client = getClient();
  return {
    Authorization: `Bearer ${client.getConfigKey('authToken')}`,
  };
};

/**
 * Retrieves full list of headers
 */
export const getClientHeaders = () => {
  return {
    ...getAuthorizationHeaders(),
    ...getClientInfoHeaders(),
  };
};

/**
 * Retrieves headers for Neo1 version
 */
export const getClientInfoHeaders = () => getClient().getClientInfoHeaders();
