import { reify } from '../normalize';
import { Domain, DomainStats } from './types';

export const DomainEntityName = 'Domain';
export const DomainStatsEntityName = 'DomainStats';

/**
 * Constructs a domain object
 * @param {*} rawData
 */
export function normalizeDomain(rawData: any): Domain {
  const data = reify(rawData);
  return {
    id: data.getUuid('domain_id'),
    entityType: DomainEntityName,
    name: data.getString('name'),
    description: data.getString('description'),
    normalizedName: data.getString('normalized_name'),
    isProduction: data.getBoolean('is_production'),
    createdAt: data.getDate('created_at'),
    branding: {
      link1: data.getUrl('branding.link1', null),
      link2: data.getUrl('branding.link2', null),
      link3: data.getUrl('branding.link3', null),
      logoUrl: data.getStringOrNull('branding.logo_url'),
    },
  };
}

export function normalizeDomainStat(rawData: any): DomainStats {
  const data = reify(rawData);
  return {
    id: data.getUuid('domain_id'),
    entityType: DomainStatsEntityName,
    activeUserCount: data.getNumber('active_user_count'),
    activeCompanyCount: data.getNumber('active_company_count'),
    transactionCount: data.getNumber('transaction_count'),
    pendingCompanyCount: data.getNumber('pending_company_count'),
    failedCompanyCount: data.getNumber('failed_company_count'),
    inProgressCompanyCount: data.getNumber('in_progress_company_count'),
  };
}
