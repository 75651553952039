import { useState } from 'react';
import { User } from '@neo1/client';
import useLoggableAsUsers from 'modules/Authentification/useLoggableAsUsers';

import LoggingAsModal from '../LoggingAsModal';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import styles from './styles.module.css';

type Props = {
  user: User;
  onNavigation: Function;
};

const ProfileMenu = ({ user, onNavigation }: Props) => {
  const [isLoggingAsOpen, setLoggingAsOpen] = useState(false);
  const loggableUsers = useLoggableAsUsers();

  const startLoggingAs = () => {
    setLoggingAsOpen(true);
  };

  return (
    <div className={styles.container}>
      <Header
        user={user}
        startLoggingAs={loggableUsers.length > 0 && startLoggingAs}
      />
      <hr className={styles.separator} />
      <Body user={user} onNavigation={onNavigation} />
      <hr className={styles.separator} />
      <Footer />
      <LoggingAsModal
        users={loggableUsers}
        isOpen={isLoggingAsOpen}
        onClose={() => setLoggingAsOpen(false)}
      />
    </div>
  );
};

export default ProfileMenu;
