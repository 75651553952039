import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import set from 'lodash/set';
import { isValidString } from '@neo1/core/utils';
import FormGroup from 'components/elements/form/Group';
import TextInput from 'components/elements/form/fields/TextInput';
import DateInput from 'components/elements/form/fields/DateInput';
import Form from 'components/elements/form/Form';
import SelectCountry from 'components/composed/SelectCountry';
import SelectCountryRegion from 'components/composed/SelectCountryRegion';
import FormField from 'components/elements/form/Field';
import {
  CompanyActivationData,
  CompanyActivationOwner,
} from '@neo1/core/modules/persisted/types';
import { FormattedMessage, useIntl } from 'react-intl';
import AutocompleteOptions from 'components/elements/Google/AutocompleteOptions';
import { SelectedAddress } from 'components/elements/Google/utils';
import Button, { ButtonTheme } from 'components/elements/Button';
import { useAmplitude } from 'contexts/instrumentation';
import fieldMessages from 'i18n/fields';
import { useDateUtils } from 'utils/i18n/date';
import { useAddressUtils } from 'hooks/address';
import ownerMessages from './messages';
import messages from '../../messages';
import styles from '../../ActivateCompany.module.css';
import selfStyles from './EditOwner.module.css';

type Values = CompanyActivationOwner;

type Props = {
  owner: Values;
  index: number;
  onClickBack(): void;
};

const OWNER_STREET_INPUT_ID = 'editedOwnerStreet';
const MAP_ADDRESS_KEYS_TO_ACTIVATION_OWNER_KEYS = {
  countryCode: 'country',
  streetLine1: 'street',
};

export default function OwnerDetails({
  owner: {
    firstName,
    lastName,
    birthDate,
    countryOfBirth,
    country,
    street,
    zipCode,
    city,
    state,
  },
  index,
  onClickBack,
}: Props) {
  const { logEvent } = useAmplitude();
  const intl = useIntl();
  const addressInputRef = useRef();
  const { setFieldValue, values, setValues, isSubmitting } =
    useFormikContext<CompanyActivationData>();
  const { getStateFieldLabel, getZipCodeFieldLabel } = useAddressUtils();
  const { inputDateFormatLabel } = useDateUtils();

  useEffect(() => {
    logEvent({ event: `edit_owner${index + 1}_viewed` });
  }, []);

  const [initialValues] = useState<Values>({
    firstName,
    lastName,
    birthDate,
    countryOfBirth,
    country,
    street,
    zipCode,
    city,
    state,
  });

  /**
   * Callback when user cancels edition of owner
   */
  const onCancel = () => {
    const revertedOwnersValue = values.owners.map(
      (owner: Values, ownerIndex: number) => {
        if (ownerIndex === index) {
          return initialValues;
        }
        return owner;
      },
    );

    setFieldValue('owners', revertedOwnersValue);

    onClickBack();

    logEvent({ event: `cancel_owner${index + 1}` });
  };

  /**
   * Get edited owner field name
   */
  const getOwnerFieldName = useCallback(
    (name: string) => `owners[${index}]${name}`,
    [index],
  );

  const isUSOwner = country === 'US';

  const onPlaceSelected = useCallback(
    (selectedAddress: SelectedAddress) => {
      const valuesWithActivationDataKeys = Object.keys(selectedAddress).reduce(
        (presentValues, key) => {
          const value = selectedAddress[key];
          const keyNameOnActivationData = getOwnerFieldName(
            Object.keys(MAP_ADDRESS_KEYS_TO_ACTIVATION_OWNER_KEYS).includes(key)
              ? MAP_ADDRESS_KEYS_TO_ACTIVATION_OWNER_KEYS[key]
              : key,
          );
          return isValidString(value)
            ? {
                ...presentValues,
                [keyNameOnActivationData]: value,
              }
            : presentValues;
        },
        {},
      );

      const newValues = { ...values };
      Object.keys(valuesWithActivationDataKeys).forEach((key) => {
        set(newValues, key, valuesWithActivationDataKeys[key]);
      });
      setValues(newValues, true);
    },
    [values, setValues, getOwnerFieldName],
  );

  return (
    <div className={styles.subContainer}>
      <header className={styles.subHeader}>
        <FormattedMessage
          id={ownerMessages.editingTitle.id}
          defaultMessage={ownerMessages.editingTitle.defaultMessage}
          values={{ no: index + 1 }}
        >
          {(title) => <h3 className={styles.subHeaderTitle}>{title}</h3>}
        </FormattedMessage>
        <div className={styles.subHeaderActions} />
      </header>
      <main className={styles.formMain}>
        <FormGroup className={styles.compactFormGroup}>
          <FormField
            title={intl.formatMessage(fieldMessages.owner.firstName)}
            id="editedOwnerFirstName"
            name={getOwnerFieldName('firstName')}
            required
          >
            <TextInput />
          </FormField>
          <FormField
            title={intl.formatMessage(fieldMessages.owner.lastName)}
            name={getOwnerFieldName('lastName')}
            id="editedOwnerLastName"
            required
          >
            <TextInput />
          </FormField>
        </FormGroup>

        <FormGroup className={styles.compactFormGroup}>
          <FormField
            title={intl.formatMessage(fieldMessages.owner.birthDate)}
            helperText={intl.formatMessage(fieldMessages.date.formatHelper, {
              format: inputDateFormatLabel,
            })}
            name={getOwnerFieldName('birthDate')}
            id="editedOwnerBirthDate"
            required
          >
            <DateInput defaultView="decade" />
          </FormField>
          <FormField
            title={intl.formatMessage(fieldMessages.owner.nationality)}
            name={getOwnerFieldName('c\u200CountryOfBirth')}
            id="editedOwnerPolitical__Entity__Origin"
            required
          >
            <SelectCountry />
          </FormField>
        </FormGroup>

        <AutocompleteOptions
          query={get(values, getOwnerFieldName('street'))}
          inputRef={addressInputRef}
          key="AutocompleteOptions"
          onPlaceSelected={onPlaceSelected}
        >
          <FormGroup className={styles.compactFormGroup}>
            <FormField
              title={intl.formatMessage(fieldMessages.address.address)}
              name={getOwnerFieldName('street')}
              id={OWNER_STREET_INPUT_ID}
              required
            >
              <TextInput ref={addressInputRef} autoComplete="new-password" />
            </FormField>
          </FormGroup>
        </AutocompleteOptions>

        <FormGroup className={styles.compactFormGroup}>
          <FormField
            title={intl.formatMessage(fieldMessages.address.city)}
            name={getOwnerFieldName('city')}
            id="editedOwnerCity"
            required
          >
            <TextInput autoComplete="new-password" />
          </FormField>
          <FormField
            title={getStateFieldLabel(country)}
            name={getOwnerFieldName('state')}
            id="editedOwnerState"
            required={isUSOwner}
          >
            <SelectCountryRegion countryCode={country} />
          </FormField>
        </FormGroup>

        <FormGroup className={styles.compactFormGroup}>
          <FormField
            title={getZipCodeFieldLabel(country)}
            name={getOwnerFieldName('zipCode')}
            id="editedOwnerZipCode"
            required
          >
            <TextInput autoComplete="new-password" />
          </FormField>
          <FormField
            title={intl.formatMessage(fieldMessages.address.countryCode)}
            name={getOwnerFieldName('c\u200Country')}
            id="editedOwnerPolitical__Entity"
            onChange={() => setFieldValue(`owners[${index}].state`, null)}
            required
          >
            <SelectCountry menuPlacement="top" />
          </FormField>
        </FormGroup>

        <Form.Footer className={selfStyles.footer}>
          <Button
            label={intl.formatMessage(messages.footer.btnCancel)}
            theme={ButtonTheme.Ghost}
            onClick={onCancel}
            disabled={isSubmitting}
          />
          <Button
            label={intl.formatMessage(messages.footer.btnSaveOwner)}
            ariaLabel="Save the data for this owner"
            type="submit"
            theme={ButtonTheme.Outlined}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </Form.Footer>
      </main>
    </div>
  );
}
