import classNames from 'classnames';
import { Addr, AddrFields } from '@neo1/core/utils';
import { useAppReferentialSingleton } from 'modules/App/useAppReferential';
import Tag from 'components/elements/Tag';
import Icon from 'components/elements/Icon';
import styles from './AddressBlock.module.css';

type Props = {
  title?: string;
  address: Addr;
  unconfirmedFields?: string[];
  replacedFeilds?: string[];
  testId: string;
};

const ADDRESS_FIELDS_ORDER: AddrFields[] = [
  'streetLine1',
  'streetLine2',
  'city',
  'state',
  'countryCode',
  'zipCode',
];

const AddressBlock = ({
  title,
  address,
  unconfirmedFields,
  replacedFeilds,
  testId,
}: Props) => {
  const { isCountryWithStates, getStateName, getCountry } =
    useAppReferentialSingleton();

  const displayAddressElem = (
    k: string,
    value: string,
    countryCode?: string,
  ) => {
    if (k === 'countryCode') {
      return getCountry(countryCode).name;
    }
    if (k === 'state' && isCountryWithStates(countryCode)) {
      return getStateName(countryCode, value);
    }
    return value;
  };

  const isUS = address.countryCode === 'US';

  return (
    <div
      className={classNames(styles.addressContainer, 'textM')}
      data-testid={testId}
    >
      {title && (
        <div className={classNames(styles.title, 'textMSemibold')}>{title}</div>
      )}
      {ADDRESS_FIELDS_ORDER.map((k) => {
        const v = address[k];
        if (!v) return null;
        const displayValue = displayAddressElem(
          k,
          address[k],
          address.countryCode,
        );
        const isUnconfirmed = unconfirmedFields?.includes(k);
        const isReplaced = replacedFeilds?.includes(k);
        return (
          <div key={k} className={styles.addressLine}>
            <div
              className={classNames(
                styles.addressValue,
                isReplaced && 'textMBold',
              )}
            >
              {displayValue}
            </div>
            {isUnconfirmed && (
              <Tag
                type={isUS ? 'error' : 'warn'}
                revert
                testId={`unconfirmed-${k}`}
              >
                <Icon name="warning" /> Not found
              </Tag>
            )}
          </div>
        );
      })}
    </div>
  );
};

AddressBlock.defaultProps = {
  title: undefined,
  unconfirmedFields: undefined,
  replacedFeilds: undefined,
};

export default AddressBlock;
