import { generatePath, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getFullnameInitials } from '@neo1/core/utils';
import { isCompanyAdmin } from '@neo1/core/modules/users/utils';
import { useLayoutContext } from 'contexts/layout';
import { ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO } from 'modules/Settings/constants';
import { selectActingUser } from 'modules/Authentification/redux/selectors';
import Button, { ButtonKind, ButtonTheme } from 'components/elements/Button';
import Tooltip from 'components/elements/Tooltip';
import styles from './UserActions.module.css';

type Props = {
  isDark: boolean;
};

const UserActions = ({ isDark }: Props) => {
  const user = useSelector(selectActingUser);
  const { actions } = useLayoutContext();
  const history = useHistory();

  const handleClick = () => {
    actions.toggleProfileMenu();
  };

  return (
    <>
      <Button
        theme={ButtonTheme.Discrete}
        kind={ButtonKind.Neutral}
        iconOnly
        label="Help"
        iconName="questionCircle"
        onClick={() => window.open('https://knowledge.neo1.com', '_blank')}
        dark={isDark}
      />
      {user && isCompanyAdmin(user) && (
        <Button
          theme={ButtonTheme.Discrete}
          kind={ButtonKind.Neutral}
          iconOnly
          label="Settings"
          iconName="settings"
          onClick={() =>
            history.push(
              generatePath(ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO),
            )
          }
          dark={isDark}
        />
      )}
      {user && (
        <Tooltip content="Show profile">
          <button
            type="button"
            title="Show profile"
            aria-label="Show profile"
            onClick={handleClick}
            className={classNames(
              styles.avatarBtn,
              isDark ? styles.dark : null,
              'textMSemibold',
            )}
          >
            {getFullnameInitials(user.displayName)}
          </button>
        </Tooltip>
      )}
    </>
  );
};

export default UserActions;
