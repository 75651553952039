import {
  BillingAccountReportByIds,
  ExtractedFile,
  BillingPeriodReport,
} from '@neo1/core/modules/reconciliation/types';

export type GetAccountReportsAction = {
  type: Actions.GetAccountsReports;
};

export type GetAccountReportsSuccessAction = {
  type: Actions.GetAccountsReportsOk;
  reports: BillingAccountReportByIds;
};

export type GetAccountReportsErrorAction = {
  type: Actions.GetAccountsReportsErr;
  error: Error;
};

export type GetExtractedFilesAction = {
  type: Actions.GetExtractedFiles;
};

export type GetExtractedFilesSuccessAction = {
  type: Actions.GetExtractedFilesOk;
  extractedFiles: any;
};

export type GetExtractedFilesErrorAction = {
  type: Actions.GetExtractedFilesErr;
  error: Error;
};

export type ExtractTxAction = {
  type: Actions.BuildExtract;
  id: string;
};

export type ExtractTxSuccessAction = {
  type: Actions.BuildExtractOk;
  id: string;
};

export type ExtractTxErrorAction = {
  type: Actions.BuildExtractErr;
  id: string;
  error: Error;
};

export type GetTxsInErrorSuccessAction = {
  type: Actions.GetTxsInErrorSuccess;
  txsInError: any;
  extract: BillingPeriodReport;
};

export type ReconciliationActions =
  | GetAccountReportsAction
  | GetAccountReportsSuccessAction
  | GetAccountReportsErrorAction
  | GetExtractedFilesAction
  | GetExtractedFilesSuccessAction
  | GetExtractedFilesErrorAction
  | ExtractTxAction
  | ExtractTxSuccessAction
  | ExtractTxErrorAction
  | GetTxsInErrorSuccessAction;

export enum Actions {
  GetAccountsReports = 'one/reporting/reconciliation/GET_ACCOUNT_REPORT',
  GetAccountsReportsOk = 'one/reporting/reconciliation/GET_ACCOUNT_REPORT_SUCCESS',
  GetAccountsReportsErr = 'one/reporting/reconciliation/GET_ACCOUNT_REPORT_ERROR',
  GetExtractedFiles = 'one/reporting/reconciliation/GET_EXTRACTED_FILES',
  GetExtractedFilesOk = 'one/reporting/reconciliation/GET_EXTRACTED_FILES_SUCCESS',
  GetExtractedFilesErr = 'one/reporting/reconciliation/GET_EXTRACTED_FILES_ERROR',
  BuildExtract = 'one/reporting/reconciliation/EXTRACT_TX',
  BuildExtractOk = 'one/reporting/reconciliation/EXTRACT_TX_SUCCESS',
  BuildExtractErr = 'one/reporting/reconciliation/EXTRACT_TX_ERROR',
  GetTxsInErrorSuccess = 'one/reporting/reconciliation/GET_TXS_IN_ERROR_SUCCESS',
}

export function getAccountReports(): GetAccountReportsAction {
  return {
    type: Actions.GetAccountsReports,
  };
}

export function getAccountReportsSuccess(
  accountReports: BillingAccountReportByIds,
): GetAccountReportsSuccessAction {
  return {
    type: Actions.GetAccountsReportsOk,
    reports: accountReports,
  };
}

export function getAccountReportsError(
  error: Error,
): GetAccountReportsErrorAction {
  return {
    type: Actions.GetAccountsReportsErr,
    error,
  };
}

export function getExtractedFiles(): GetExtractedFilesAction {
  return {
    type: Actions.GetExtractedFiles,
  };
}

export function getExtractedFilesSuccess(
  extractedFiles: ExtractedFile[],
): GetExtractedFilesSuccessAction {
  return {
    type: Actions.GetExtractedFilesOk,
    extractedFiles,
  };
}

export function getExtractedFilesError(
  error: Error,
): GetExtractedFilesErrorAction {
  return {
    type: Actions.GetExtractedFilesErr,
    error,
  };
}

export function extractTx(id: BillingPeriodReport['id']): ExtractTxAction {
  return {
    type: Actions.BuildExtract,
    id,
  };
}

export function extractTxSuccess(
  id: BillingPeriodReport['id'],
): ExtractTxSuccessAction {
  return {
    type: Actions.BuildExtractOk,
    id,
  };
}

export function extractTxError(
  id: BillingPeriodReport['id'],
  error: Error,
): ExtractTxErrorAction {
  return {
    type: Actions.BuildExtractErr,
    id,
    error,
  };
}

export function getTxsInErrorSuccess(
  txsInError: any,
  extract: BillingPeriodReport,
): GetTxsInErrorSuccessAction {
  return {
    type: Actions.GetTxsInErrorSuccess,
    txsInError,
    extract,
  };
}
