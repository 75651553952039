import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import upperCase from 'lodash/upperCase';
import capitalize from 'lodash/capitalize';
import identity from 'lodash/identity';
import {
  formatAmountForPdf as formatAmount,
  sumAmounts,
} from '../../../utils/currencies';
import { prettyAndShortDateTime as formatDateTime } from '../../../utils/dates';

import { displayDate as formatDate } from '../../../i18n/utils';

import { enumToLabel } from '../../../utils/strings';
import { ReportFieldClass } from '../constants';
import { getNotNil } from '../../../utils';
import { ReportTable } from '../types';

interface ReportField {
  class: string;
  name: string;
  value_format: string;
  header_format: string;
}

enum ReportFormating {
  Uppercase = 'uppercase',
  Lowercase = 'lowercase',
  Capitalize = 'capitalize',
  Raw = 'raw',
}

const FORMATING_FUNCTIONS: Record<ReportFormating, (s?: string) => string> = {
  [ReportFormating.Uppercase]: upperCase,
  [ReportFormating.Lowercase]: lowerCase,
  [ReportFormating.Capitalize]: capitalize,
  [ReportFormating.Raw]: identity,
};

/**
 * Formats a given value according to field's `value_format` or  `header_format` props given as the `format` param
 */
export function formatByBackendIndicator(
  value: string,
  format: string,
): string {
  return FORMATING_FUNCTIONS[format](value);
}

export const getFormaterByBackendIndicator =
  (format: string): ((s: string) => string) =>
  (value: string) => {
    return FORMATING_FUNCTIONS[format](value);
  };

/**
 * Formats a given value according to field class
 */
const formatByFieldClass = (
  fieldClass: string,
  val: any,
  locale?: string,
): string => {
  switch (fieldClass) {
    case ReportFieldClass.ENUMERATION:
      return val.label || enumToLabel(val.value);
    case ReportFieldClass.DATE:
      return formatDate(val);
    case ReportFieldClass.DATE_TIME:
      return formatDateTime(val);
    case ReportFieldClass.AMOUNT:
      return formatAmount(val, { locale });
    case ReportFieldClass.BILLING_PERIOD:
      return `${formatDate(val.start_date)} ⇢ ${formatDate(val.end_date)}`;
    case ReportFieldClass.CREDIT_CARD_DIGITS:
      return `${val.slice(0, 4)} **** **** ${val.slice(-4)}`;
    case ReportFieldClass.DECIMAL:
      return Number(val).toFixed(2);
    case ReportFieldClass.INTEGER:
      return `${val}`;
    case ReportFieldClass.COUNTRY:
      return isString(val) ? val.toUpperCase() : null;
    case ReportFieldClass.PERCENTAGE:
      return `${val}%`;
    case ReportFieldClass.ACCOUNTING_SEGMENT:
      return val ? val.name : '';
    case ReportFieldClass.CODE_WITH_LABEL:
      return `${val.code} - ${val.label}`;
    case ReportFieldClass.UUID:
      return val ? val.label || val.uuid : null;
    default:
      if (typeof val === 'string') {
        return val;
      } else if (!isNil(val) && val.toString) {
        return val.toString();
      }
      return '';
  }
};

/**
 * Formats a given value using the field class and value_format.
 */
export const formatReportVal =
  (field: ReportField, options: any = {}) =>
  (val: any) => {
    if (isNil(val)) {
      return '';
    }

    return formatByBackendIndicator(
      formatByFieldClass(field.class, val, options?.locale),
      field.value_format,
    );
  };

/**
 * Checks field class to tell whether it is numeric or not
 * @param field
 * @returns {boolean}
 */
export function isNumericField(field: ReportField): boolean {
  return [
    ReportFieldClass.AMOUNT,
    ReportFieldClass.PERCENTAGE,
    ReportFieldClass.DECIMAL,
    ReportFieldClass.INTEGER,
  ].includes(field.class);
}

/**
 * Retrieves numeric value from given field and raw value
 * @param field
 * @param value
 * @returns {*}
 */
export function getFieldNumericValue(field: ReportField, value: any): number {
  switch (field.class) {
    case ReportFieldClass.AMOUNT:
      return get(value, 'amount', 0);
    default:
      return parseFloat(value);
  }
}

/**
 * Extract csv values for a given report table column
 * @param field
 * @returns {Function}
 */
export function getCsvValues(field: ReportField, defaultValue: any = '') {
  return function (value: any) {
    switch (field.class) {
      case ReportFieldClass.AMOUNT:
        return {
          [field.name]: getNotNil(value, 'amount', defaultValue),
          [`${field.name}_currency`]: getNotNil(
            value,
            'currency',
            defaultValue,
          ),
        };
      case ReportFieldClass.BILLING_PERIOD:
        return {
          [`${field.name}_start`]: getNotNil(value, 'start_date', defaultValue),
          [`${field.name}_end`]: getNotNil(value, 'end_date', defaultValue),
        };
      case ReportFieldClass.CODE_WITH_LABEL:
        return {
          [`${field.name}_code`]: getNotNil(value, 'code', defaultValue),
          [`${field.name}_label`]: getNotNil(value, 'label', defaultValue),
        };
      case ReportFieldClass.ENUMERATION:
        return {
          [`${field.name}_value`]: getNotNil(value, 'value', defaultValue),
          [`${field.name}_label`]: getNotNil(value, 'label', defaultValue),
        };
      case ReportFieldClass.UUID:
        return {
          [`${field.name}_uuid`]: getNotNil(value, 'uuid', defaultValue),
          [`${field.name}_label`]: getNotNil(value, 'label', defaultValue),
        };
      case ReportFieldClass.ACCOUNTING_SEGMENT:
        return {
          [`${field.name}_code`]: getNotNil(value, 'code', defaultValue),
          [`${field.name}_name`]: getNotNil(value, 'name', defaultValue),
        };

      default:
        return {
          [field.name]: isNil(value) ? defaultValue : value,
        };
    }
  };
}

/**
 * Transform a predefined report table to a Json array to build a CSV
 * @param table
 */

export function tableToCsvArray(table: ReportTable) {
  return table.data.map((datum: any) =>
    table.fields.reduce((line, field, fieldIndex: number) => {
      const getColumnCsvValues = getCsvValues(field);

      return {
        ...transformKeysToLabels(line),
        ...transformKeysToLabels(getColumnCsvValues(datum[fieldIndex])),
      };
    }, {}),
  );
}

export const transformKeysToLabels = (o: Object) =>
  Object.keys(o).reduce(
    (obj, key) => ({
      ...obj,
      [enumToLabel(key)]: o[key],
    }),
    {},
  );

/**
 * Helper to find a field in a list of fields by name
 */
export const matchFieldByName =
  (fieldName: string) =>
  (field: ReportField): boolean =>
    field.name === fieldName;

/**
 * Returns a function to add two report values belonging to the given field
 * @param {*} field
 */
export const getFieldCumulator = (field: ReportField): Function => {
  switch (field.class) {
    case ReportFieldClass.AMOUNT:
      return sumAmounts;
    case ReportFieldClass.INTEGER:
    case ReportFieldClass.DECIMAL:
    case ReportFieldClass.PERCENTAGE:
      return (a: number = 0, b: number = 0) => a + b;
    default:
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
      return () => {};
  }
};
