import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useWindowContext, DeviceSize } from 'contexts/window';
import UserActions from 'components/layout/App/UserActions';
import LoadPlaceholder from 'components/elements/LoadPlaceholder';
import Button, { ButtonTheme } from 'components/elements/Button';
import TextFilter from '../TextFilter';
import HeaderDivider from '../HeaderDivider';
import { Cta, Filter } from '../types';
import CtaButton from '../CtaButton';
import messages from './messages';
import styles from './Header.module.css';

type Props = {
  title: string;
  subTitle?: string;
  actions?: ReactNode;
  mainCta?: Cta;
  mainFilter?: Filter;
  onExit?: () => void;
  isDark?: boolean;
  isLoading?: boolean;
  className?: string;
};

const Header = ({
  title,
  subTitle,
  actions,
  mainCta,
  mainFilter,
  onExit,
  isDark,
  isLoading,
  className,
}: Props) => {
  const { deviceSize } = useWindowContext();
  const intl = useIntl();

  return (
    <header
      className={classNames(
        styles.container,
        className,
        isDark && styles.isDark,
        onExit && styles.withTightLeftPadding,
      )}
      data-testid="pageHeader"
    >
      <div className={styles.left}>
        {onExit && (
          <Button
            label={intl.formatMessage(messages.backBtn)}
            id="backBtn"
            iconName="arrowLeft"
            iconOnly
            onClick={onExit}
            theme={ButtonTheme.Ghost}
            className={styles.backBtn}
            testId="navBackBtn"
            dark={isDark}
          />
        )}
        {isLoading ? (
          <LoadPlaceholder style={{ width: 240, padding: 0, height: 24 }} />
        ) : (
          <div className={styles.headerBlock}>
            <h1 className={classNames(styles.title, 'header1')}>{title}</h1>
            {subTitle && (
              <div className={classNames(styles.subTitle, 'textS')}>
                {subTitle}
              </div>
            )}
          </div>
        )}
      </div>
      {actions || mainFilter || deviceSize === DeviceSize.desktop || mainCta ? (
        <div className={styles.right}>
          {mainFilter && (
            <>
              <HeaderDivider />
              <TextFilter filter={mainFilter} />
            </>
          )}
          {actions}
          {mainCta && (
            <>
              <HeaderDivider />
              <CtaButton cta={mainCta} isDark={isDark} />
            </>
          )}
          {deviceSize === DeviceSize.desktop && <UserActions isDark={isDark} />}
        </div>
      ) : null}
    </header>
  );
};

Header.defaultProps = {
  subTitle: undefined,
  actions: undefined,
  mainCta: undefined,
  mainFilter: undefined,
  onExit: undefined,
  isDark: false,
  isLoading: false,
  className: undefined,
};

export default Header;
