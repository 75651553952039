import { ReactNode } from 'react';
import classNames from 'classnames';
import RadioBox from 'components/elements/form/fields/RadioBox';

import styles from './Item.module.css';

type Props = {
  name?: string;
  text?: string;
  value?: any;
  className?: string;
  contentClassName?: string;
  checkedContentClassName?: string;
  children?: ((checked?: boolean) => ReactNode) | ReactNode;
  checked?: boolean;
  onChange?: (value: any) => void;
  disabled?: boolean;
  testId?: string;
};

const RadioGroupItem = ({
  checked,
  contentClassName,
  checkedContentClassName,
  children,
  className,
  name,
  value,
  disabled,
  text,
  onChange,
  testId,
}: Props) => {
  const buildContentTemplate = (content: ReactNode) => (
    <div
      className={classNames(
        contentClassName,
        checked && checkedContentClassName,
      )}
    >
      {content}
    </div>
  );

  const renderContent = (childrenArg: Props['children']) => {
    if (typeof childrenArg === 'function') {
      return buildContentTemplate(childrenArg(checked));
    }

    return buildContentTemplate(childrenArg);
  };
  return (
    <div className={classNames(styles.container, className)}>
      <RadioBox
        text={text}
        onChange={(e) => onChange(e.currentTarget.value)}
        disabled={disabled}
        id={`${name}_${value}`}
        data-testid={testId || name}
        value={value}
        checked={checked}
      />
      {renderContent(children)}
    </div>
  );
};

RadioGroupItem.defaultProps = {
  name: undefined,
  text: undefined,
  value: undefined,
  className: undefined,
  contentClassName: undefined,
  checkedContentClassName: undefined,
  children: null,
  disabled: false,
  onChange: undefined,
  checked: false,
  testId: undefined,
};

export default RadioGroupItem;
