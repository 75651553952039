import TagManager from 'utils/gtm';
import { AppConfig } from 'config/types';

export enum Event {
  SignupStarted = 'signupStarted',
}

export const initialize = (gtmId: AppConfig['gtmId']) => {
  if (!gtmId) return;
  TagManager.initialize({ gtmId, nonce: window.currentCspNonce });
};

export const sendEvent = (event: Event) => {
  if (!TagManager?.dataLayer) return;
  TagManager.dataLayer({ dataLayer: { event } });
};
