import superagent from 'superagent';
import isFunction from 'lodash/isFunction';
import { getClientHeaders, getClient } from '../client';

/**
 *  Normalizes a file service Url
 */
export function fileServiceUrl(url: string, randParam?: string) {
  const euid = getClient().getConfigKey('euid');
  return `${url}?${euid ? `euid=${euid}` : ''}${
    randParam ? `&${randParam}` : ''
  }`;
}

/**
 * Converts given blob to data url
 * @param {Blob} blob
 */
export const blobToDataUrl = (blob: Blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(this.result);
    };
    reader.readAsDataURL(blob);
  });

/**
 * Converts given blob to pdf data url
 * @param {Blob} blob
 */
export const blobToPdfDataUrl = async (blob: Blob) => {
  try {
    return await blobToDataUrl(blob);
  } catch (err) {
    return '';
  }
};

/**
 * Fetches a blob from file service
 * @param {*} url
 * @param {*} options
 */
export function fetch(url: string, options: any = {}) {
  const { transform = blobToDataUrl, headers = getClientHeaders() } = options;

  const request = superagent
    .get(fileServiceUrl(url, `${Math.random() * 100}`))
    .responseType('blob');

  Object.entries(headers).forEach(([key, value]: [string, any]) => {
    request.set(key, value);
  });

  return new Promise<ReturnType<typeof transform>>((resolve, reject) => {
    request.end((err, res) => {
      if (err) {
        return reject(err);
      }
      return resolve(isFunction(transform) ? transform(res.body) : res.body);
    });
  });
}
