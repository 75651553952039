import { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CompanyExtractService } from '@neo1/client';
import {
  notifySuccess,
  notifyError,
} from 'modules/App/redux/notifications/toaster/thunks';
import { enumToLabel } from '@neo1/core/utils/strings';
import { ROUTE_PATH_SETTINGS } from 'modules/Settings/constants';

const IntegrationsRedirect = () => {
  const { status, service } = useParams<{
    status: string;
    service: CompanyExtractService;
  }>();

  const dispatch = useDispatch();
  const serviceName = enumToLabel(service);

  useEffect(() => {
    if (status === 'success') {
      dispatch(notifySuccess(`Logged in ${serviceName} successfully.`));
    } else {
      dispatch(notifyError(`Could not connect to ${serviceName}.`));
    }
  }, [dispatch, status, serviceName]);

  return <Redirect to={ROUTE_PATH_SETTINGS} />;
};

export default IntegrationsRedirect;
