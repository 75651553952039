import { TxItem } from '@neo1/client/lib/entities/spend/txItem';
import {
  AmexVirtualCardInstrument,
  CreditCardInstrument,
  WalletInstrument,
} from '@neo1/core/modules/settings/paymentTypes/instruments/types';
import {
  AmexVirtualCardPaymentType,
  CardPaymentType,
  CashPaymentType,
} from '@neo1/core/modules/settings/paymentTypes/types/types';
import { Budget } from '../purchase';
import { TxEnhancedData } from './enhancedData';
import { TxRejectInfo } from './rejectInfo';
import { UUID } from '../common/strings';
import { Entity } from '../types';
import { CompanyUser } from '../user';

export const TxEntityName = 'Tx';
export const TxHistoryEntryEntityName = 'txHistoryEntry';
export type TxAmount = {
  amount: number;
  currency: string;
};

export enum TxCompletenessError {
  Purpose = 'purpose',
  ItemizedSumAmount = 'itemized_sum_amount',
  UndefinedTax = 'undefined_tax',
  MerchantName = 'merchant_name',
  DistributedSumAmount = 'distributed_sum_amount',
  MileageWaypoints = 'mileage_waypoints',
  NoAttendees = 'no_attendees',
  TaxInvalid = 'tax_invalid',
  SegmentCodeInvalid = 'segment_code_invalid',
  InactiveExpenseType = 'inactive_expense_type',
  EmptyItemization = 'empty_itemization',
  State = 'state',
  City = 'city',
  MissingTax = 'missing_tax',
  Country = 'country',
  InvalidAccountingConfiguration = 'invalid_accounting_configuration',
}

export enum TxStatus {
  Approve = 'approve',
  InProgress = 'in_progress',
  WaitingForApproval = 'waiting_for_approval',
  WaitingForReview = 'waiting_for_review',
  WaitingForExtract = 'waiting_for_extract',
  WaitingForExport = 'waiting_for_export',
  ExtractInProgress = 'extract_in_progress',
  Extracted = 'extracted',
  Exported = 'exported',
}

export enum OnlyViewTxStatus {
  Rejected = 'rejected',
  Ready = 'ready',
  Draft = 'draft',
}

export type ConsolidatedWorspaceTxStatus = TxStatus | OnlyViewTxStatus;

export type TxCountStatus =
  | TxStatus.InProgress
  | TxStatus.WaitingForApproval
  | TxStatus.WaitingForReview
  | TxStatus.WaitingForExtract
  | TxStatus.WaitingForExport;

export const TX_COUNT_STATUSES: TxCountStatus[] = [
  TxStatus.InProgress,
  TxStatus.WaitingForApproval,
  TxStatus.WaitingForReview,
  TxStatus.WaitingForExtract,
  TxStatus.WaitingForExport,
];
export const ZERO_TX_COUNT_NODE: TxCountNode = {
  total: 0,
  toSupervise: 0,
  toJustify: 0,
};

export const INITIAL_TX_COUNT: TxCounters = {
  ...ZERO_TX_COUNT_NODE,
  byStatus: {
    [TxStatus.InProgress]: {
      byOwnerId: {},
      byTag: {},
      count: ZERO_TX_COUNT_NODE,
      myTeamCount: ZERO_TX_COUNT_NODE,
    },
    [TxStatus.WaitingForApproval]: {
      byOwnerId: {},
      byTag: {},
      count: ZERO_TX_COUNT_NODE,
      myTeamCount: ZERO_TX_COUNT_NODE,
    },
    [TxStatus.WaitingForReview]: {
      byOwnerId: {},
      byTag: {},
      count: ZERO_TX_COUNT_NODE,
      myTeamCount: ZERO_TX_COUNT_NODE,
    },
    [TxStatus.WaitingForExtract]: {
      byOwnerId: {},
      byTag: {},
      count: ZERO_TX_COUNT_NODE,
      myTeamCount: ZERO_TX_COUNT_NODE,
    },
    [TxStatus.WaitingForExport]: {
      byOwnerId: {},
      byTag: {},
      count: ZERO_TX_COUNT_NODE,
      myTeamCount: ZERO_TX_COUNT_NODE,
    },
  },
};

export type TxCountNode = {
  total: number;
  toSupervise: number;
  toJustify: number;
};

type TxByStatusNode = {
  byOwnerId: Record<UUID, TxCountNode>;
  byTag: Record<string, TxCountNode>;
  count: TxCountNode;
  myTeamCount: TxCountNode;
};

export interface TxCounters extends TxCountNode {
  byStatus: { [s in TxCountStatus]: TxByStatusNode };
}

type TxExtractInfos = {
  accountingDeepLinkUrl: string | null;
  accountingSystem: string | null;
  extractDate: string | null;
};

export enum TxReceiptDataStatus {
  Paper = 'paper',
  NoReceipt = 'no_receipt',
  Missing = 'missing',
  Attached = 'attached',
  NotAvailable = 'not_available',
}

export type TxReceiptData = {
  comment: null | string;
  isValid: boolean;
  id?: null | string;
  status: TxReceiptDataStatus;
  url?: null | string;
  isLostCertified?: boolean;
};

export interface VirtualCardTx extends TxBase {
  paymentInstrument?: AmexVirtualCardInstrument;
  account?: AmexVirtualCardPaymentType;
}

export interface CreditCardTx extends TxBase {
  paymentInstrument?: CreditCardInstrument;
  account?: CardPaymentType;
}

interface CashTx extends TxBase {
  paymentInstrument?: WalletInstrument;
  account?: CashPaymentType;
}

export type Tx = CreditCardTx | CashTx | VirtualCardTx;

export type TxSupervisionData = {
  hasApproved: boolean;
  id: string;
  displayName?: string; // Present in archived transactions
};

export type TxSegment = {
  id: number;
  name: string;
  isReadOnly: boolean;
  isRequired: boolean;
};

export interface TxItemWithErrors extends TxItem {
  errors?: any;
}

export interface ValidatedTx extends TxBase {
  allErrors: any[];
  items: TxItemWithErrors[];
}

interface TxBase extends Entity {
  accountId: string;
  amount: TxAmount;
  budget?: Budget;
  billingAmount: TxAmount;
  billingPeriodEnd: string;
  billingPeriodKey: string;
  billingPeriodStart: string;
  city?: string;
  companyId?: string;
  completenessErrors: TxCompletenessError[];
  countryCode?: string;
  created: string | number;
  currency: string;
  date: string;
  enhancedData: TxEnhancedData;
  errors?: any;
  extractInfos?: TxExtractInfos;
  exchangeRate: number;
  grossAmount: number;
  hasItemizationChanges?: boolean;
  instrumentId: string;
  isCompanyPaid: boolean;
  isCompliant: boolean;
  isReadOnly: boolean;
  isSubmittable: boolean;
  items: TxItem[];
  itemsTotalAmount: number;
  lastUpdated: string;
  merchant?: string;
  owner?: CompanyUser;
  ownerId: string;
  ownerDisplayName?: string;
  paymentTypeText: string;
  purpose?: string;
  receipt: {
    status: TxReceiptDataStatus;
    id?: string;
    url?: string;
    isLostCertified?: boolean;
    comment?: string;
  };
  receiptStatus: string;
  rejectInfo: TxRejectInfo | null;
  reportedExchangeRate?: number;
  budgetId?: string;
  requestId?: string;
  requestName?: string;
  segments: TxSegment[];
  sourceEventType: string;
  state?: string;
  status: TxStatus;
  supervision: TxSupervisionData[];
  reviewers: CompanyUser[];
  updateError: Error | null;
  canSupervise?: boolean;
  isCrossCompany: boolean;
  onlyItemsEditable?: boolean;
  isOwnedByCurrentUser: boolean;
  tag?: string | null;
  type: string;
  txStatus?: ConsolidatedWorspaceTxStatus;
}
