import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import ScopeType from 'redux/scopeType';
import { selectCountersByScope } from 'redux/counter/selector';
import { useSelector } from 'react-redux';
import {
  ROUTE_PATH_AUTHORIZE_BUDGET,
  ROUTE_PATH_AUTHORIZE_PURCHASE,
  ROUTE_PATH_AUTHORIZE_SPEND,
} from '../constants';

const AuthorizeIndex = () => {
  const history = useHistory();
  const counts = useSelector(selectCountersByScope(ScopeType.Supervisor));

  useEffect(() => {
    if (!counts) return;

    if (counts.budget.toApprove.count) {
      history.replace(generatePath(ROUTE_PATH_AUTHORIZE_BUDGET));
      return;
    }

    if (counts.purchase.toApprove.count) {
      history.replace(generatePath(ROUTE_PATH_AUTHORIZE_PURCHASE));
      return;
    }

    if (counts.tx.toApprove.count) {
      history.replace(`${generatePath(ROUTE_PATH_AUTHORIZE_SPEND)}?v=approve`);
      return;
    }

    if (counts.tx.toReview.count) {
      history.replace(`${generatePath(ROUTE_PATH_AUTHORIZE_SPEND)}?v=review`);
      return;
    }

    // This is the default route if all counters are at 0
    history.replace(`${generatePath(ROUTE_PATH_AUTHORIZE_SPEND)}?v=approve`);
  }, [counts]);

  return null;
};

export default AuthorizeIndex;
