import async from 'utils/wrapAsync';
import { hasSpendManagement, AppMenuRoute } from 'config/routes/utils';
import { ROUTE_PATH_PAYMENTS, ROUTE_NAME_PAYMENTS } from './constants';

const route: AppMenuRoute = {
  title: 'Payment methods',
  show: hasSpendManagement,
  exact: true,
  menu: {
    iconClass: 'paymentMethods',
  },
  path: ROUTE_PATH_PAYMENTS,
  name: ROUTE_NAME_PAYMENTS,
  component: async(
    () =>
      import(
        /* webpackChunkName: "paymentMethods" */
        '.'
      ),
  ),
};

export default route;
