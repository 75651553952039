import { Currency } from '@neo1/core/modules/app/entities';
import ScopeType from 'redux/scopeType';

export type FullValues = {
  count: number;
  amount: number;
  currency: Currency['code'];
};

export type SimpleValues = {
  count: number;
};

export type Counters = {
  tx: {
    inApprove: FullValues;
    inExtract: FullValues;
    inProgress: FullValues;
    inReview: FullValues;
    toApprove: FullValues;
    toReview: FullValues;
  };
  budget: {
    inApprove: FullValues;
    inProgress: FullValues;
    toApprove: FullValues;
    approved: FullValues;
  };
  purchase: {
    inApprove: FullValues;
    inProgress: FullValues;
    toApprove: FullValues;
    approved: FullValues;
  };
  trips: {
    current: SimpleValues;
    future: SimpleValues;
  };
};

export type State = Record<ScopeType, Counters>;

export enum Actions {
  FETCH_COUNTERS = 'one/counter/FETCH_COUNTERS',
  SET_COUNTERS = 'one/counter/SET_COUNTERS',
}

type FetchCounters = {
  type: Actions.FETCH_COUNTERS;
};

type SetCounters = {
  type: Actions.SET_COUNTERS;
  state: State;
};

export type Action = FetchCounters | SetCounters;
