import { Dispatch } from 'redux';
import { CompanyExtractService, UUID } from '@neo1/client';
import { getAccountingOAuthCallbackUrl } from 'utils/bootstrap/oauth';
import * as companiesApi from '@neo1/core/modules/companies/api';
import { setConfig, setUsersVendors, setVendors } from './actions';

export const startQuickBooksConnection = async (
  companyId: UUID,
  location: any,
) => {
  const { url } = await companiesApi.connectExtractService(
    companyId,
    CompanyExtractService.QuickBooks,
    getAccountingOAuthCallbackUrl(
      CompanyExtractService.QuickBooks,
      location.pathname,
    ),
  );

  if (url) {
    window.location.href = url;
  }
};

export const getVendors = (companyId: UUID) => async (dispatch: Dispatch) => {
  const vendors = await companiesApi.getVendors(companyId);
  dispatch(setVendors(CompanyExtractService.QuickBooks, vendors));
};

export const getUsersVendors =
  (companyId: UUID) => async (dispatch: Dispatch) => {
    const usersVendors = await companiesApi.getUsersVendors(companyId);
    dispatch(setUsersVendors(CompanyExtractService.QuickBooks, usersVendors));
  };

export const getAccountingConfig =
  (companyId: UUID) => async (dispatch: Dispatch) => {
    const companyConfig = await companiesApi.getCompanyAccountingConfig(
      companyId,
    );
    dispatch(setConfig(CompanyExtractService.QuickBooks, companyConfig));
  };
