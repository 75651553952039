import { CollectionSortFilters } from '@neo1/core/utils';

export enum Actions {
  SET_EXPENSE_TYPES = 'one/settings/ExpenseTypes/SET_EXPENSE_TYPES',
  SET_EXPENSE_TYPE = 'one/settings/ExpenseTypes/SET_EXPENSE_TYPE',
  SET_SORT_OPTIONS = 'one/settings/ExpenseTypes/SET_SORT_OPTIONS',
  SET_SEARCH_FILTER = 'one/settings/ExpenseTypes/SET_SEARCH_FILTER',
}
type SetExpenseTypes = {
  type: Actions.SET_EXPENSE_TYPES;
  ids: string[];
};

type SetExpenseType = {
  type: Actions.SET_EXPENSE_TYPE;
  id: string;
};

type ActionSetSortOptions = {
  type: Actions.SET_SORT_OPTIONS;
  options: CollectionSortFilters;
};
type ActionSetSearchFilter = {
  type: Actions.SET_SEARCH_FILTER;
  search: string;
};
export function setAll(ids: string[]) {
  return { type: Actions.SET_EXPENSE_TYPES, ids };
}

export function set(id: string) {
  return { type: Actions.SET_EXPENSE_TYPE, id };
}

export function setColumnSortOptions(options: Record<string, any>) {
  return {
    type: Actions.SET_SORT_OPTIONS,
    options,
  };
}

export function setSearchFilter(search: string) {
  return { type: Actions.SET_SEARCH_FILTER, search };
}

export type ExpenseActions =
  | SetExpenseTypes
  | SetExpenseType
  | ActionSetSortOptions
  | ActionSetSearchFilter;
