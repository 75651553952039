import { createContext, ReactNode } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
} from 'react-router-dom';
import {
  hasReportingFeature,
  hasSpendManagementFeature,
} from '@neo1/core/modules/companies/utils';
import { IconName } from 'components/elements/Icon';
import { User, Company, UserLevel, CompanyUserRole } from '@neo1/client';
import { hasCompanyRole } from '@neo1/core/modules/users/utils';

export type RouteContext = {
  pathname: string;
  user: User;
  company?: Company;
};

type RouteSlugs = 'page' | 'txId' | 'budgetId';

export type RouteParams = Record<RouteSlugs, string>;

export type AccessRouteCallback = (context: RouteContext) => boolean;

export type AppRouteMenuConfig = {
  hasBadge?: boolean;
  iconClass: IconName;
  userLevels?: UserLevel[];
  userRoles?: CompanyUserRole[];
  companyFeatures?: string[];
  path?: string;
  component?: any;
  show?: AccessRouteCallback;
  subItems?: any;
};

export type AppRoute = {
  title?: string | (() => ReactNode);
  name: string;
  path: string;
  generatePath?: (params: Record<string, string>) => string;
  exact?: boolean;
  defaultPath?: string;
  defaultRoute?: string;
  component?: any;
  routeOrder?: number;
  hideTab?: boolean;
  show?: AccessRouteCallback;
  children?: AppRoute[];
  strict?: boolean;
  userLevels?: UserLevel[];
  render?: (routeProps?: RouteComponentProps) => ReactNode;
};

export interface AppMenuRoute extends AppRoute {
  menu: AppRouteMenuConfig;
}

export const isReportingOnly = ({ company }: RouteContext) =>
  hasReportingFeature(company) && company.features.length === 1;

export const hasSpendManagement = ({ company }: RouteContext) =>
  hasSpendManagementFeature(company);

export const hasGuestManagement = (user: User) =>
  hasCompanyRole(user, CompanyUserRole.Admin) ||
  hasCompanyRole(user, CompanyUserRole.Arranger) ||
  hasCompanyRole(user, CompanyUserRole.TravelHost);

export const TabsContext = createContext<AppRoute[]>([]);

export const mapRouteConfigToRoutes = (
  config: AppRoute[],
  filterFn: (r: AppRoute) => boolean = (_) => true,
) =>
  config.reduce((appRoutes, route) => {
    const { component: RouteComponent, path, exact, children = [] } = route;

    const isEnabled = filterFn(route);
    const allowedChildrenRoutes = children.filter(filterFn);

    const renderRoute = ({
      location,
      component,
      render,
      path: path2,
      exact: exact2,
      sensitive,
      strict,
    }: RouteProps) => (
      <TabsContext.Provider value={allowedChildrenRoutes}>
        {RouteComponent ? (
          <RouteComponent
            location={location}
            component={component}
            render={render}
            path={path2}
            exact={exact2}
            sensitive={sensitive}
            strict={strict}
          >
            {children}
          </RouteComponent>
        ) : (
          <Switch>
            {allowedChildrenRoutes.map(
              ({
                component: childComponent,
                render: childRender,
                path: childPath,
                exact: childExact,
                strict: childExtrict,
                children: childChildren,
              }: AppRoute) => (
                <Route
                  key={childPath}
                  component={childComponent}
                  render={childRender}
                  path={childPath}
                  exact={childExact}
                  strict={childExtrict}
                >
                  {childChildren}
                </Route>
              ),
            )}
            <Redirect to="/" />
          </Switch>
        )}
      </TabsContext.Provider>
    );
    if (isEnabled) {
      return appRoutes.concat(
        <Route
          key={route.name}
          render={renderRoute}
          path={path}
          exact={exact}
        />,
      );
    }

    return appRoutes;
  }, []);
