import { Entity } from '../../entities/types';
import { reify } from '../../entities/normalize';

export interface TravelReceipt extends Entity {
  id: string;
  pnr: string;
  url: string;
  travelerName?: string;
  tripName?: string;
  amount?: number;
  currency?: string;
  bookedDate?: string;
  merchantName?: string;
}

export const normalizeTravelReceipt = (result: any): TravelReceipt => {
  const data = reify(result);
  return {
    entityType: 'travelReceipt',
    // there is no id on the backend for the travel receipts object, so we create it ourselves to create an unique key.
    id: `${data.getString('pnr')}_${data.getNumber('service_index', null)}`,
    pnr: data.getString('pnr'),
    tripName: data.getStringOrNull('trip_name'),
    travelerName: data.getStringOrNull('traveler_name'),
    bookedDate: data.getStringOrNull('booked_date'),
    currency: data.getStringOrNull('purchase_amount.currency'),
    amount: data.getNumber('purchase_amount.amount', null),
    url: data.getStringOrNull('url'),
    merchantName: data.getStringOrNull('merchant_name'),
  };
};
