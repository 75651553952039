import { reify } from '@neo1/client';

export type FundingApproversData = {
  fundingApproversIds: string[];
  // Record<fundingApproverId(this is an user id), string(virtual card billing account id)[]>
  ownedAccountsIds: Record<string, string[]>;
};

export const normalizeFundingApproversData = (
  inputData: any = [],
): FundingApproversData =>
  inputData.reduce(
    (result, data: any) => {
      const d = reify(data);
      const fundingApproverId = d.getString('funding_approver_id');
      const ownedAccountIds = d.getArray<string>('owned_account_ids');
      return {
        fundingApproversIds: [...result.fundingApproversIds, fundingApproverId],
        ownedAccountsIds: {
          ...result.ownedAccountsIds,
          [fundingApproverId]: ownedAccountIds,
        },
      };
    },
    {
      fundingApproversIds: [],
      ownedAccountsIds: {},
    },
  );
