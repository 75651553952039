import { fetchAppReferentialData } from '@neo1/core/modules/app/api';
import { loadReferentialSuccess } from './actions';

/**
 * Loads App Referential
 */
export const loadReferential = () => async (dispatch: Function) => {
  const data = await fetchAppReferentialData();
  dispatch(loadReferentialSuccess(data));
};

export default loadReferential;
