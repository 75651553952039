import { CompanyActivationData } from '@neo1/core/modules/persisted/types';
import isNumber from 'lodash/isNumber';

/**
 * Asserts if user is editing owner or not
 * @param values
 */
export function isEditingOwner(values: CompanyActivationData) {
  return isNumber(values.editedOwnerIndex);
}

export * from './constants';
export * from './validation';
