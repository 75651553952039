import dayjs, { ConfigType as DateInput, ManipulateType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const newTimestamp = (date?: string) => {
  const newDate = date ? new Date(date) : new Date();
  return newDate.getTime();
};

export const todayDate = () => dayjs().format('YYYY-MM-DD');

export const formatDateTimeToUTC = (date: string, time: string) =>
  dayjs(`${date} ${time}`, 'YYYY-MM-DD HH:mm').utc().format();

export const prettyAndShortDateTime = (date: DateInput) =>
  dayjs(date as Date).format('D MMM YYYY [at] HH:mm');

/**
 * Converts milliseconds to seconds
 * @param {Number} timeInMillis
 */
export const msToSeconds = (timeInMillis: number) =>
  Math.ceil(timeInMillis / 1000);

/**
 * Generates a date range array with the right bound being now and left bound a date in the past
 * @param {*} count positive integer
 * @param {*} timeUnit dayjs duration input
 * @param {*} format a dayjs format
 */
export const getDateRangeToNow = (
  count = 1,
  timeUnit: ManipulateType = 'month',
  format = 'YYYY-MM-DD',
) => [
  dayjs().add(-Math.abs(count), timeUnit).format(format),
  dayjs().format(format),
];

/**
 * Formats given date string
 * @param date
 */
export const formatTimeAgo = (date: DateInput) => {
  if (date) {
    return dayjs(date).fromNow();
  }
};

/**
 * Generates date range for last monthCount months
 * @param {*} monthCount
 */
export const getDateRangeForLastMonths = (
  monthCount = 1,
  format = 'YYYY-MM-DD',
) => getDateRangeToNow(monthCount, 'month', format);

export const DatePresetType = {
  DATE: 'date',
  RANGE: 'range',
};
