import prop from 'lodash/property';
import chunk from 'lodash/chunk';
import superagent from 'superagent';
import { RpcClientError } from '../rpc';
import {
  concatBatchResults,
  RpcPayload,
  RpcBatchCommand,
  PayloadMapper,
  isRpcErrorResult,
  RpcCommand,
  isRpcBatchResult,
  RpcResult,
  isRpcBatchPayload,
  consolidatePayload,
  mapErrorToClientError,
} from './utils';

export const MAX_CHUNK_SIZE = 50;

export type ClientEventCallback = (event: any) => void;

export type OneRpcApiClientConfig = {
  debug?: boolean;
  baseUrl?: string;
  authToken?: string;
  neo1Version?: string;
  clientType?: 'ui' | 'mobile';
  euid?: string;
};

export class OneRpcApiClient {
  config: OneRpcApiClientConfig;
  pendingRequestsCount: number = 0;

  listeners: Record<string, ClientEventCallback[]> = {};

  constructor(config?: Partial<OneRpcApiClientConfig>) {
    this.config = {
      debug: false,
      baseUrl: '/api/rpc',
      ...config,
    };
  }

  /**
   * adds 1 to pendingRequestsCount
   */
  addRequestPending() {
    this.pendingRequestsCount++;
  }
  /**
   * substract 1 from pendingRequestsCount
   */
  removeRequestPending() {
    this.pendingRequestsCount--;
  }

  /**
   * Generates the JSON RPC Api Enpoint Url
   * @param payload
   * @returns {string}
   */
  getRPCEndpointUrl(payload: RpcPayload) {
    let endpointUrl = this.config.baseUrl;

    const query: Record<string, string> = {
      euid: this.config.euid,
    };

    if (this.config.debug) {
      // This is only extra logging information to quickly identify rpc queries
      if (Array.isArray(payload)) {
        query.methods = payload.map(prop('method')).join(',');
      } else if (payload.method) {
        query.method = payload.method;
      }
    }

    const finalQueryKeys = Object.keys(query).filter((k) => query[k]);

    if (finalQueryKeys.length > 0) {
      const requestQuery = finalQueryKeys
        .map((k) => `${k}=${query[k]}`)
        .join('&');
      endpointUrl = `${endpointUrl}?${requestQuery}`;
    }

    return endpointUrl;
  }

  requestInterceptor: (payload: RpcPayload) => void | Promise<void> = undefined;

  /**
   * Builds and sends the JSON-RPC command
   * @param payload
   * @param ignoreErrors
   * @returns {Promise}
   */
  async sendRequest(
    payload: RpcPayload,
    notifyErrors: boolean = true,
  ): Promise<any> {
    if (this.requestInterceptor) {
      await this.requestInterceptor(payload);
    }
    let result: superagent.Response;

    const request = superagent
      .post(this.getRPCEndpointUrl(payload))
      .send(consolidatePayload(payload))
      .set('Accept', 'application/json');

    Object.entries(this.getClientInfoHeaders()).forEach(([name, val]) => {
      request.set(name, val);
    });

    if (this.config.authToken) {
      request.set('Authorization', `Bearer ${this.config.authToken}`);
    }

    this.addRequestPending();

    try {
      result = await request.then();
    } catch (err) {
      const clientError = mapErrorToClientError(err);

      if (notifyErrors) {
        this.notify('error', clientError);
      }
      this.removeRequestPending();
      throw clientError;
    }

    this.removeRequestPending();

    return result.body;
  }

  waitForPendingRequests(): Promise<void> {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (this.pendingRequestsCount === 0) {
          clearInterval(intervalId);
          resolve();
        }
      }, 250);
    });
  }

  /**
   * Sends a JSON-RPC command to JSON-RPC api
   * @param method
   * @param params
   * @param id
   * @returns {Promise.Object}
   */
  sendCommand(command: RpcCommand, notifyErrors: boolean = true): Promise<any> {
    return this.sendRequest(command, notifyErrors).then(prop('result'));
  }

  /**
   * Send several JSON-RPC in one batch
   * @param commands
   * @param ignoreErrors
   * @returns {Promise}
   */
  async batchCommands(
    commands: RpcBatchCommand,
    ignoreErrors: boolean = true,
  ): Promise<RpcResult[]> {
    if (!Array.isArray(commands) || commands.length === 0) {
      throw 'Batch payload can not be empty';
    }

    let results: RpcResult[] = [];

    for await (let chunkResults of this.sendChunk(commands)) {
      results = results.concat(chunkResults);
    }

    const errorResults = results.filter(isRpcErrorResult);

    if (errorResults.length && !ignoreErrors) {
      const error = new Error() as RpcClientError;
      error.messages = errorResults.map((err) => err.error.message);
      throw error;
    }

    return results;
  }

  async *sendChunk(
    payload: any[],
    mapChunk?: PayloadMapper,
    chunkSize = MAX_CHUNK_SIZE,
  ) {
    const chunks = chunk(payload, chunkSize);

    for (const payload of chunks) {
      const chunk = isRpcBatchPayload(payload) ? payload : mapChunk(payload);
      yield await this.sendRequest(chunk);
    }
  }

  /**
   * Will map over chunks of items to trigger a request for each chunk
   * @param {*} items
   * @param {*} mapChunk
   * @param {*} chunkSize
   */
  async sendBulk<T>(
    items: T[],
    mapChunk?: PayloadMapper,
    notifyProgress?: Function,
    chunkSize: number = MAX_CHUNK_SIZE,
  ) {
    let results: any[] = [];

    const chunksCount = Math.ceil(items.length / chunkSize);

    let chunkNo = 0;

    for await (let response of this.sendChunk(items, mapChunk, chunkSize)) {
      if (typeof notifyProgress === 'function') {
        notifyProgress((Number(chunkNo) / chunksCount) * 100);
      }

      if (isRpcBatchResult(response)) {
        results = results.concat(response.reduce(concatBatchResults, []));
      } else if (!isRpcErrorResult(response)) {
        results = results.concat(response.result);
      }

      chunkNo++;
    }

    return results;
  }

  notify(msg: string, eventData: any) {
    const listeners = this.listeners[msg] || [];
    listeners.map((listenerFunc) => listenerFunc(eventData));
  }

  on(msg: string, listenerFn: ClientEventCallback = (f) => f) {
    if (typeof msg === 'string' && msg && typeof listenerFn === 'function') {
      this.listeners[msg] = (this.listeners[msg] || []).concat(listenerFn);
      return () => {
        this.listeners[msg] = this.listeners[msg].filter(
          (fn) => fn !== listenerFn,
        );
      };
    }
    throw new Error(
      'on should be supplied a valid message (string) and a listener (function)',
    );
  }

  setConfigKey(k: keyof OneRpcApiClientConfig, v: any) {
    Object.assign(this.config, { [k]: v });
  }

  getConfigKey<K extends keyof OneRpcApiClientConfig>(
    k: K,
  ): OneRpcApiClientConfig[K] {
    return this.config[k];
  }

  unsetConfigKey(k: keyof OneRpcApiClientConfig) {
    delete this.config[k];
  }

  getClientInfoHeaders() {
    const neo1Version = this.getConfigKey('neo1Version');
    const clientType = this.getConfigKey('clientType');
    const headers: Record<string, string> = {};

    if (neo1Version) {
      headers['X-Neo1-Version'] = neo1Version.toString();
    }

    if (clientType) {
      headers['X-Neo1-Client'] = clientType.toString();
    }

    return headers;
  }
}
