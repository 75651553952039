import { ReactNode } from 'react';
import classNames from 'classnames';
import { CompanyActivationData } from '@neo1/core/modules/persisted/types';
import GetStarted, { GetStartedActions } from './GetStarted';
import About from './About';
import Actions from './Address/Actions';
import { OwnersActions } from './Owners';
import Neo1Features, { Neo1FeaturesActions } from './Neo1Features';
import Finish, { FinishActions } from './Finish';

import styles from './StepView.module.css';
import useGoback from '../useGoBack';

export const GetStartedView = ({ className }: { className: string }) => (
  <div className={classNames(styles.container, className)}>
    <GetStarted />
    <GetStartedActions className={styles.startBtn} />
  </div>
);

export const AboutView = ({
  className,
  onBack,
}: {
  className: string;
  onBack: (values: CompanyActivationData) => void;
}) => {
  const { handleOnBack } = useGoback(onBack);

  return (
    <div className={classNames(styles.container, className)}>
      <About />
      <Actions className={styles.btn} onGoBack={handleOnBack} />
    </div>
  );
};

export const OwnersView = ({
  className,
  isSubmitDisabled,
  isShowingOwnerValidationResult,
  onBackOwnerAddressValidation,
  onBack,
  children,
}: {
  className: string;
  isSubmitDisabled: boolean;
  isShowingOwnerValidationResult: boolean;
  onBackOwnerAddressValidation: () => void;
  onBack: (values: CompanyActivationData) => void;
  children: ReactNode;
}) => {
  const { handleOnBack } = useGoback(onBack);
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.ownerViewWrapper}>{children}</div>
      <OwnersActions
        className={styles.btn}
        isSubmitDisabled={isSubmitDisabled}
        isShowingOwnerValidationResult={isShowingOwnerValidationResult}
        onBackOwnerAddressValidation={onBackOwnerAddressValidation}
        onGoBack={handleOnBack}
      />
    </div>
  );
};
export const Neo1FeaturesView = ({
  className,
  onBack,
}: {
  className: string;
  onBack: (values: CompanyActivationData) => void;
}) => {
  const { handleOnBack } = useGoback(onBack);
  return (
    <div className={classNames(styles.container, className)}>
      <Neo1Features />
      <Neo1FeaturesActions className={styles.btn} onGoBack={handleOnBack} />
    </div>
  );
};
export const FinishView = ({ className }: { className: string }) => (
  <div className={classNames(styles.container, className)}>
    <Finish />
    <FinishActions />
  </div>
);
