import get from 'lodash/get';
import deepEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import lowerCase from 'lodash/lowerCase';
import { SortTypes } from '../modules/config/constants';

const EMPTY_FILTERS: CollectionFilters = {
  search: '',
};

type FilterFn<T> = (obj: T) => boolean;

export type CollectionSortFilters = {
  prop: string;
  dir?: SortTypes;
};

export type CollectionFilters<T extends {} = {}, O extends {} = {}> = {
  search: string;
  index?: any;
  value?: Partial<T>;
  sort?: CollectionSortFilters;
  options?: O;
};

const isRealNumber = (a: any) => !isNaN(Number(a));

export const sortAlphaNumeric =
  <T>(
    sort: CollectionSortFilters,
    getSortValue?: (row: T) => string | number,
  ) =>
  (a: T, b: T) => {
    if (!sort) {
      return 0;
    }
    const sortKey = sort.prop;
    const sortDir = sort.dir;
    const commuter = sortDir === SortTypes.DESC ? -1 : 1;

    if (sortKey) {
      let valueA = getSortValue ? getSortValue(a) : get(a, sortKey);
      let valueB = getSortValue ? getSortValue(b) : get(b, sortKey);

      const isString = typeof valueA === 'string' && typeof valueB === 'string';
      if (isNil(valueA)) valueA = isString ? '' : Number.NEGATIVE_INFINITY;
      if (isNil(valueB)) valueB = isString ? '' : Number.NEGATIVE_INFINITY;

      if (isRealNumber(valueA) && isRealNumber(valueB)) {
        return (valueA - valueB) * commuter;
      }
      if (isRealNumber(valueA)) {
        return -1 * commuter;
      }
      if (isRealNumber(valueB)) {
        return 1 * commuter;
      }
      if (isString) {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        return valueA > valueB
          ? 1 * commuter
          : valueA < valueB
          ? -1 * commuter
          : 0;
      }
    }
    return 0;
  };

/**
 * HOF asserting if item matches filters.search
 * @param filters
 * @returns
 */
export const matchesSearchFilter =
  <T extends {}>(filters?: CollectionFilters) =>
  (item: T) => {
    const searchQuery = (filters.search || '').toLowerCase();

    if (!searchQuery) {
      return true;
    }

    const searchBase = Object.entries(item).reduce((searchBase, [key, val]) => {
      // Prevent filtering on inadequate fields
      if (key === 'id' || key === 'entityType') return searchBase;

      switch (typeof val) {
        case 'boolean':
          return val ? searchBase.concat(`;${key.toLowerCase()}`) : searchBase;
        case 'string':
          return searchBase.concat(`;${val.toLowerCase()}`);
        default:
          return searchBase;
      }
    }, '');

    return searchBase.indexOf(searchQuery) >= 0;
  };

/**
 * HOF asserting if item matches filters.value
 * @param filters
 * @returns
 */
const matchesValueFilter =
  <T extends {}>({ value }: CollectionFilters<T>) =>
  (item: T) => {
    return (
      !value || Object.entries(value).every(([key, val]) => item[key] === val)
    );
  };

/**
 * HOF asserting if item fields include filters.value, use only on string fields.
 * @param filters
 * @returns
 */
export const includesValueFilter =
  <T extends {}>({ value }: CollectionFilters<T>) =>
  (item: T) => {
    return (
      !value ||
      Object.entries(value).every(([key, query = '']: [string, string]) => {
        const fieldValue = item[key] || '';

        return lowerCase(fieldValue).includes(lowerCase(query));
      })
    );
  };

/**
 * HOF to assert if entity matches given filters
 */
export const matchesFilters = <T>(filters: CollectionFilters<T>) =>
  composeFilters(matchesSearchFilter(filters), matchesValueFilter(filters));

/**
 * Applies default sort and search filters to the given collection
 * @param filters
 * @param collection
 */
export const applyFilters = <T = any>(
  collection: T[] = [],
  filters = EMPTY_FILTERS,
) => {
  return collection.filter(matchesSearchFilter(filters));
};

export const applyFilterByValue = <T extends {}>(
  collection: T[] = [],
  filters = EMPTY_FILTERS,
) => {
  return Object.keys(filters).length > 0
    ? collection.filter((item) => deepEqual(item[filters.index], filters.value))
    : collection;
};

/**
 * Denormalizes a collection of data to a hash with each key matching to an element
 * @param data
 * @param idKey
 * @returns {{}}
 */
export const denormalizeById = <T extends Record<string, any>>(
  data: T[] = [],
  idKey = 'id',
) => {
  const dataById: Record<string, T> = {};

  data.forEach((datum) => {
    dataById[datum[idKey]] = Object.assign({}, datum);
  });

  return dataById;
};

/**
 * Allows to compose several filter functions into one
 * @param filters
 */
export const composeFilters =
  <T>(...filters: FilterFn<T>[]) =>
  (obj: T) =>
    filters.every((f) => f(obj));
