import superagent from 'superagent';
import { getClientHeaders } from '../client';

/**
 * Fetches File API file and resolves to a Blob
 * @param {string} url
 */
export const fetchBlob = (
  url: string,
): Promise<superagent.Response['body']> => {
  const request = superagent.get(url).responseType('blob');
  Object.entries(getClientHeaders()).forEach(([key, value]) => {
    request.set(key, value);
  });
  return new Promise((resolve, reject) =>
    request.end((err, res) => (err ? reject(err) : resolve(res.body))),
  );
};
