import * as signupApi from '@neo1/core/modules/auth/signup/api';
import { CompanyActivationData } from '@neo1/core/modules/persisted/types';
import {
  SignupParams,
  SaveBusinessLeadParams,
} from '@neo1/core/modules/auth/signup/types';
import { setEntity } from 'redux/entities/thunks';
import { Company, CompanyEntityName } from '@neo1/client';

export const saveBusinessLead = (data: SaveBusinessLeadParams) =>
  signupApi.saveBusinessLead(data);

export const signup = (data: SignupParams) => signupApi.signup(data);

export const activate = (token: string) => signupApi.activateUser(token);

export const activateCompany =
  (data: CompanyActivationData) => async (dispatch: any) => {
    const company = await signupApi.activateCompany(data);

    setEntity<Company>({
      entityType: CompanyEntityName,
      entity: company,
    })(dispatch);

    return company;
  };
