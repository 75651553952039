import { UserTitle } from '@neo1/client/lib/entities/user';
import { Entity } from '../../entities/types';
import { reify } from '../../entities/normalize';

export const GuestEntityName = 'Guest';
export interface Guest extends Entity {
  id: string;
  hostId: string;
  companyId: string;
  email: string;
  firstName: string;
  lastName: string;
  title: UserTitle;
  dateOfBirth: string;
  phone: string;
}

export type CreateGuestParams = Omit<Guest, 'id' | 'companyId' | 'entityType'>;
export interface CreateGuestApiParams
  extends Pick<Guest, 'email' | 'phone' | 'title'> {
  host_id: Guest['hostId'];
  first_name: Guest['firstName'];
  last_name: Guest['lastName'];
  birth_date: Guest['dateOfBirth'];
  wait_for_travel_synchronization: boolean;
}

export type UpdateGuestParams = Pick<
  Guest,
  'id' | 'firstName' | 'lastName' | 'title' | 'phone' | 'dateOfBirth'
>;
export interface UpdateGuesApiParams extends Pick<Guest, 'title' | 'phone'> {
  guest_id: Guest['id'];
  first_name: Guest['firstName'];
  last_name: Guest['lastName'];
  birth_date: Guest['dateOfBirth'];
}

export const normalizeGuest = (result: any): Guest => {
  const data = reify(result);
  return {
    entityType: GuestEntityName,
    id: data.getString('guest_id'),
    hostId: data.getString('host_id'),
    companyId: data.getString('company_id'),
    email: data.getString('email'),
    firstName: data.getString('first_name'),
    lastName: data.getString('last_name'),
    title: data.getString('title') as UserTitle,
    dateOfBirth: data.getString('birth_date'),
    phone: data.getString('phone'),
  };
};
