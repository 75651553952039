import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import {
  notifySuccess,
  notifyError,
} from 'modules/App/redux/notifications/toaster/thunks';
import { syncExtractService } from '@neo1/core/modules/companies/api';
import { setIntegrationSettings } from 'redux/accountingIntegrations/actions';
import {
  CompanyExtractService,
  COMPANY_EXTERNAL_ACCOUNTING_LINKS,
} from '@neo1/client';
import { BootstrapContext } from './types';

async function handleExtractServiceOAuthSuccess(
  ctx: BootstrapContext,
  service: CompanyExtractService,
) {
  if (COMPANY_EXTERNAL_ACCOUNTING_LINKS.includes(service)) {
    return setIntegrationSettings(service, false);
  }

  // DEV-3220 FIXME - Just Remove this section when DEV-3295/DEV-3219 will be merged
  // <ToRemove>
  await syncExtractService(selectActingCompany(ctx.store.getState())?.id);
  // </ToRemove>

  return notifySuccess(`Successfully connected to ${service}`);
}

/**
 * Detects if we are redirecting a user after a xero connection
 * The query string must contains keys oauth_redirect === true and service should be an accounting service
 */
export async function detectAccountingServiceOauthRedirection(
  ctx: BootstrapContext,
) {
  const path = new URL(window.location.href);

  if (path.searchParams.has('oauth_redirect')) {
    const service = path.searchParams.get('service') as CompanyExtractService;
    const redirectUrl = path.searchParams.get('r');

    ctx.store.dispatch(
      path.searchParams.get('result') !== 'failure'
        ? await handleExtractServiceOAuthSuccess(ctx, service)
        : notifyError(`Could not connect to ${service}`),
    );

    window.history.replaceState({}, document.title, '/');

    ctx.history.replace(redirectUrl);
  }
}

/**
 * Returns Oauth redirect url for given service
 * @param service
 */
export function getAccountingOAuthCallbackUrl(
  service: CompanyExtractService,
  redirectPath: string,
) {
  return `${window.location.protocol}//${window.location.host}?oauth_redirect&service=${service}&r=${redirectPath}`;
}
