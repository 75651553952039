import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import not from 'lodash/negate';
import matchProp from 'lodash/matchesProperty';
import {
  getFieldNumericValue,
  isNumericField,
  getFieldCumulator,
} from './fields';
import { denormalizeById } from '../../../utils/collections';

/**
 * Checks if given table is empty
 * @param {*} table
 */
export function isTableEmpty(table) {
  return isNil(table) || isEmpty(table) || table.lines.length === 0;
}

/**
 * Compute given report table limits for all numeric fields
 * @param {*} table
 */
export function computeTableLimits(table) {
  const { fields, lines } = table;

  const numericFields = fields.filter(isNumericField);

  return lines.reduce((result, line) => {
    return numericFields.reduce((aggregatedValues, field) => {
      const fieldName = field.name;
      const fieldIndex = fields.findIndex(matchProp('name', fieldName));

      const currentLineFieldValue = getFieldNumericValue(
        field,
        line[fieldIndex],
      );

      const fieldAggregatedValues = aggregatedValues[fieldName] || {
        min: currentLineFieldValue,
        max: currentLineFieldValue,
      };

      return {
        ...aggregatedValues,
        [fieldName]: {
          max:
            fieldAggregatedValues.max > currentLineFieldValue
              ? fieldAggregatedValues.max
              : currentLineFieldValue,
          min:
            fieldAggregatedValues.min < currentLineFieldValue
              ? fieldAggregatedValues.min
              : currentLineFieldValue,
        },
      };
    }, result);
  }, {});
}

/**
 * Merge table given table lines
 * @param {*} table
 */
export function mergeLines(table) {
  const { fields, lines } = table;
  return lines.reduce(
    (mergedLine, line) =>
      line.map((value, fieldIndex) => {
        const field = fields[fieldIndex];
        const cumulateFieldValues = getFieldCumulator(field);
        return cumulateFieldValues(value, mergedLine[fieldIndex]);
      }),
    [],
  );
}

/**
 * Computes a label for given tabe line
 * @param {*} line
 * @param {*} separator
 */
export function getReportLineLabel(line, separator = ' > ') {
  return line
    .map((val) => get(val, 'label', val))
    .filter(not(isNil))
    .join(separator);
}

/**
 * Reduces into given table to group lines values by field name
 * @param {*} table
 */
export const computeFieldsDistinctValues = (table) => {
  const { lines } = table;

  const uniqueValues = [];

  lines.forEach((line) => {
    line.forEach((value, index) => {
      // initializes uniqueValues[index] the first cycle.
      if (!uniqueValues[index]) uniqueValues[index] = [];
      // Adds the value to uniqueValues[index], but only if is not already inside.
      if (!uniqueValues[index].includes(value) && value !== null) {
        uniqueValues[index].push(value);
      }
    });
  });

  table.fields.forEach((field, index) => {
    field['index'] = index;
    // for the fields which values are objects, get rid of the duplicates.
    field['values'] = uniqWith(uniqueValues[index], isEqual);
  });

  return table;
};

/**
 * Reduces into given table to compute displayable columns
 * @param {*} table
 */
export const computeTableColumns = (table) => {
  const { fields, lines } = table;

  return {
    ...table,
    fieldsByName: denormalizeById(fields, 'name'),
    totalLines: lines.length,
  };
};
