import { PhoneNumber, UUID } from '../common';
import { Address } from '../address';
import { Entity } from '../types';

export enum CompanyFeature {
  Spend = 'spend_management',
  Reporting = 'reporting',
  AmexVirtualAccounts = 'virtual_card_management',
  Travel = 'neo_travel',
}

export type PurchaseFeature = 'neoTravel' | 'amazon' | 'spendRequest';

export enum CompanyPurchaseService {
  Amazon = 'amazon',
  Hotel = 'hotel',
}

export const COMPANY_FEATURES = [
  CompanyFeature.Spend,
  CompanyFeature.Reporting,
  CompanyFeature.AmexVirtualAccounts,
  CompanyFeature.Travel,
];

export enum CompanyStatus {
  ActivationInProgress = 'activation_in_progress',
  Active = 'active',
  PendingActivation = 'pending_activation',
  Locked = 'locked',
  DueDiligenceFailed = 'due_diligence_failed',
}

export enum CompanyKind {
  SoleTrader = 'sole_trader',
  LimitedCompany = 'limited_company',
  UnlimitedCompany = 'unlimited_company',
  Partnership = 'partnership',
  LimitedLiabilityPartnership = 'limited_liability_partnership',
  ClubOrAssociation = 'club_or_association',
  Society = 'society',
  SoleProprietorship = 'sole_proprietorship',
  LimitedLiabilityCompany = 'limited_liability_company',
  BusinessCorporation = 'business_corporation',
  Other = 'other',
}

export enum CompanyIndustry {
  AerospaceAndDefense = 'aerospace_and_defense',
  Agriculture = 'agriculture',
  AutomotiveAndTransport = 'automotive_and_transport',
  Banking = 'banking',
  Beverages = 'beverages',
  BusinessServices = 'business_services',
  CharitableOrganizations = 'charitable_organizations',
  Chemicals = 'chemicals',
  ComputerHardware = 'computer_hardware',
  ComputerServices = 'computer_services',
  ComputerSoftware = 'computer_software',
  Construction = 'construction',
  ConsumerProductsManufacturers = 'consumer_products_manufacturers',
  ConsumerServices = 'consumer_services',
  CulturalInstitutions = 'cultural_institutions',
  Education = 'education',
  Electronics = 'electronics',
  EnergyAndUtilities = 'energy_and_utilities',
  EnvironmentalServicesAndEquipment = 'environmental_services_and_equipment',
  FinancialServices = 'financial_services',
  Food = 'food',
  Foundations = 'foundations',
  government = 'government',
  Healthcare = 'healthcare',
  IndustrialManufacturing = 'industrial_manufacturing',
  Insurance = 'insurance',
  Leisure = 'leisure',
  Media = 'media',
  MembershipOrganizations = 'membership_organizations',
  MetalsAndMining = 'metals_and_mining',
  Pharmaceuticals = 'pharmaceuticals',
  RealEstate = 'real_estate',
  Retail = 'retail',
  SecurityProductsAndServices = 'security_products_and_services',
  TelecommunicationsEquipment = 'telecommunications_equipment',
  TelecommunicationsServices = 'telecommunications_services',
  TransportationServices = 'transportation_services',
}

export enum AccountingConfigStatus {
  OngoingSynchronization = 'ongoing_synchronization',
  OngoingTaxConfiguration = 'ongoing_tax_configuration',
  OngoingCashTxConfiguration = 'ongoing_cash_tx_configuration',
  OngoingCardTxConfiguration = 'ongoing_card_tx_configuration',
  CompletedCardTxConfiguration = 'completed_card_tx_configuration',
  NotStarted = 'not_started',
}
export enum CashAccountMappingPreferences {
  SingleVendor = 'single_vendor',
  UserMapping = 'user_mapping',
}
export enum CreditCardMappingPreferences {
  SingleVendor = 'single_vendor',
  MerchantAsVendor = 'merchant_as_vendor',
}
export enum UserMappingType {
  ExistingVendor = 'existing_vendor',
  NewVendor = 'new_vendor',
}
export type UserMapping = {
  userId: UUID;
  mapping: {
    mappingType: UserMappingType;
    vendorId: string;
  };
};
export type Vendor = {
  id: string;
  displayName: string;
};
export type UserVendor = {
  userId: UUID;
  vendorId: string;
};
export type AccountingConfig = {
  configStatus: AccountingConfigStatus;
  cardTxMappingPreferences: CreditCardMappingPreferences;
  singleCardTxVendorId: string;
  cashTxMappingPreferences: CashAccountMappingPreferences;
  singleCashTxVendorId: string;
};

export type CashTxVendorCreationResult = {
  failed: UUID[];
  created: UUID[];
  existing: UUID[];
};
export enum CompanyExtractService {
  Standard = 'standard',
  Xero = 'xero',
  QuickBooks = 'quickbooks',
  Sage = 'sage',
}

export const COMPANY_EXTERNAL_ACCOUNTING_LINKS: Array<CompanyExtractService> = [
  CompanyExtractService.Xero,
  CompanyExtractService.QuickBooks,
  CompanyExtractService.Sage,
];

export const COMPANY_PURCHASE_SERVICES: Array<CompanyPurchaseService> = [
  CompanyPurchaseService.Amazon,
];

interface CompanyLinks {
  parent: string | null;
  children: Array<string>;
}

export interface Company extends Entity {
  accountingLink: CompanyExtractService;
  accountingName: string;
  accountingCurrency: string;
  defaultCurrency: string;
  bankingIds: Array<string>;
  countryCode: string;
  purchaseServices: Array<string>;
  address?: Address;
  id: string;
  company_id: string;
  creation: Date;
  domain_id: string;
  domainId: string;
  features: Array<CompanyFeature>;
  lastUpdate: Date;
  links: CompanyLinks;
  name: string;
  normalizedName: string;
  contactEmail?: string;
  siblings: Array<string>;
  status: CompanyStatus;
  phoneNumber?: PhoneNumber;
  website?: string;
  fiscalPeriodStart: string;
  fiscalPeriodEnd: string;
  defaultDashboardLayout: string;
  travelContactPhone1: PhoneNumber;
  travelContactPhone2: PhoneNumber;
  whatsAppPhone: PhoneNumber;
  travelContactEmail: string;
}

export type CompanyData = Pick<
  Company,
  'id' | 'domainId' | 'name' | 'defaultCurrency'
>;

export type CompanyNumber = {
  value: string;
  type: CompanyNumberType;
};

export enum CompanyNumberType {
  RegistrationNumber = 'registration_number',
  Duns = 'duns',
  Ein = 'ein',
}
