import { Budget, UUID, CompanyUser, toCodingsPayload } from '../../../entities';
import { RpcCommand } from '../../utils';

type BudgetCoding = {
  segment_id: number;
  code: string;
};

export enum RejectBudgetReasons {
  'out_of_policy',
  'incorrect_financial_coding',
}

export enum RejectChangesReasons {
  'out_of_policy',
  'other',
}

export type RejectReasons = RejectBudgetReasons | RejectChangesReasons;

export type BudgetCodings = BudgetCoding[];

export type BudgetCreateParams = {
  owner_id: UUID;
  name: Budget['name'];
  max_amount: Budget['maxAmount'];
  currency: Budget['currency'];
  members: Budget['members'];
  end_date: Budget['endDate'];
  start_date: Budget['startDate'];
  codings: BudgetCodings;
  company_id: UUID;
  merchant_name: Budget['merchant'];
  order_number: Budget['orderNumber'];
};

export type VirtualBudgetCreateParams = {
  owner_id: UUID;
  name: string;
  max_amount: number;
  currency: string;
  members: string[];
  end_date: string;
  codings: BudgetCodings;
  company_id: string;
  merchant_name: string;
  order_number: string;
  virtual_card_id: UUID;
};

export type BudgetApproveParams = {
  budget_id: UUID;
  supervisor_id: string;
};

export type BudgetDeleteParams = {
  budget_id: UUID;
};

export type BudgetGetParams = {
  budget_id: UUID;
};

export type BudgetSubmitParams = {
  budget_id: UUID;
};

export type GetBudgetsParams = {
  user_id: UUID;
};

export type GetBudgetForSupervisionParams = {
  supervisor_id: UUID;
};

export type GetBudgetApprovedParams = {
  user_id: UUID;
};

export type BudgetRejectPayload = {
  budgetId: Budget['id'];
  supervisorId: CompanyUser['id'];
  reason: RejectReasons;
  comment: string;
};

export type BudgetRejectParams = {
  budget_id: Budget['id'];
  supervisor_id: CompanyUser['id'];
  reject_reason: RejectReasons;
  comment: string;
};

export type BudgetUpdateParams = {
  budget_id: UUID;
  name: Budget['name'];
  max_amount: Budget['maxAmount'];
  currency: Budget['currency'];
  members: Budget['members'];
  end_date: Budget['endDate'];
  start_date: Budget['startDate'];
  codings: BudgetCodings;
  merchant_name: Budget['merchant'];
  order_number: Budget['orderNumber'];
};

export type VirtualBudgetUpdateParams = {
  budget_id: UUID;
  name: Budget['name'];
  max_amount: Budget['maxAmount'];
  currency: Budget['currency'];
  members: Budget['members'];
  end_date: Budget['endDate'];
  codings: BudgetCodings;
  merchant_name: Budget['merchant'];
  order_number: Budget['orderNumber'];
  virtual_card_id: Budget['virtualCardId'];
};

export type BudgetRequestChangesPayload = {
  budgetId: Budget['id'];
  endDate: Budget['requestedChanges']['endDate'];
  additionalAmount: Budget['requestedChanges']['additionalAmount'];
  justification?: Budget['requestedChanges']['justification'];
};

export type BudgetRequestChangesParams = {
  budget_id: Budget['id'];
  new_end_date: Budget['requestedChanges']['endDate'];
  additional_amount: Budget['requestedChanges']['additionalAmount'];
  justification?: Budget['requestedChanges']['justification'];
};

export type BudgetApproveChangesPayload = {
  budgetId: Budget['id'];
  supervisorId: CompanyUser['id'];
};

export type BudgetApproveChangesParams = {
  budget_id: Budget['id'];
  supervisor_id: CompanyUser['id'];
};

export type BudgetRejectChangesPayload = {
  budgetId: Budget['id'];
  supervisorId: CompanyUser['id'];
  reason: RejectReasons;
  comment: string;
};

export type BudgetRejectChangesParams = {
  budget_id: Budget['id'];
  supervisor_id: CompanyUser['id'];
  reject_reason: RejectReasons;
  comment: string;
};

export type CloseBudgetPayload = {
  budgetId: Budget['id'];
  supervisorId: CompanyUser['id'];
  dryRun: boolean;
};

export type CloseBudgetParams = {
  budget_id: Budget['id'];
  user_id: CompanyUser['id'];
  dry_run: boolean;
};

export type GetBudgetLinkedTxsPayload = {
  budgetId: Budget['id'];
};

export type GetBudgetLinkedTxsParams = {
  budget_id: Budget['id'];
};

export type GetBudgetArchivedLinkedTxsPayload = {
  budgetId: Budget['id'];
};

export type GetBudgetArchivedLinkedTxsParams = {
  budget_id: Budget['id'];
};

export type GetVirtualBudgetHistoryPayload = {
  budgetId: Budget['id'];
};

export type GetVirtualBudgetHistoryParams = {
  budget_id: Budget['id'];
};

/**
 * Create a budget
 * @param budget
 */
export const newBudget = (budget: Budget): RpcCommand<BudgetCreateParams> => {
  return {
    method: 'new_standard_budget',
    params: {
      owner_id: budget.ownerId,
      company_id: budget.companyId,
      name: budget.name,
      max_amount: budget.maxAmount,
      currency: budget.currency,
      members: budget.members,
      end_date: budget.endDate,
      codings: toCodingsPayload(budget.codings),
      merchant_name: budget.merchant,
      order_number: budget.orderNumber,
      start_date: budget.startDate,
    },
  };
};

/**
 * Create a virtual budget
 * @param budget
 */
export const newVirtualBudget = (
  budget: Budget,
): RpcCommand<VirtualBudgetCreateParams> => {
  return {
    method: 'new_virtual_budget',
    params: {
      owner_id: budget.ownerId,
      company_id: budget.companyId,
      name: budget.name,
      max_amount: budget.maxAmount,
      currency: budget.currency,
      members: budget.members,
      end_date: budget.endDate,
      codings: toCodingsPayload(budget.codings),
      merchant_name: budget.merchant,
      order_number: budget.orderNumber,
      virtual_card_id: budget.virtualCardId,
    },
  };
};

/**
 * Updates a budget
 * @param budget
 */
export const updateBudget = (
  budget: Budget,
): RpcCommand<BudgetUpdateParams> => {
  return {
    method: 'update_standard_budget',
    params: {
      budget_id: budget.id,
      name: budget.name,
      max_amount: budget.maxAmount,
      currency: budget.currency,
      members: budget.members,
      end_date: budget.endDate,
      start_date: budget.startDate,
      codings: toCodingsPayload(budget.codings),
      merchant_name: budget.merchant,
      order_number: budget.orderNumber,
    },
  };
};

/**
 * Updates a budget
 * @param budget
 */
export const updateVirtualBudget = (
  budget: Budget,
): RpcCommand<VirtualBudgetUpdateParams> => {
  return {
    method: 'update_virtual_budget',
    params: {
      budget_id: budget.id,
      name: budget.name,
      max_amount: budget.maxAmount,
      currency: budget.currency,
      members: budget.members,
      end_date: budget.endDate,
      codings: toCodingsPayload(budget.codings),
      merchant_name: budget.merchant,
      order_number: budget.orderNumber,
      virtual_card_id: budget.virtualCardId,
    },
  };
};

export const requestBudgetChanges = ({
  budgetId,
  additionalAmount,
  endDate,
  justification,
}: BudgetRequestChangesPayload): RpcCommand<BudgetRequestChangesParams> => {
  return {
    method: 'request_virtual_budget_changes',
    params: {
      budget_id: budgetId,
      additional_amount: additionalAmount,
      new_end_date: endDate,
      justification,
    },
  };
};

export const approveBudgetChanges = ({
  budgetId,
  supervisorId,
}: BudgetApproveChangesPayload): RpcCommand<BudgetApproveChangesParams> => {
  return {
    method: 'approve_virtual_budget_changes',
    params: {
      budget_id: budgetId,
      supervisor_id: supervisorId,
    },
  };
};

export const rejectBudgetChanges = ({
  budgetId,
  supervisorId,
  reason,
  comment,
}: BudgetRejectChangesPayload): RpcCommand<BudgetRejectChangesParams> => {
  return {
    method: 'reject_virtual_budget_changes',
    params: {
      budget_id: budgetId,
      supervisor_id: supervisorId,
      reject_reason: reason,
      comment: comment,
    },
  };
};

/**
 * Approve a budget
 * @param budgetId
 * @param supervisorId
 */
export const approveBudget = (
  budgetId: Budget['id'],
  supervisorId: CompanyUser['id'],
): RpcCommand<BudgetApproveParams> => {
  return {
    method: 'approve_budget',
    params: {
      budget_id: budgetId,
      supervisor_id: supervisorId,
    },
  };
};

/**
 * Deletes Budget
 * @param budgetId
 */
export const deleteBudget = (
  budgetId: UUID,
): RpcCommand<BudgetDeleteParams> => {
  return {
    method: 'delete_budget',
    params: {
      budget_id: budgetId,
    },
  };
};

/**
 * Gets a Budget
 * @param budgetId
 */
export const getBudget = (budgetId: UUID): RpcCommand<BudgetGetParams> => {
  return {
    method: 'get_budget',
    params: {
      budget_id: budgetId,
    },
  };
};

/**
 * Gets all approved budgets for given user
 * @param userId
 */
export const getUserApprovedBudgets = (
  userId: UUID,
): RpcCommand<GetBudgetApprovedParams> => {
  return {
    method: 'get_approved_user_budgets',
    params: { user_id: userId },
  };
};

/**
 * Gets all budgets for supervision
 */
export const getBudgetsForSupervision = (
  userId: UUID,
): RpcCommand<GetBudgetForSupervisionParams> => ({
  method: 'get_budgets_for_supervision',
  params: {
    supervisor_id: userId,
  },
});

/**
 * Gets all budgets owned by user
 */
export const getUserBudgets = (userId: UUID): RpcCommand<GetBudgetsParams> => ({
  method: 'get_user_budgets',
  params: {
    user_id: userId,
  },
});

/**
 * Rejects a budget
 */
export const rejectBudget = ({
  budgetId,
  supervisorId,
  reason,
  comment,
}: BudgetRejectPayload): RpcCommand<BudgetRejectParams> => ({
  method: 'reject_budget',
  params: {
    budget_id: budgetId,
    supervisor_id: supervisorId,
    reject_reason: reason,
    comment,
  },
});

/**
 * Rejects a budget
 */
export const submitBudget = (
  budgetId: UUID,
): RpcCommand<BudgetSubmitParams> => ({
  method: 'submit_budget',
  params: {
    budget_id: budgetId,
  },
});

/**
 * Closes a budget: pass `dryRun` set to false to really close the budget:
 * if dryRun is true does not closes the budget, the response contains a report of the changes that would have been done to txs linked to the budget if the budget were closed.
 * if dry run is false, it does close the budget, the response contains a report of the changes done to the txs that were linked to the budget.
 */
export const closeBudget = ({
  budgetId,
  supervisorId,
  dryRun,
}: CloseBudgetPayload): RpcCommand<CloseBudgetParams> => ({
  method: 'close_budget',
  params: {
    budget_id: budgetId,
    user_id: supervisorId,
    dry_run: dryRun,
  },
});

/**
 * Get budget's linked Tx summaries
 */
export const getBudgetLinkedTxs = ({
  budgetId,
}: GetBudgetLinkedTxsPayload): RpcCommand<GetBudgetLinkedTxsParams> => ({
  method: 'get_budget_linked_transactions',
  params: { budget_id: budgetId },
});

/**
 * Get budget's archived linked Tx summaries
 */
export const getBudgetArchivedLinkedTxs = ({
  budgetId,
}: GetBudgetArchivedLinkedTxsPayload): RpcCommand<GetBudgetArchivedLinkedTxsParams> => ({
  method: 'get_budget_archived_linked_transactions',
  params: { budget_id: budgetId },
});

/**
 * Get virtual budget's history
 */
export const getVirtualBudgetHistory = ({
  budgetId,
}: GetVirtualBudgetHistoryPayload): RpcCommand<GetVirtualBudgetHistoryParams> => ({
  method: 'get_virtual_budget_history',
  params: { budget_id: budgetId },
});
