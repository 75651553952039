import { reify } from '../normalize';
import { Delegation } from './types';

export const DelegationEntityName = 'Delegation';

export const normalizeDelegation = (rawData: any): Delegation => {
  const data = reify(rawData);

  return {
    entityType: DelegationEntityName,
    id: data.getUuid('delegation_id'),
    userId: data.getUuid('user_id'),
    userName: data.getString('user_name'),
    recipientId: data.getUuid('recipient_id'),
    recipientName: data.getString('recipient_name'),
    startDate: data.getLocalDateFromUTC('start_date'),
    endDate: data.getLocalDateFromUTC('end_date'),
    startTime: data.getLocalTimeFromUTC('start_date'),
    endTime: data.getLocalTimeFromUTC('end_date'),
  };
};
