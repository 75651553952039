import partial from 'lodash/partial';
import isNil from 'lodash/isNil';
import { hasFeature as hasCompanyFeature } from '../../companies/utils';
import {
  CompanyUser,
  CompanyGroupUser,
  CompanyUserRole,
  User,
  DomainUser,
  SystemUser,
  UserLevel,
} from '@neo1/client/lib/entities/user';
import { isUUID } from '@neo1/client/lib/entities/common/strings';
import { Company } from '@neo1/client/lib/entities/company';

export function getDisplayName(user: User | CompanyGroupUser) {
  return user.displayName;
}

export function isCompanyUser(
  user: User | CompanyGroupUser,
): user is CompanyUser {
  return user.level === UserLevel.COMPANY && 'latestAcceptedEula' in user;
}

export function isCompanyGroupUser(
  user: User | CompanyGroupUser,
): user is CompanyGroupUser {
  return user.level === UserLevel.COMPANY && 'companyName' in user;
}

export function hasCompanyRole(user: User, role: CompanyUserRole) {
  return isCompanyUser(user) && user.roles.includes(role);
}

export function isDomainUser(user: User): user is DomainUser {
  return user.level === UserLevel.DOMAIN;
}
export function isDomainUserLoginAsCompany(user: User): user is CompanyUser {
  return isCompanyUser(user) && user.freezed;
}

export function rolesContainsAdminRole(roles: CompanyUser['roles']) {
  return Array.isArray(roles) && roles.indexOf(CompanyUserRole.Admin) > -1;
}

export function isAdmin(user: User) {
  return isCompanyUser(user) && rolesContainsAdminRole(user.roles);
}

export function isCompanyAdmin(user: User) {
  return isCompanyUser(user) && isAdmin(user);
}

export function isCompanyFinance(user: User) {
  return isCompanyUser(user) && isFinance(user);
}

export function isFinance(user: CompanyUser) {
  return (
    Array.isArray(user.roles) &&
    user.roles.indexOf(CompanyUserRole.Finance) > -1
  );
}

export function isArranger(user: User) {
  return (
    isCompanyUser(user) &&
    Array.isArray(user.roles) &&
    user.roles.indexOf(CompanyUserRole.Arranger) > -1
  );
}

export function isSystemUser(user: User): user is SystemUser {
  return user.level === UserLevel.SYSTEM;
}

function hasLevel(user: User, level: UserLevel) {
  return user && user.level === level;
}

export function isSuperUser(user: User): user is SystemUser | DomainUser {
  return isDomainUser(user) || isSystemUser(user);
}

export function hasSupervisor(user: User) {
  return isCompanyUser(user) && isUUID(user.supervisorId);
}

export const canCreateDomain = isSystemUser;

export function canCreateCompany(user: User) {
  return isDomainUser(user) || isSystemUser(user);
}

export function actingUserHasRightRoles(
  userRoles: CompanyUserRole[] = [],
  actingUser: CompanyUser,
) {
  return userRoles.some(partial(hasCompanyRole, actingUser));
}

export function actingUserHasRightLevel(
  userLevels: UserLevel[] = [],
  actingUser: User,
) {
  return userLevels.some(partial(hasLevel, actingUser));
}

export function actingCompanyHasRightFeatures(
  companyFeatures: string[],
  actingCompany: Company,
) {
  return (
    !Array.isArray(companyFeatures) ||
    companyFeatures.some(partial(hasCompanyFeature, actingCompany))
  );
}

export function isUserProfileCompleteForNeoTravel(user: CompanyUser) {
  return !isNil(user.birthDate) && !isNil(user.phone);
}
