import { UUID } from '@neo1/client';
import { AccountSummary } from '@neo1/core/modules/settings/accountSummary/types';

export enum Actions {
  SET_ACCOUNTS_SUMMARY = 'one/settings/paymentMethods/SET_ACCOUNTS_SUMMARY',
  SET_ACCOUNT_INSTRUMENT = 'one/settings/paymentMethods/SET_ACCOUNT_INSTRUMENT',
  SET_ACCOUNT_INSTRUMENTS = 'one/settings/paymentMethods/SET_ACCOUNT_INSTRUMENTS',
}

export function setAccountsSummary({
  accountsSummary,
}: {
  companyId: string;
  accountsSummary: AccountSummary[];
}) {
  return {
    type: Actions.SET_ACCOUNTS_SUMMARY,
    accountsSummary,
  };
}

export const setAccountInstrument = (id: UUID) => ({
  type: Actions.SET_ACCOUNT_INSTRUMENT,
  id,
});

export function setAccountInstruments(ids: UUID[]) {
  return {
    type: Actions.SET_ACCOUNT_INSTRUMENTS,
    ids,
  };
}

export type ActionsType =
  | {
      type: Actions.SET_ACCOUNTS_SUMMARY;
      accountsSummary: AccountSummary[];
    }
  | {
      type: Actions.SET_ACCOUNT_INSTRUMENTS;
      ids: UUID[];
    }
  | {
      type: Actions.SET_ACCOUNT_INSTRUMENT;
      id: UUID;
    };
