import { AppConfigReferentialData } from '@neo1/core/modules/app/types';

export enum ReferentialType {
  LoadReferentialOk = 'one/app/referential/LOAD_REFERENTIAL_OK',
}

export const loadReferentialSuccess = (data: AppConfigReferentialData) => ({
  type: ReferentialType.LoadReferentialOk,
  data,
});

export type ReferentialActionType = {
  type: ReferentialType.LoadReferentialOk;
  data: AppConfigReferentialData;
};
