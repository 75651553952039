import { Company, CompanyStatus } from '@neo1/client/lib/entities/company';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export function isCompanyRestricted(company: Company) {
  return !company || company.status !== CompanyStatus.Active;
}

export function getChildrenIds(company: Company) {
  return get(company, 'links.children', []);
}

export function hasChildren(company: Company) {
  const childrenIds = getChildrenIds(company);
  return childrenIds.length > 0;
}

export function hasParent(company: Company) {
  const parentCompanyId = get(company, 'links.parent');
  return !isNil(parentCompanyId);
}

export function isChildOf(a: Company) {
  return function (b: Company) {
    const bParentId = get(b, 'links.parent');
    return bParentId === a.company_id;
  };
}

export function isChildOrParent(company: Company) {
  return hasChildren(company) || hasParent(company);
}

export function getCompanyName(companies: Company[], companyId: string) {
  const companyMatch = companies.find((company) => company.id === companyId);

  return companyMatch && companyMatch.name;
}

export function isOrganizationRoot(company: Company): boolean {
  return !hasParent(company) && hasChildren(company);
}
