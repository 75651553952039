import { Entity } from '../types';
import { reify } from '../normalize';
import { User, Company } from '@neo1/client';
import { UUID } from '../common';

export type TripSegment = {
  type: string;
  status: string;
};

export enum TripStatus {
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  HOLDING = 'holding',
  WAITLISTED = 'waitlisted',
}

export enum TripSegmentTypes {
  AIR = 'air',
  CAR = 'car',
  HOTEL = 'hotel',
  RAIL = 'rail',
  GROUND = 'ground',
}
export const UserTripEntityName = 'UserTrip';
export interface CurrentUserTrip extends Entity {
  startDate: string;
  endDate: string;
  lastRefresh: string;
  id: UUID;
  userId: UUID;
  traveler: string;
  pnr: string;
  name: string;
  segments: TripSegment[];
  deepLinkId: string | null;
}

export function normalizeTrip(rawData: any): CurrentUserTrip {
  const data = reify(rawData);

  return {
    entityType: UserTripEntityName,
    startDate: data.getString('start_date_time'),
    endDate: data.getString('end_date_time'),
    deepLinkId: data.getString('deep_link_id', null),
    lastRefresh: data.getString('last_refresh'),
    id: data.getUuid('trip_id'),
    userId: data.getUuid('user_id'),
    traveler: data.getString('traveler'),
    pnr: data.getString('pnr'),
    name: data.getString('name'),
    segments: data.getArray<TripSegment>('segments', normalizeTripSegments),
  };
}

export function normalizeTripSegments(rawData: any): TripSegment {
  const data = reify(rawData);

  return {
    type: data.getString('type'),
    status: data.getString('status'),
  };
}

export type GetUserTripsParams = {
  user_id: User['id'];
};

export type GetHostedTripsParams = {
  host_id: User['id'];
};

export type GetTeamTripParams = {
  supervisor_id: User['id'];
};

export type GetCompanyTripsParams = {
  company_id: Company['id'];
};

/**
 * Asserts if supply is owned by given user
 * @param user
 */
export const isTripOwnedBy = (user: User) => (trip: CurrentUserTrip) =>
  user.id === trip.userId;
