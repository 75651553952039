import { Country, CountryState } from './entities';

export function isActiveCountry(country: Country) {
  return country.active;
}

export function hasStates(country: Country) {
  return country?.states?.length > 0;
}

export function matchesCountryStateCode(code: string) {
  return (state: CountryState): boolean => state.code === code;
}
