import { useRef, useEffect, FC, ReactNode } from 'react';
import FocusTrap from 'focus-trap-react';
import classNames from 'classnames';
import { isDark } from '@neo1/core/utils';
import { getConfigValue } from 'config';
import Portal from 'components/composed/Portal';
import Button, {
  ButtonKind,
  ButtonSize,
  ButtonTheme,
} from 'components/elements/Button';
import { mdWidth, useWindowContext } from 'contexts/window';

import styles from './styles.module.css';

export type ModalProps = {
  children: ReactNode;
  id?: string;
  className?: string;
  onClose?: () => void;
  title?: string | ReactNode;
  contentClassName?: string;
  footer?: ReactNode;
  showHeader?: boolean;
  canExpand?: boolean;
  isOpen?: boolean;
  error?: string;
  // todo
  // passing a wrapper component like this with its props (which could be whatever) is not a good idea
  // especially may have impacts on styles
  // the only use of these props is for Modal form for the moment
  // first of all dont use these props for other components
  // and need to find another way to handle this for Modal form
  wrapperComponent?: FC;
  wrapperProps?: object;
  // end todo
  withBackground?: boolean;
  showCloseBtnOnly?: boolean;
  testId?: string;
};

const Modal = ({
  id,
  className,
  isOpen,
  children,
  title,
  footer,
  showHeader,
  canExpand,
  contentClassName,
  onClose,
  error,
  wrapperComponent: Wrapper,
  wrapperProps,
  withBackground,
  showCloseBtnOnly,
  testId,
}: ModalProps) => {
  // When a error happens, we scroll to top, so the user can see the error.
  const containerRef = useRef(null);
  const { winWidth } = useWindowContext();
  const headerWithDarkBg = winWidth <= mdWidth || !showCloseBtnOnly;

  useEffect(() => {
    if (containerRef?.current?.scrollTo) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error]);

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Portal element={document.body}>
      <section
        className={classNames(
          styles.container,
          !isDark(getConfigValue('branding')?.primaryColor) && styles.dark,
        )}
        data-testid="modalWrapper"
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Wrapper {...wrapperProps}>
          {withBackground && (
            /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */
            <div
              className={styles.overlay}
              onClick={handleOverlayClick}
              data-testid="modalOverlay"
            />
          )}
          <FocusTrap
            focusTrapOptions={{
              fallbackFocus: `div[role='dialog']`,
              clickOutsideDeactivates: true,
            }}
          >
            <div
              id={id}
              role="dialog"
              className={classNames(className, styles.modal, {
                [styles.canExpand]: canExpand,
              })}
              data-testid={testId}
            >
              {showHeader && (
                <header
                  className={classNames(
                    styles.header,
                    { [styles.withCloseBtnOnly]: showCloseBtnOnly },
                    { [styles.withoutTitle]: !title },
                  )}
                >
                  {title && (
                    <h3 className={styles.title} data-testid="modalTitle">
                      {title}
                    </h3>
                  )}
                  {onClose && (
                    <Button
                      label="Close modal"
                      iconName="close"
                      iconOnly
                      onClick={onClose}
                      theme={ButtonTheme.Discrete}
                      size={ButtonSize.Small}
                      kind={ButtonKind.Neutral}
                      dark={headerWithDarkBg}
                      data-testid={`${testId}-closeBtn`}
                    />
                  )}
                </header>
              )}
              <main
                ref={containerRef}
                className={classNames(styles.content, contentClassName)}
                data-testid="modalBody"
              >
                {children}
              </main>
              {footer && <footer className={styles.footer}>{footer}</footer>}
            </div>
          </FocusTrap>
        </Wrapper>
      </section>
    </Portal>
  );
};

Modal.defaultProps = {
  id: undefined,
  className: undefined,
  onClose: undefined,
  title: undefined,
  contentClassName: undefined,
  footer: undefined,
  showHeader: true,
  canExpand: false,
  isOpen: false,
  error: undefined,
  wrapperComponent: ({ children }) => children,
  wrapperProps: {},
  withBackground: true,
  showCloseBtnOnly: false,
  testId: 'modal',
};

export default Modal;
