import classNames from 'classnames';
import { ChangeEvent } from 'react';
import styles from './RadioButtonInput.module.css';

type Props = {
  checked: boolean;
  name: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  id?: string;
};
function RadioButtonInput({
  checked,
  disabled,
  readOnly,
  name,
  value,
  onChange,
  className,
  id,
}: Props) {
  const testId = `${name}-${value}`;
  return (
    <div className={classNames(styles.container, className)}>
      <input
        data-testid={testId}
        id={id}
        onChange={onChange}
        className={classNames(
          styles.label,
          {
            [styles.labelChecked]: checked,
            [styles.disabled]: disabled,
          },
          styles.input,
        )}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
        tabIndex={0}
      />
      <div
        className={classNames(styles.label, {
          [styles.labelChecked]: checked,
          [styles.disabled]: disabled,
        })}
      >
        <div className={styles.dotContainer}>
          <div className={styles.dot} />
        </div>
      </div>
    </div>
  );
}

RadioButtonInput.defaultProps = {
  onChange: undefined,
  disabled: false,
  readOnly: false,
  className: undefined,
  id: undefined,
};

export default RadioButtonInput;
