import not from 'lodash/negate';
import wrapAsync from 'utils/wrapAsync';
import { isReportingOnly } from 'config/routes/utils';
import { ROUTE_NAME_HOME, ROUTE_PATH_HOME } from './constants';

const dashboardRoute = {
  path: ROUTE_PATH_HOME,
  name: ROUTE_NAME_HOME,
  exact: true,
  component: wrapAsync(
    () =>
      import(
        /* webpackChunkName: "dashboard" */
        '.'
      ),
  ),
  menu: {
    iconClass: 'home',
    show: not(isReportingOnly),
  },
};

export default dashboardRoute;
