import {
  CompanyActivationOwner,
  CompanyActivationData,
} from '@neo1/core/modules/persisted/types';
import { enumToLabel } from '@neo1/core/utils';
import { CompanyIndustry, CompanyKind } from '@neo1/client';

export type EditedOwnerIndex = 0 | 1 | 2 | 3 | null;

export enum CompanyActivationStep {
  GetStarted,
  About,
  Address,
  Owners,
  Neo1Features,
  Finish,
}

export interface InjectedActivationStepProps {
  onClose: (values: CompanyActivationData) => void;
}

export type CompanyActivationStepProps = InjectedActivationStepProps;

export const INITIAL_OWNER_DATA: CompanyActivationOwner = {
  firstName: '',
  lastName: '',
  birthDate: '',
  countryOfBirth: '',
  country: '',
  street: '',
  zipCode: '',
  state: '',
  city: '',
};

export const INDUSTRIES_OPTIONS = Object.entries(CompanyIndustry).map(
  ([_, industry]) => ({
    label: enumToLabel(industry),
    value: industry,
  }),
);

export const EMPLOYEES_COUNT_OPTIONS = [
  {
    label: 'Less than 10',
    value: 9,
  },
  {
    label: '10 - 49',
    value: 48,
  },
  {
    label: '50 - 99',
    value: 98,
  },
  {
    label: '100 - 249',
    value: 248,
  },
  {
    label: '250 - 999',
    value: 998,
  },
  {
    label: 'More than 1000',
    value: 1001,
  },
];

/**
 * This list represents company/business kind enum values and the country codes
 * for which they work
 */
export const COMPANY_KIND_OPTIONS = [
  { value: CompanyKind.LimitedCompany, countryCodes: ['GB'] },
  { value: CompanyKind.SoleTrader, countryCodes: ['GB'] },
  { value: CompanyKind.UnlimitedCompany, countryCodes: ['GB'] },
  { value: CompanyKind.Partnership, countryCodes: ['GB'] },
  { value: CompanyKind.LimitedLiabilityPartnership, countryCodes: ['GB'] },
  { value: CompanyKind.ClubOrAssociation, countryCodes: ['GB'] },
  { value: CompanyKind.Society, countryCodes: ['GB'] },
  { value: CompanyKind.SoleProprietorship, countryCodes: ['US'] },
  { value: CompanyKind.Partnership, countryCodes: ['US'] },
  { value: CompanyKind.LimitedLiabilityCompany, countryCodes: ['US'] },
  { value: CompanyKind.BusinessCorporation, countryCodes: ['US'] },
  { value: CompanyKind.Other, countryCodes: ['GB'] },
];

export const ACTIVATION_STEPS = [
  CompanyActivationStep.About,
  CompanyActivationStep.Address,
  CompanyActivationStep.Owners,
  CompanyActivationStep.Neo1Features,
  CompanyActivationStep.Finish,
];
