import { Children, cloneElement } from 'react';
import classNames from 'classnames';

import RadioGroupItem from './Item';

import styles from './RadioGroup.module.css';

type Props = {
  name?: string;
  onChange?: (value: any) => void;
  value?: any;
  children: any;
  disabled?: boolean;
  className?: string;
};

function RadioGroup({
  children,
  onChange,
  disabled,
  name,
  value,
  className,
}: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      {Children.map(children, (child) => {
        if (!child) {
          // if the child is nullish, just return null.
          return null;
        }
        return cloneElement(child, {
          name,
          onChange,
          disabled,
          checked: value === child.props.value,
        });
      })}
    </div>
  );
}

RadioGroup.defaultProps = {
  name: undefined,
  onChange: undefined,
  value: undefined,
  disabled: false,
  className: undefined,
};

RadioGroup.Item = RadioGroupItem;

export default RadioGroup;
