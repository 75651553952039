import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import range from 'lodash/range';
import { CompanyActivationData } from '@neo1/core/modules/persisted/types';
import ToggleSwitch from 'components/elements/form/fields/ToggleSwitch';
import Button, { ButtonTheme } from 'components/elements/Button';
import { useAmplitude, Events } from 'contexts/instrumentation';
import {
  INITIAL_OWNER_DATA,
  useValidateStep,
  isEditingOwner as isEditingOwnerUtil,
} from '../../utils';
import OwnerDetails from './OwnerDetails';
import EditOwner from './EditOwner';
import messages from './messages';
import wording from '../../messages';
import TipBox from '../../TipBox';
import styles from '../../ActivateCompany.module.css';

function CompanyOwners() {
  const {
    isSubmitting,
    values: { hasOwners, dismissedTips, editedOwnerIndex, owners },
    setFieldValue,
  } = useFormikContext<CompanyActivationData>();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent({ event: Events.CompanyOwnersViewed });
  }, []);

  const { isValidOwner } = useValidateStep();

  /**
   * When the user stops editing an owner, we should remove the owners that are not valid
   */
  useEffect(() => {
    if (!isNumber(editedOwnerIndex)) {
      setFieldValue('owners', owners?.filter(isValidOwner));
    }
  }, [editedOwnerIndex]);

  const isEditingOwner = !isNil(editedOwnerIndex) && hasOwners;
  const isTipDismissed = dismissedTips?.includes('owners');

  const onCancelEditOwner = () => setFieldValue('editedOwnerIndex', null);
  const onSetHasOwners = (newHasOwners: boolean) => {
    setFieldValue('hasOwners', newHasOwners);

    logEvent({
      event: Events.CompanyHasOwnersSwitchClicked,
      data: { value: !hasOwners },
    });
  };

  function onEditOwner(index: number) {
    setFieldValue('editedOwnerIndex', index);
  }

  const onDismissTip = async () => {
    setFieldValue('dismissedTipsField', dismissedTips.concat('owners'));
  };

  function renderOwnersSlots() {
    return (
      <FieldArray
        name="owners"
        render={(arrayHelpers: FieldArrayRenderProps) => {
          const onAddOwner = () => {
            const nextOwnerId = owners.length;
            arrayHelpers.insert(nextOwnerId, { ...INITIAL_OWNER_DATA });
            setFieldValue('editedOwnerIndex', nextOwnerId);
          };

          const onRemoveOwner = (index: number = 0) => {
            if (!isNil(owners[index])) {
              arrayHelpers.remove(index);
            }
          };

          return range(0, 4).map((index) => {
            const owner = owners[index];

            if (owner) {
              return (
                <OwnerDetails
                  owner={owner}
                  no={index + 1}
                  className={styles.ownerBlock}
                  onEdit={() => onEditOwner(index)}
                  onDelete={() => onRemoveOwner(index)}
                  disabled={isSubmitting}
                />
              );
            }

            return (
              <div className={classNames(styles.ownerBlock, styles.empty)}>
                {index === owners.length && (
                  <FormattedMessage
                    id={messages.btnAddOwner.id}
                    defaultMessage={messages.btnAddOwner.defaultMessage}
                  >
                    {(btnTitle) => (
                      <Button
                        label={btnTitle.toString()}
                        iconName="plusCircle"
                        onClick={onAddOwner}
                        disabled={isEditingOwner || !hasOwners}
                        loading={isSubmitting}
                        theme={ButtonTheme.Outlined}
                      />
                    )}
                  </FormattedMessage>
                )}
              </div>
            );
          });
        }}
      />
    );
  }

  function renderOwnersList() {
    return (
      <>
        {!isTipDismissed && (
          <TipBox message="owners" onDismiss={onDismissTip} />
        )}

        <main className={styles.formMain}>
          <FormattedMessage
            id={messages.title.id}
            defaultMessage={messages.title.defaultMessage}
            tagName="h4"
          />
          <section className={styles.formChoice}>
            <ToggleSwitch
              disabled={isSubmitting}
              onToggle={() => onSetHasOwners(!hasOwners)}
              isOn={hasOwners}
            />
          </section>
          {hasOwners && renderOwnersSlots()}
        </main>
      </>
    );
  }

  function renderEditOwner() {
    const editedOwner = owners[editedOwnerIndex];

    if (!editedOwner) {
      return null;
    }

    return (
      <EditOwner
        owner={editedOwner}
        onClickBack={onCancelEditOwner}
        index={editedOwnerIndex}
      />
    );
  }

  return isEditingOwner ? renderEditOwner() : renderOwnersList();
}
export const OwnersActions = ({
  className,
  onGoBack,
  isSubmitDisabled,
  isShowingOwnerValidationResult,
  onBackOwnerAddressValidation,
}: {
  className: string;
  onGoBack: () => any;
  isSubmitDisabled: boolean;
  isShowingOwnerValidationResult: boolean;
  onBackOwnerAddressValidation: () => void;
}) => {
  const { isSubmitting, values } = useFormikContext<CompanyActivationData>();
  const intl = useIntl();

  const isDisabled =
    (isEditingOwnerUtil(values) && !isShowingOwnerValidationResult) ||
    isSubmitting;

  return (
    <footer className={className}>
      <Button
        label="Back"
        theme={ButtonTheme.Ghost}
        iconName="left"
        id="backButton"
        onClick={
          isShowingOwnerValidationResult
            ? onBackOwnerAddressValidation
            : onGoBack
        }
        disabled={isDisabled}
      />
      <Button
        label={
          isShowingOwnerValidationResult
            ? 'Confirm'
            : intl.formatMessage(wording.footer.btnNext)
        }
        type="submit"
        id="btnContinue"
        disabled={isDisabled || isSubmitDisabled}
        loading={isSubmitting}
      >
        {intl.formatMessage(wording.footer.btnNext)}
      </Button>
    </footer>
  );
};

export default CompanyOwners;
