import { fileServiceUrl } from '../../utils/files/service';
import { SortTypes } from './constants';

let config = {
  apiFilesBaseUrl: '',
  localesBaseUrl: '/locales',
};

export function getPostCompanyImportFileUrl(companyId: string) {
  return fileServiceUrl(
    `${config.apiFilesBaseUrl}/excel-upload/company/${companyId}`,
  );
}

export function getPostDomainImportFilesUrl(domainId: string) {
  return fileServiceUrl(
    `${config.apiFilesBaseUrl}/excel-upload/domain/${domainId}`,
  );
}

export function getCompanyAccountExtractFileUrl(
  companyId: string,
  accountId: string,
  fileName?: string,
) {
  let endpointUrl = `${config.apiFilesBaseUrl}/extract/${companyId}/${accountId}`;

  if (fileName) {
    endpointUrl = `${endpointUrl}/${fileName}`;
  }

  return fileServiceUrl(endpointUrl);
}

/**
 * Gets i18n Messages file url
 * @param locale
 */
export function getMessagesFileUrl(locale: string) {
  return `${config.localesBaseUrl}/${locale}.json`;
}

export function configure(newConfig: any) {
  return (config = {
    ...config,
    ...newConfig,
  });
}

export function isSortTypes(s: string): s is SortTypes {
  return s === SortTypes.ASC || s === SortTypes.DESC;
}
