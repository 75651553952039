import { ConfigType as DateInput } from 'dayjs';
import { prettyAndShortDateTime } from './dates';

/**
 * Formats a boolean to a nice text
 * @param val
 */
export const formatBoolean = (val: boolean) => (val ? 'Yes' : 'No');

/**
 * Displays valIfTrue when true
 * @param valIfTrue
 * @param valIfFalse
 */
export const formatBooleanText =
  (valIfTrue = 'Yes', valIfFalse = 'No') =>
  (val: boolean) =>
    val ? valIfTrue : valIfFalse;

/**
 * Formats datetime to a pretty and short text
 * @param date
 */
export const prettyDateTime = (date: DateInput) =>
  `${prettyAndShortDateTime(date)}`;
