import { UUID, TravelReceipt } from '@neo1/client';
import { CollectionFilters } from '@neo1/core/utils';

export type State = {
  ids: UUID[];
};

export enum Actions {
  SET_RECEIPTS = 'one/receipt/travel/SET_RECEIPTS',
}

export type SetReceipts = {
  type: typeof Actions.SET_RECEIPTS;
  ids: UUID[];
};

export type Action = SetReceipts;

type NullableDate = string | null | undefined;

export type Options = {
  start: NullableDate;
  end: NullableDate;
  merchants: string[];
  travelers: string[];
  pnrs: string[];
};

export type Filters = CollectionFilters<TravelReceipt, Options>;
