import { useEffect, ClassType } from 'react';
import { useIntl } from 'react-intl';
import dayjs, { ConfigType as DateInput } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';

import {
  API_DATE_FORMAT_DEFAULT,
  DISPLAY_DATE_TIME_FORMAT_DEFAULT,
  DISPLAY_DATE_FORMAT_DEFAULT,
  INPUT_DATE_FORMAT_DEFAULT,
  DAYJS_LOCALE_DEFAULT,
  INPUT_DATE_FORMAT_LABEL_DEFAULT,
  INPUT_YEAR_PLACEHOLDER_DEFAULT,
  INPUT_MONTH_PLACEHOLDER_DEFAULT,
  INPUT_DAY_PLACEHOLDER_DEFAULT,
  DISPLAY_MONTH_FORMAT_DEFAULT,
  DISPLAY_DATE_WITHOUT_YEAR_FORMAT_DEFAULT,
} from '@neo1/core/i18n/utils';

dayjs.extend(localeData);
dayjs.extend(utc);

export type DateUtils = {
  displayDateTimeFormat: string;
  displayDateFormat: string;
  inputDateFormat: string;
  inputDateFormatLabel: string;
  inputYearPlaceholder: string;
  inputMonthPlaceholder: string;
  inputDayPlaceholder: string;
  apiDateFormat: string;
  formatDisplayDateTime: (date: DateInput, utc?: boolean) => string;
  formatDisplayDate: (date: DateInput, utc?: boolean) => string;
  formatDisplayDateWithoutYear: (date: DateInput, utc?: boolean) => string;
  formatDisplayMonth: (date: DateInput) => string;
  formatApiDate: (date: DateInput) => string;
};

// a custom hook to handle date formatting for function components
export const useDateUtils = (): DateUtils => {
  const intl = useIntl();

  if (!intl) {
    throw new Error(
      'useDateUtils must be used inside in a child of IntlProvider',
    );
  }

  const dayjsLocale = intl.formatMessage({
    id: 'DAYJS_LOCALE',
    description: 'Day.js parent locale',
    defaultMessage: DAYJS_LOCALE_DEFAULT,
  });

  useEffect(() => {
    import(`dayjs/locale/${dayjsLocale}.js`).then(() => {
      dayjs.locale(dayjsLocale);
    });
  }, [dayjsLocale]);

  const apiDateFormat = API_DATE_FORMAT_DEFAULT;

  const displayDateTimeFormat = intl.formatMessage({
    id: 'DISPLAY_DATE_TIME_FORMAT',
    description: 'Display datetime format',
    defaultMessage: DISPLAY_DATE_TIME_FORMAT_DEFAULT,
  });

  const displayDateFormat = intl.formatMessage({
    id: 'DISPLAY_DATE_FORMAT',
    description: 'Display date format',
    defaultMessage: DISPLAY_DATE_FORMAT_DEFAULT,
  });

  const displayDateWithoutYearFormat = intl.formatMessage({
    id: 'DISPLAY_DATE_WITHOUT_YEAR_FORMAT',
    description: 'Display date format',
    defaultMessage: DISPLAY_DATE_WITHOUT_YEAR_FORMAT_DEFAULT,
  });

  const displayMonthFormat = intl.formatMessage({
    id: 'DISPLAY_MONTH_FORMAT',
    description: 'Display date format',
    defaultMessage: DISPLAY_MONTH_FORMAT_DEFAULT,
  });

  const inputDateFormat = intl.formatMessage({
    id: 'INPUT_DATE_FORMAT',
    description: 'Input date format',
    defaultMessage: INPUT_DATE_FORMAT_DEFAULT,
  });

  const inputDateFormatLabel = intl.formatMessage({
    id: 'INPUT_DATE_FORMAT_LABEL',
    description: 'Input date format label',
    defaultMessage: INPUT_DATE_FORMAT_LABEL_DEFAULT,
  });

  const inputYearPlaceholder = intl.formatMessage({
    id: 'INPUT_YEAR_PLACEHOLDER',
    description: 'Input year placeholder',
    defaultMessage: INPUT_YEAR_PLACEHOLDER_DEFAULT,
  });

  const inputMonthPlaceholder = intl.formatMessage({
    id: 'INPUT_MONTH_PLACEHOLDER',
    description: 'Input year placeholder',
    defaultMessage: INPUT_MONTH_PLACEHOLDER_DEFAULT,
  });

  const inputDayPlaceholder = intl.formatMessage({
    id: 'INPUT_DAY_PLACEHOLDER',
    description: 'Input year placeholder',
    defaultMessage: INPUT_DAY_PLACEHOLDER_DEFAULT,
  });

  const formatDate = ({
    date,
    format,
    withUtc = false,
  }: {
    date: DateInput;
    format: string;
    withUtc?: boolean;
  }) => {
    const dayjsDate = withUtc ? dayjs(date).utc() : dayjs(date);
    return `${dayjsDate.format(format)}${withUtc ? ' UTC' : ''}`;
  };

  const formatDisplayDateTime = (date: DateInput, withUtc: boolean = false) =>
    formatDate({ date, format: displayDateTimeFormat, withUtc });

  const formatDisplayDate = (date: DateInput, withUtc: boolean = false) =>
    formatDate({ date, format: displayDateFormat, withUtc });

  const formatDisplayDateWithoutYear = (
    date: DateInput,
    withUtc: boolean = false,
  ) => formatDate({ date, format: displayDateWithoutYearFormat, withUtc });

  const formatDisplayMonth = (date: DateInput) =>
    formatDate({ date, format: displayMonthFormat });

  const formatApiDate = (date: DateInput) => {
    // Force format year on 4-digits due to a bug: https://github.com/iamkun/dayjs/issues/1745
    const formattedDate = formatDate({ date, format: apiDateFormat });
    const [year, month, day] = formattedDate.split('-');
    return `${year.padStart(4, '0')}-${month}-${day}`;
  };

  return {
    displayDateTimeFormat,
    displayDateFormat,
    inputDateFormat,
    inputDateFormatLabel,
    inputYearPlaceholder,
    inputMonthPlaceholder,
    inputDayPlaceholder,
    apiDateFormat,
    formatDisplayDateTime,
    formatDisplayDate,
    formatDisplayDateWithoutYear,
    formatDisplayMonth,
    formatApiDate,
  };
};

// a HOC to handle date formatting for class components
export const withDateUtils =
  (Component: ClassType<any, any, any>) => (props: any) => {
    const dateUtils = useDateUtils();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component dateUtils={dateUtils} {...props} />;
  };
