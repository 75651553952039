import camelCase from 'lodash/camelCase';
import isString from 'lodash/isString';
import get from 'lodash/get';
import { RpcErrorCode } from '@neo1/client/lib/rpc/utils';
import { isBlankString } from './strings';

const API_ERRORS_WITH_FIELDNAME = [
  '^null value for key `([a-zA-Z_]+)` is not allowed',
  '^Value `[^`]+` for key `([a-zA-Z_]+)` is invalid',
  'You cannot reset your ([a-zA-Z_]+) with',
];

export function catchSubmitError(error: any) {
  const humanMessage = get(error, 'data.human_message', false);

  if (humanMessage) {
    return Promise.reject({
      message: humanMessage,
    });
  }

  let errorMessage = error.message;

  if (error.code === RpcErrorCode.PasswordPolicyViolation) {
    errorMessage =
      'You cannot reset your password with a password previously used.';
  }

  if (errorMessage) {
    const errorMessageWithField = API_ERRORS_WITH_FIELDNAME.map((regex) =>
      new RegExp(regex).exec(errorMessage),
    ).find(isString);

    const errorMessageKey = errorMessageWithField
      ? camelCase(errorMessageWithField[1])
      : 'message';

    if (console && console.error) {
      console.error(error);
    }

    return Promise.reject({
      [errorMessageKey]: errorMessage,
    });
  }
}

export const isValidString = (obj: any) =>
  typeof obj === 'string' && !isBlankString(obj);
