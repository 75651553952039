import classNames from 'classnames';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';
import styles from './ToggleSwitch.module.css';

interface Props extends Omit<FieldWrapperProps, 'children'> {
  id?: string;
  isOn?: boolean;
  label?: string;
  className?: string;
  onToggle?: () => void;
  testId?: string;
}

const ToggleSwitch = ({
  id,
  name,
  title,
  info,
  errorText,
  successText,
  helperText,
  keepBottomSpace,
  isInvalid,
  isRequired,
  isSuccess,
  isOn,
  disabled,
  readOnly,
  label,
  onToggle,
  className,
  testId,
}: Props) => {
  const handleChange = () => {
    if (!onToggle) return;
    onToggle();
  };

  return (
    <FieldWrapper
      id={id}
      name={name}
      title={title}
      info={info}
      errorText={errorText}
      successText={successText}
      helperText={helperText}
      keepBottomSpace={keepBottomSpace}
      isInvalid={isInvalid}
      disabled={disabled}
      isRequired={isRequired}
      isSuccess={isSuccess}
      readOnly={readOnly}
      readOnlyValue="TODO"
      noBorder
    >
      <div className={classNames(styles.wrapper, className)}>
        <input
          type="checkbox"
          id={id}
          checked={isOn}
          disabled={disabled}
          onChange={handleChange}
          className={styles.container}
          data-testid={testId}
        />
        {Boolean(label) && (
          <div className={classNames(disabled && styles.disabledLabel)}>
            {label}
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};

ToggleSwitch.defaultProps = {
  id: undefined,
  isOn: false,
  label: undefined,
  className: undefined,
  onToggle: undefined,
  testId: undefined,
};

export default ToggleSwitch;
