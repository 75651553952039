import { EntityId } from '@neo1/client/lib/entities/types';
import { EntityType } from 'redux/entities/types';

export enum ActionTypes {
  Nop = 'one/entities/NOP',
  Set = 'one/entities/SET',
  Delete = 'one/entities/DELETE',
  SetMulti = 'one/entities/SET_MULTI',
  HardSet = 'one/entities/HARD_SET',
}

export interface ActionSet<T> {
  type: ActionTypes.Set;
  entityType: EntityType;
  entity: T;
}

export interface ActionDelete {
  type: ActionTypes.Delete;
  entityType: EntityType;
  entityId: EntityId;
}

interface Nop {
  type: ActionTypes.Nop;
}

export interface ActionSetMulti<T> {
  type: ActionTypes.SetMulti;
  entityType: EntityType;
  entities: T[];
}
export interface ActionHardSet<T> {
  type: ActionTypes.HardSet;
  entityType: EntityType;
  entities: T[];
}

export type Action<T> =
  | ActionSet<T>
  | ActionSetMulti<T>
  | ActionDelete
  | ActionHardSet<T>
  | Nop;

export function setEntity<T>(entityType: EntityType, entity: T): ActionSet<T> {
  return {
    type: ActionTypes.Set,
    entityType,
    entity,
  };
}

export function setMulti<T>(
  entityType: EntityType,
  entities: T[],
): ActionSetMulti<T> {
  return {
    type: ActionTypes.SetMulti,
    entityType,
    entities,
  };
}
export function hardSet<T>(
  entityType: EntityType,
  entities: T[],
): ActionHardSet<T> {
  return {
    type: ActionTypes.HardSet,
    entityType,
    entities,
  };
}

export function deleteEntity(
  entityType: EntityType,
  entityId: EntityId,
): ActionDelete {
  return {
    type: ActionTypes.Delete,
    entityType,
    entityId,
  };
}
