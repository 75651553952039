import range from 'lodash/range';

export const SEGMENTS_COUNT = 20;

export function segmentCodingToArray(coding: any) {
  const result = range(0, SEGMENTS_COUNT).map((segmentId) => ({
    segment_id: segmentId,
    code: coding[segmentId] || null,
  }));
  return result;
}
