import { Addr } from '@neo1/core/utils';
import RadioButtonInput from 'components/composed/RadioButtonInput';
import styles from './ReplacedAddressFields.module.css';
import AddressBlock from '../AddressBlock';

export enum VALUE {
  REPLACED_ADDRESS = 'replaced',
  ORIGINAL_ADDRESS = 'original',
}

type Props = {
  address: Addr;
  replacedAddress: Addr;
  isReplacedAddressSelected: boolean;
  isUSAddress: boolean;
  onChange: (value: VALUE) => void;
};

const ReplacedAddressFields = ({
  address,
  replacedAddress,
  isReplacedAddressSelected,
  isUSAddress,
  onChange,
}: Props) => {
  if (!address || !replacedAddress) {
    return null;
  }
  return (
    <div className={styles.container}>
      <h4 className={styles.title} data-testid="replacedAddressTitle">
        We detected some discrepancies in your address
        {isUSAddress &&
          ', please click confirm to save the corrected version otherwise click back to modify your address'}
      </h4>
      <div className={styles.addressOptions}>
        <label
          htmlFor={VALUE.ORIGINAL_ADDRESS}
          className={styles.addressOption}
        >
          {/* let user choose the original version when not US address */}
          {!isUSAddress && (
            <RadioButtonInput
              id={VALUE.ORIGINAL_ADDRESS}
              name={VALUE.ORIGINAL_ADDRESS}
              value={VALUE.ORIGINAL_ADDRESS}
              checked={!isReplacedAddressSelected}
              onChange={() => onChange(VALUE.ORIGINAL_ADDRESS)}
            />
          )}
          <AddressBlock
            title="Original version:"
            address={address}
            testId="addressValues"
          />
        </label>
        <label
          htmlFor={VALUE.REPLACED_ADDRESS}
          className={styles.addressOption}
        >
          {/* radio readonly when US address */}
          <RadioButtonInput
            id={VALUE.REPLACED_ADDRESS}
            name={VALUE.REPLACED_ADDRESS}
            value={VALUE.REPLACED_ADDRESS}
            checked={isReplacedAddressSelected}
            onChange={() => onChange(VALUE.REPLACED_ADDRESS)}
            readOnly={isUSAddress}
          />
          <AddressBlock
            title="Corrected version"
            address={replacedAddress}
            replacedFeilds={Object.keys(replacedAddress).reduce((res, k) => {
              if (replacedAddress[k] !== address[k]) {
                return [...res, k];
              }
              return res;
            }, [])}
            testId="correctedAddressValues"
          />
        </label>
      </div>
    </div>
  );
};

export default ReplacedAddressFields;
