import { reify } from '../normalize';
import { Entity } from '../types';

export interface Address extends Entity {
  zipCode: string;
  city: string;
  name: string;
  state?: string;
  countryCode?: string;
  streetLine1: string;
  streetLine2?: string;
  userId?: string;
}

export interface UserAddress extends Address {
  userId: string;
}

export const AddressEntityName = 'Address';

export const homeAddressId = '00000000-0000-0000-0000-000000000000';

export function normalizeAddress(rawData: any): Address | null {
  if (!rawData) return null;

  const data = reify(rawData);

  return {
    entityType: AddressEntityName,
    id: data.getUuid('address_id', homeAddressId),
    zipCode: data.getString('zip_code', ''),
    city: data.getString('city', ''),
    name: data.getString('name', ''),
    state: data.getString('state', ''),
    countryCode: data.getString('country_code', ''),
    streetLine1: data.getString('street_line_1', ''),
    streetLine2: data.getString('street_line_2', ''),
  };
}
