import { SortTypes } from '@neo1/core/modules/config/constants';
import { CollectionFilters } from '@neo1/core/utils';
import uniq from 'lodash/uniq';
import { Actions, ExpenseActions } from './actions';
import { ExpenseTypesState } from './types';

const initialFilters: CollectionFilters = {
  search: null,
  sort: {
    prop: 'nature',
    dir: SortTypes.ASC,
  },
};

const initialState: ExpenseTypesState = {
  ids: [],
  filters: initialFilters,
};

const expenseTypesReducer = (
  state: ExpenseTypesState = initialState,
  action: ExpenseActions = null,
): ExpenseTypesState => {
  switch (action?.type) {
    case Actions.SET_EXPENSE_TYPES:
      return {
        ...state,
        ids: action.ids,
      };

    case Actions.SET_EXPENSE_TYPE:
      return {
        ...state,
        ids: uniq([...state.ids, action.id]),
      };

    case Actions.SET_SORT_OPTIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: action.options || state.filters.sort,
        },
      };

    case Actions.SET_SEARCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.search,
        },
      };

    default:
      return state;
  }
};
export default expenseTypesReducer;
