import { useEffect, useState } from 'react';
import { getViewerLocation, LatLong } from '@neo1/core/utils/geo';

const useViewerLocation = () => {
  const [location, setLocation] = useState<LatLong>(null);
  const getLocation = async () => {
    setLocation(await getViewerLocation());
  };
  useEffect(() => {
    getLocation();
  }, []);

  return location;
};

export default useViewerLocation;
