import { Children, cloneElement } from 'react';
import classNames from 'classnames';

import styles from './Group.module.css';

interface Props {
  className?: string;
  children: any;
}

const FormGroup = ({ children, className }: Props) => (
  <div className={classNames(styles.groupContainer, className)}>
    {Children.map(children, (child) =>
      cloneElement(child, {
        className: classNames(child.props.className, styles.field),
      }),
    )}
  </div>
);

FormGroup.defaultProps = {
  className: undefined,
};

export default FormGroup;
