import { normalizeUser, RpcCommand } from '@neo1/client';
import { UserLevel, User } from '@neo1/client/lib/entities/user/types';
import * as rpcBatchUtils from '@neo1/client/lib/rpc/batch';
import { getClient } from '../../utils/client';
import * as payload from './payload';

export type UpdatePasswordParams = {
  id: string;
  password: string;
};

/**
 * Updates given user
 * @param params
 */
export function createUser(params: payload.CreateUserParams) {
  let command: RpcCommand;

  switch (params.level) {
    case UserLevel.SYSTEM:
      command = {
        method: 'new_system_user',
        params: payload.getCreateSystemUserPayload(params),
      };
      break;
    case UserLevel.DOMAIN:
      params;
      command = {
        method: 'new_domain_user',
        params: payload.getCreateDomainUserPayload(params),
      };
      break;
    case UserLevel.COMPANY:
      command = {
        method: 'new_company_user',
        params: payload.getCreateCompanyUserPayload(params),
      };

      break;
    default:
      throw `Could not create user without level`;
  }

  return getClient().sendCommand(command).then(normalizeUser);
}

/**
 * Updates given user
 * @param user
 * @param applyAllOperations
 */
export function updateUser(user: payload.UpdateUserParams, applyAll = false) {
  const params = payload.getUpdateUserPayload(user, applyAll);
  return getClient()
    .sendCommand({ method: 'update_user', params })
    .then(normalizeUser);
}

/**
 * Updates one user password
 * @param payload
 */
export function updateUserPassword(payload: UpdatePasswordParams) {
  return getClient().sendCommand({
    method: 'update_user_password',
    params: {
      user_id: payload.id,
      password: payload.password,
    },
  });
}

/**
 * Retrieves information about one user by its user_id
 * @param user_id
 */
export function fetchUserById(userId: string) {
  return getClient()
    .sendCommand({ method: 'get_user_by_id', params: { user_id: userId } })
    .then(normalizeUser);
}

/**
 * Retrieves information about several users with a list of userIds
 * @param userIds[]
 */
export function fetchUsersByIds(userIds: string[] = []) {
  if (!Array.isArray(userIds) || userIds.length === 0) {
    return Promise.resolve([]);
  }
  return getClient()
    .batchCommands(
      userIds.map((userId) => ({
        method: 'get_user_by_id',
        params: { user_id: userId },
      })),
    )
    .then((results) => rpcBatchUtils.unwrapFilter(results, normalizeUser));
}

/**
 * Fetches the whole list of domain users for a given domain
 * @returns {Promise.<Array>}
 */
export function fetchSystemUsers(): Promise<User[]> {
  return getClient()
    .sendCommand({ method: 'get_system_users', params: {} })
    .then((users) => users.map(normalizeUser));
}

export async function acceptEula({ id }: User, version: string) {
  try {
    const result = await getClient().sendCommand({
      method: 'accept_eula',
      params: { user_id: id, version },
    });
    return normalizeUser(result);
  } catch (error) {
    throw error;
  }
}

/**
 * Deletes the given user
 * @param user_id
 */
export async function deleteUser(user_id: User['id']): Promise<any> {
  try {
    const result = await getClient().sendCommand({
      method: 'delete_user',
      params: { user_id },
    });
    return result;
  } catch (error) {
    throw error;
  }
}
