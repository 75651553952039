import { useHistory, useLocation } from 'react-router-dom';

import isString from 'lodash/isString';
import { User } from '@neo1/client';
import { AppMenuRoute } from 'config/routes/utils';
import { useAmplitude } from 'contexts/instrumentation';
import { DeviceSize, useWindowContext } from 'contexts/window';

import styles from './styles.module.css';
import getProfileRoutes from './getProfileRoutes';
import MenuItem from '../../MenuItem';

interface Props {
  user: User;
  onNavigation: Function;
}

const Body = ({ user, onNavigation }: Props) => {
  const routes = getProfileRoutes(user);
  const { logEvent } = useAmplitude();
  const history = useHistory();
  const { pathname: currentPath } = useLocation();
  const { deviceSize } = useWindowContext();

  const onNavigateRoute = (route: AppMenuRoute) => {
    logEvent({
      event: `profile_${
        isString(route.title) ? route.title : String(route.title())
      }`,
    });
    if (onNavigation) {
      onNavigation();
    }
    history.push(route.path);
  };

  return (
    <main className={styles.container}>
      {routes.map((route) => (
        <MenuItem
          key={route.name}
          label={route.title.toString()}
          buttonTitle={`Go to ${route.title}`}
          icon={route.menu?.iconClass}
          selected={
            deviceSize === DeviceSize.desktop && currentPath === route.path
          }
          active={
            deviceSize !== DeviceSize.desktop && currentPath === route.path
          }
          dark={deviceSize !== DeviceSize.desktop}
          onClick={() => {
            onNavigateRoute(route);
          }}
        />
      ))}
    </main>
  );
};

export default Body;
