import { CompanyFeature } from '@neo1/client';
import wrapAsync from 'utils/wrapAsync';
import { ROUTE_NAME_REPORTS, ROUTE_PATH_REPORTS } from './constants';

const reportsRoute = {
  path: ROUTE_PATH_REPORTS,
  name: ROUTE_NAME_REPORTS,
  component: wrapAsync(
    () =>
      import(
        /* webpackChunkName: "reports" */
        '.'
      ),
  ),
  menu: {
    companyFeatures: [CompanyFeature.Reporting],
    iconClass: 'charts',
    userLevels: ['company'],
  },
};

export default reportsRoute;
