import range from 'lodash/range';

/**
 * shared logic and models between suply and budget
 */

const getCodingPayloadTemplate = () =>
  range(0, 20).map((n) => ({
    segment_id: n,
    code: null,
  }));

export type CodingDatumPayload = {
  segment_id: number;
  code: string;
};

export type CodingDataPayload = CodingDatumPayload[];

export type CodingsRecord = Record<string, string>; // => {[segmentId]: codeValue}

export interface AcountingCodingData {
  code?: string;
  description?: string;
  name?: string;
  segmentId: number;
  status: string | null;
}

export const toCodingsPayload = (
  codingsData: AcountingCodingData[],
): CodingDataPayload => {
  const codingDataPayload = codingsData.map(
    ({ code, segmentId: segment_id }) => ({
      code: code || null,
      segment_id,
    }),
  );

  return codingDataPayload.reduce((payload, codingDatum) => {
    const indexToReplace = payload.findIndex(
      ({ segment_id }) => codingDatum.segment_id === segment_id,
    );
    payload[indexToReplace] = codingDatum;
    return payload;
  }, getCodingPayloadTemplate());
};

export const normalizeAcountingCodingData = (
  data: any,
): AcountingCodingData => ({
  code: data.getString('code'),
  description: data.getString('description'),
  name: data.getString('name'),
  segmentId: data.getNumber('segment_id'),
  status: data.getString('status'),
});

export interface AccountingSegmentData {
  segmentId: number;
  name: string;
}

export const normalizeSegmentData = (data: any): AccountingSegmentData => ({
  segmentId: data.getNumber('segment_id'),
  name: data.getString('name'),
});

export const toAccountingCodingData = (
  codings: CodingsRecord,
): AcountingCodingData[] => {
  return Object.keys(codings).map((segmentId) => ({
    segmentId: Number(segmentId),
    code: codings[Number(segmentId)],
    description: null,
    status: null,
    name: null,
  }));
};

export const toCodingsRecord = (
  accountingCodingData: AcountingCodingData[] = [],
): CodingsRecord =>
  accountingCodingData.reduce((record, { segmentId, code }) => {
    record[segmentId] = code;
    return record;
  }, {} as Record<number, string>);
