import { displayDate } from '../../../i18n/utils';

function getReportCompanyNamesText({ params }, options = {}) {
  const { companies } = options;

  const companyNames = companies
    .filter((company) => params.scope.company_ids.indexOf(company.id) !== -1)
    .map((company) => company.name);

  return companyNames.length === 0
    ? 'your transactions'
    : `${companyNames.join(', ')} transactions`;
}

export function getReportParamsSummary(report, options) {
  const { params, loaded } = report;
  return loaded
    ? `Report for ${getReportCompanyNamesText(
        report,
        options,
      )} from ${displayDate(params.dateRange.start_date)} to ${displayDate(
        params.dateRange.end_date,
      )}`
    : 'Report Builder';
}
