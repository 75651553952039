import { UUID } from '../common';
import { reify } from '../normalize';
import { Company } from '@neo1/client';
import { Entity } from '../types';

type ServiceType = 'air' | 'rail' | 'car' | 'hotel' | 'custom';
export const CompanyTripEntityName = 'CompanyTrip';
export interface CompanyTrip extends Entity {
  id: UUID;
  companyId: Company['id'];
  travelerFullName: string;
  pnr: string | null;
  startDate: string;
  endDate: string;
  name: string;
  servicesType: ServiceType[];
}

export function normalizeCompanyTrip(rawData: any): CompanyTrip {
  const data = reify(rawData);

  return {
    entityType: CompanyTripEntityName,
    id: data.getUuid('trip_id'),
    companyId: data.getUuid('company_id'),
    startDate: data.getString('start_date'),
    endDate: data.getString('end_date'),
    travelerFullName: data.getString('traveler_full_name'),
    pnr: data.getString('pnr'),
    name: data.getString('name'),
    servicesType: data.getArray<ServiceType>('services_type'),
  };
}
