import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import useAsyncState from 'hooks/asyncState';
import {
  CompanyExtractService,
  UUID,
  CreditCardMappingPreferences,
} from '@neo1/client';
import { notifyError } from 'modules/App/redux/notifications/toaster/thunks';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import StepContent from 'components/composed/MultiStepModalForm/StepContent';
import { useDispatch, useSelector } from 'react-redux';
import { getVendors } from 'redux/accountingIntegrations/thunks';
import { selectVendors } from 'redux/accountingIntegrations/selector';
import FormField from 'components/elements/form/Field';
import RadioGroup from 'components/composed/RadioGroup';
import SelectBox from 'components/elements/form/fields/SelectBox';
import { FormikErrors, useFormikContext } from 'formik';

import styles from './CardTxsConfiguration.module.css';
import messages from './messages';

export type CardValues = {
  vendorId: string;
  mappingPreferences: CreditCardMappingPreferences;
};
export type Props = {
  failedRecords: UUID[];
};

export const validationCardConf = (
  values: CardValues,
): FormikErrors<CardValues> => {
  const errors: FormikErrors<CardValues> = {};

  const { mappingPreferences, vendorId } = values;

  if (!values.mappingPreferences) {
    errors.mappingPreferences = 'This field is required';
  }

  if (
    mappingPreferences === CreditCardMappingPreferences.SingleVendor &&
    !vendorId
  ) {
    errors.vendorId = 'Vendor is required';
  }

  return errors;
};
export const INITIAL_VALUES = {
  vendorId: null,
  mappingPreferences: CreditCardMappingPreferences.SingleVendor,
};
const CardTxsConfContent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const company = useSelector(selectActingCompany);
  const vendors = useSelector(selectVendors(CompanyExtractService.QuickBooks));

  const {
    values: { mappingPreferences },
    setFieldValue,
  } = useFormikContext<CardValues>();

  const isSingleVendorActive =
    mappingPreferences === CreditCardMappingPreferences.SingleVendor;

  const onGetVendors = useCallback(async () => {
    try {
      await dispatch(getVendors(company.id));
    } catch (_) {
      dispatch(notifyError(intl.formatMessage(messages.loadingError)));
    }
  }, [company.id, dispatch, intl]);

  const { execute, isLoading } = useAsyncState(onGetVendors);

  useEffect(() => {
    execute();
  }, [execute]);

  const vendorsList = vendors.map((v) => ({
    label: v.displayName,
    value: v.id,
  }));

  return (
    <StepContent submitTitle="Continue" isLoading={isLoading}>
      <div className={styles.container}>
        <RadioGroup
          onChange={(v) => setFieldValue('mappingPreferences', v)}
          value={mappingPreferences}
          className={styles.radioContainer}
        >
          <RadioGroup.Item
            value={CreditCardMappingPreferences.SingleVendor}
            text={intl.formatMessage(messages.singleVendor)}
            testId="singleMapping"
          />
          <RadioGroup.Item
            value={CreditCardMappingPreferences.MerchantAsVendor}
            text={intl.formatMessage(messages.merchantMapping)}
            testId="merchantMapping"
          />
        </RadioGroup>

        {isSingleVendorActive && (
          <FormField
            title={intl.formatMessage(messages.vendor)}
            required
            name="vendorId"
          >
            <SelectBox options={vendorsList} />
          </FormField>
        )}
      </div>
    </StepContent>
  );
};

export default CardTxsConfContent;
