import { EulaApi } from '@neo1/client';
import { getClient } from '../../utils';

export type EulaConfig = {
  version: string;
  url: string;
};

export const getCountryEula = async (
  countryCode: string,
): Promise<EulaConfig> =>
  await getClient().sendCommand(EulaApi.getCountryEula(countryCode));

export const getCompanyEula = async (companyId: string): Promise<EulaConfig> =>
  await getClient().sendCommand(EulaApi.getCompanyEula(companyId));
