import { ReportParams } from '@neo1/core/modules/reporting/types';

export enum Actions {
  LOAD_REPORT = 'one/reporting/predefined/FETCH',
  LOAD_REPORT_ERR = 'one/reporting/predefined/FETCH_ERR',
  LOAD_REPORT_OK = 'one/reporting/predefined/FETCH_OK',
  SET_REPORT = 'one/reporting/predefined/SET_REPORT',
  SET_REPORT_FILTERS = 'one/reporting/predefined/SET_REPORT_FILTERS',
  SET_REPORT_PARAMS = 'one/reporting/predefined/SET_REPORT_PARAMS',
  SET_REPORT_OPTS = 'one/reporting/predefined/SET_REPORT_OPTS',
  RESET = 'one/reporting/predefined/RESET',
}

export function fetchReport(
  reportName: string,
  params: Record<string, any>,
  payload: Record<string, any>[] = [],
): PredefinedReportingActions {
  return {
    type: Actions.LOAD_REPORT,
    reportName,
    params,
    payload,
  };
}
export function fetchReportSuccess(
  reportName: string,
  reportsByChartName: Record<string, any>,
): PredefinedReportingActions {
  return {
    type: Actions.LOAD_REPORT_OK,
    reportName,
    reportsByChartName,
  };
}

export function fetchReportFailed(
  reportName: string,
  error: Error,
): PredefinedReportingActions {
  return {
    type: Actions.LOAD_REPORT_ERR,
    reportName,
    error,
  };
}

export function setParams({
  reportName,
  params,
}: {
  reportName?: string;
  params: ReportParams;
}): PredefinedReportingActions {
  return {
    type: Actions.SET_REPORT_PARAMS,
    reportName,
    params,
  };
}

export function setReportFilters(
  reportName: string,
  filters: Record<string, any> = {},
): PredefinedReportingActions {
  return {
    type: Actions.SET_REPORT_FILTERS,
    reportName,
    filters,
  };
}
export function resetState() {
  return { type: Actions.RESET };
}

export function setReportViewOptions(
  reportName: string,
  key: string,
  value: boolean,
): PredefinedReportingActions {
  return {
    type: Actions.SET_REPORT_OPTS,
    reportName,
    key,
    value,
  };
}
export type PredefinedReportingActions =
  | {
      type: Actions.SET_REPORT_OPTS;
      reportName: string;
      key: string;
      value: boolean;
    }
  | {
      type: Actions.SET_REPORT_FILTERS;
      reportName: string;
      filters: Record<string, any>;
    }
  | {
      type: Actions.SET_REPORT_PARAMS;
      reportName: string;
      params: ReportParams;
    }
  | {
      type: Actions.LOAD_REPORT_ERR;
      reportName: string;
      error: Error;
    }
  | {
      type: Actions.LOAD_REPORT;
      reportName: string;
      params: Record<string, any>;
      payload: Record<string, any>[];
    }
  | {
      type: Actions.LOAD_REPORT_OK;
      reportName: string;
      reportsByChartName: Record<string, any>;
    }
  | { type: Actions.RESET };
