import { CompanyUser, User } from '@neo1/client';
import { getClient } from '../../utils';
import { DeleteAddressPayload } from './types';
import { Address, normalizeAddress } from '@neo1/client';

export function mapAddressToPayload(address: Address) {
  const {
    id,
    zipCode,
    city,
    name,
    state,
    countryCode,
    streetLine1,
    streetLine2,
  } = address;

  return {
    address_id: id,
    zip_code: zipCode || null,
    city: city || null,
    name: name || null,
    state: state || null,
    country_code: countryCode || null,
    street_line_1: streetLine1 || null,
    street_line_2: streetLine2 || null,
  };
}

export async function setHomeAddress(
  userId: CompanyUser['id'],
  address: Address,
): Promise<Address> {
  try {
    const results = await getClient().sendCommand({
      method: 'set_user_address_configuration',
      params: {
        user_id: userId,
        default_home_address: mapAddressToPayload(address),
      },
    });
    return normalizeAddress(results.default_home_address);
  } catch (error) {
    throw error;
  }
}

export async function createAddress(
  { id }: User,
  address: Address,
): Promise<Address> {
  try {
    const results = await getClient().sendCommand({
      method: 'new_user_address',
      params: {
        user_id: id,
        ...mapAddressToPayload(address),
      },
    });
    return normalizeAddress(results);
  } catch (error) {
    throw error;
  }
}

export async function updateAddress(address: Address): Promise<Address> {
  try {
    const results = await getClient().sendCommand({
      method: 'update_user_address',
      params: mapAddressToPayload(address),
    });
    return normalizeAddress(results);
  } catch (error) {
    throw error;
  }
}

export async function deleteAddress(address: Address): Promise<void> {
  const { id } = address;

  const addressPayload: DeleteAddressPayload = { address_id: id };

  try {
    await getClient().sendCommand({
      method: 'delete_user_address',
      params: addressPayload,
    });
  } catch (error) {
    throw error;
  }
}

export async function getDefaultUserAddressConfig(
  userId: CompanyUser['id'],
): Promise<Address | null> {
  const addressConfigResults = await getClient().sendCommand({
    method: 'get_user_address_configuration',
    params: {
      user_id: userId,
    },
  });

  return normalizeAddress((addressConfigResults || {}).default_home_address);
}

export async function getAddresses({ id }: User): Promise<{
  addresses: Address[];
  defaultHomeAddress: Address;
}> {
  try {
    const addressesResults = await getClient().sendCommand({
      method: 'get_user_addresses',
      params: {
        user_id: id,
      },
    });

    const addresses = (addressesResults || []).map(normalizeAddress);
    const defaultHomeAddress = await getDefaultUserAddressConfig(id);
    return {
      addresses,
      defaultHomeAddress,
    };
  } catch (error) {
    throw error;
  }
}
