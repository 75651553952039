import { Amount } from '@neo1/client';

/**
 * Returns the text description of the given amount
 * @param amount
 * @param withSymbol
 * @returns {string}
 */
type FormatAmountOptions = {
  locale?: string;
  useIntl?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

export const formatAmount = (
  { amount = 0, currency }: Amount,
  { locale, useIntl = true, ...options }: FormatAmountOptions = {},
) => {
  const defaultLocale = navigator && navigator.language;
  const currencyName = currency.toUpperCase();

  if ((<any>window).Intl && useIntl) {
    const intlFormatter = new window.Intl.NumberFormat(
      locale || defaultLocale,
      {
        currencyDisplay: 'code',
        currency,
        style: 'currency',
        ...options,
      },
    );

    return intlFormatter.format(amount);
  }

  return `${currencyName} ${amount.toFixed(2)}`;
};

const NARROW_NO_BREAKSPACE = '\u202F';
export function formatAmountForPdf(
  amount: Amount,
  options?: FormatAmountOptions,
) {
  return formatAmount(amount, options).replace(
    new RegExp(NARROW_NO_BREAKSPACE, 'g'),
    ' ',
  );
}

/**
 * Sums two amounts together
 * @param {TxAmount} a
 * @param {TxAmount} b
 */
export const sumAmounts = (a?: Amount, b?: Amount) => {
  const aAmount = a ? a.amount : 0;
  const aCurrency = a && a.currency;
  const bAmount = b ? b.amount : 0;
  const bCurrency = b && b.currency;

  return {
    amount: aAmount + bAmount,
    currency: aCurrency || bCurrency,
  };
};
/**
 * Multiplies amount with a given quantity
 * @param {*} a Base amount
 * @param {*} quantity
 */
export const quantifyAmount = (a: Amount, quantity: number) => {
  return {
    ...a,
    amount: a.amount * quantity,
  };
};
