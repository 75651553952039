import {
  CompanyGroupUser,
  CompanyUser,
  CompanyUserRole,
  DomainUser,
  SystemUser,
  TravelerGroup,
  UserLevel,
} from './types';
import { ReifiedDataWrapper, reify } from '../normalize';
import { CompanyData } from '../company';

export const UserEntityName = 'User';
export function normalizeSystemUser(data: ReifiedDataWrapper): SystemUser {
  return {
    id: data.getUuid('user_id'),
    entityType: UserEntityName,
    level: UserLevel.SYSTEM,
    creation: data.getDate('creation'),
    displayName: data.getString('full_name'),
    email: data.getString('email'),
    lastLogin: data.getDate('last_login'),
    lastUpdate: data.getDate('last_update'),
    login: data.getString('login'),
    status: data.getString('status'),
    user_id: data.getUuid('user_id'),
    isFetching: false,
    fetchError: null,
  };
}

export function normalizeDomainUser(data: ReifiedDataWrapper): DomainUser {
  return {
    ...normalizeSystemUser(data),
    level: UserLevel.DOMAIN,
    domainId: data.getUuid('domain_id'),
    additionalDomains: data.getArray<string>('additional_domain_ids'),
  };
}

export function normalizeCompanyUser(data: ReifiedDataWrapper): CompanyUser {
  const rolesList = data.getArray('roles').join(', ');

  const displayName = `${data.getString('first_name', '')} ${data.getString(
    'last_name',
    '',
  )}`.trim();

  return {
    ...normalizeSystemUser(data),
    level: UserLevel.COMPANY,
    displayName,
    rolesList,
    companyId: data.getUuid('company_id'),
    preferredCurrency: data.getString('preferred_currency', 'USD'),
    domainId: data.getUuid('domain_id'),
    externalId: data.get('external_id'),
    firstName: data.getString('first_name'),
    lastName: data.getString('last_name'),
    latestAcceptedEula: data.get('latest_accepted_eula', null) && {
      url: data.getString('latest_accepted_eula.url'),
      version: data.getString('latest_accepted_eula.version'),
      acceptedAt: data.getString('latest_accepted_eula.accepted_at'),
      isLatest: data.getBoolean('latest_accepted_eula.is_latest'),
    },
    locale: data.getString('locale'),
    roles: data.getArray('roles'),
    title: data.getString('title'),
    supervisorId: data.getStringOrNull('supervisor_id'),
    phone: data.getStringOrNull('phone'),
    birthDate: data.getStringOrNull('birth_date'),
    travelerGroup: data.getString('traveler_group') as TravelerGroup,
  };
}

export const CompanyGroupUserEntityName = 'CompanyGroupUser';

export function normalizeCompanyGroupUser(
  inputData: any = {},
): CompanyGroupUser {
  const data = reify(inputData);
  return {
    level: UserLevel.COMPANY,
    id: data.getUuid('user_id'),
    entityType: CompanyGroupUserEntityName,
    companyId: data.getUuid('company_id'),
    firstName: data.getString('first_name'),
    lastName: data.getString('last_name'),
    displayName: data.getString('full_name'),
    title: data.getString('title'),
    companyName: data.getString('company_name'),
    email: data.getString('email'),
    supervisorId: data.getStringOrNull('supervisor_id'),
    roles: data.getArray('roles') as CompanyUserRole[],
    status: data.getString('status'),
    travelerGroup: data.getString('traveler_group') as TravelerGroup,
  };
}

export function invalidUserLevel(level: any): never {
  throw new Error(`Invalid user level: ${level}`);
}
/**
 * Creates an hybridated user object
 * @param data
 */
export function normalizeUser(inputData: any = {}) {
  const { level } = inputData;
  const data = reify(inputData);

  if (level === UserLevel.SYSTEM) {
    return normalizeSystemUser(data);
  } else if (level === UserLevel.DOMAIN) {
    return normalizeDomainUser(data);
  } else if (level === UserLevel.COMPANY) {
    return normalizeCompanyUser(data);
  } else {
    return invalidUserLevel(level);
  }
}

export function domainToCompanyUser(
  user: DomainUser,
  companyData: CompanyData,
): CompanyUser {
  return {
    ...user,
    id: user.id,
    level: UserLevel.COMPANY,
    freezed: true,
    rolesList: '',
    companyId: companyData.id,
    preferredCurrency: companyData.defaultCurrency,
    domainId: companyData.domainId,
    externalId: '',
    firstName: 'Admin',
    lastName: 'Admin',
    displayName: 'Admin',
    locale: 'US',
    roles: [CompanyUserRole.Admin, CompanyUserRole.Finance],
    title: 'mr',
    supervisorId: null,
    latestAcceptedEula: null,
    travelerGroup: TravelerGroup.Employees,
  };
}
