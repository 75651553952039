import { Base64 } from 'js-base64';

export const parseJwt = (token: string) =>
  JSON.parse(Base64.decode(token.split('.')[1]));

export const tokenNotExpired = (token: string): boolean => {
  try {
    const { exp } = parseJwt(token);
    return exp > Math.ceil(Date.now() / 1000);
  } catch (error) {
    // in cases like token is not in valid format, treat it as expired
    return false;
  }
};
