import { ThunkDispatch } from 'redux-thunk';
import { AppCoreState } from 'redux/types';
import * as companiesApi from '@neo1/core/modules/companies/api';
import {
  Company,
  CompanyEntityName,
  CompanyExtractService,
} from '@neo1/client';
import { setEntity } from 'redux/entities/thunks';

type Dispatch = ThunkDispatch<AppCoreState, void, any>;
type GetState = () => AppCoreState;

export const save =
  (payload: companiesApi.SaveCompanyPayload) => async (dispatch: Dispatch) => {
    const company = await companiesApi.save(payload);
    setEntity<Company>({
      entityType: CompanyEntityName,
      entity: company,
    })(dispatch);
    return company;
  };

export const load = (id: string) => async (dispatch: Dispatch) => {
  const company = await companiesApi.fetchById(id);
  setEntity<Company>({
    entityType: CompanyEntityName,
    entity: company,
  })(dispatch);
  return company;
};

export const reconnectExtractService =
  (id: Company['id'], callbackUrl: string) =>
  async (_: never, getState: GetState) => {
    const company = getState().entities?.Company[id];

    if (!company || company.accountingLink === CompanyExtractService.Standard) {
      throw new Error('Could not reconnect to extract service');
    }

    return companiesApi.reconnectExtractService(
      company.id,
      company.accountingLink,
      callbackUrl,
    );
  };
