import {
  Address,
  isAddressName,
  isCity,
  isCountryState,
  isStreet,
  isZipCode,
} from '@neo1/client';
import { isValidString } from '../../utils';
import { mapAddressToPayload } from './api';

export const ADDRESS_MANDATORY_FIELDS: Array<keyof Address> = [
  'city',
  'countryCode',
  'streetLine1',
  'zipCode',
];

/**
 * Asserts if object is an address
 * @param obj
 */
export const isValidAddress = (obj: any): obj is Address => {
  if (typeof obj !== 'object') {
    return false;
  }
  if (!ADDRESS_MANDATORY_FIELDS.every((field) => isValidString(obj[field]))) {
    return false;
  }
  if (obj.name && !isAddressName(obj.name)) {
    return false;
  }
  if (obj.state && !isCountryState(obj.state)) {
    return false;
  }
  if (obj.city && !isCity(obj.city)) {
    return false;
  }
  if (obj.zipCode && !isZipCode(obj.zipCode)) {
    return false;
  }
  if (obj.streetLine1 && !isStreet(obj.streetLine1)) {
    return false;
  }
  if (obj.streetLine2 && !isStreet(obj.streetLine2)) {
    return false;
  }
  return true;
};

/**
 * Maps partial address data to a JSON-RPC address payload
 * @param data
 */
export const mapValuesToAddressPayload = (data: Partial<Address>) => {
  if (!data) {
    return null;
  }

  const address: Partial<Address> = {
    city: data.city,
    streetLine1: data.streetLine1,
    streetLine2: data.streetLine2,
    state: data.state,
    zipCode: data.zipCode,
    countryCode: data.countryCode,
  };

  return isValidAddress(address) ? mapAddressToPayload(address) : null;
};

/**
 * Returns assert function to compare 2 addresses
 * @param address
 */
export const matchesAddress = (address: Address) => (otherAddress: Address) =>
  otherAddress.id === address.id;
