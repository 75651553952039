/* eslint-disable import/prefer-default-export */

import isString from 'lodash/isString';
import { MessageDescriptor } from 'react-intl';

export function isMessageDescriptor(data: any): data is MessageDescriptor {
  return (
    data &&
    'defaultMessage' in data &&
    isString(data.defaultMessage) &&
    'id' in data &&
    isString(data.id)
  );
}
