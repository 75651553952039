import { createPortal } from 'react-dom';
import {
  Fragment,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getPlacesDetails } from '@neo1/core/utils/maps';
import { isValidString } from '@neo1/core/utils';
import withGoogleMaps, { GoogleMapsContext } from 'utils/withGoogleMaps';
import useAddressSuggestions from 'components/elements/Google/useAddressSuggestions';

import styles from './AutocompleteOptions.module.css';
import { getAddressFromPlace, SelectedAddress } from '../utils';

type Props = {
  children: ReactNode;
  query: string;
  onPlaceSelected: (
    address: SelectedAddress,
    place?: google.maps.places.PlaceResult,
  ) => void;
  inputRef: RefObject<HTMLInputElement>;
};

// ! Note: When wrapping an input field with this component, if the input field is inside a FormGroup is better to wrap the FormGroup, to be sure the FormGroup styles are applied to the input field.
const AutocompleteOptions = ({
  children,
  query,
  onPlaceSelected,
  inputRef,
}: Props) => {
  const { map } = useContext(GoogleMapsContext);
  const [isSkipQueryChanges, setSkipQueryChanges] = useState(false);
  const [isOptionsHidden, setIsOptionsHidden] = useState(true);
  const { setAddress, placesSuggestions, clearSuggestions } =
    useAddressSuggestions();
  const [pickedAddressStreet, setPickedStreet] = useState('');
  const [initialQuery] = useState(query);
  const isSuggestions = placesSuggestions.length > 0;

  const clearOptions = useCallback(() => {
    clearSuggestions();
    setIsOptionsHidden(true);
  }, [setIsOptionsHidden, clearSuggestions]);

  useEffect(() => {
    if (isSuggestions) {
      setIsOptionsHidden(false);
    }
  }, [isSuggestions, isOptionsHidden]);

  const getPlace = useCallback(
    async (
      placeId: google.maps.places.PlaceResult['place_id'],
    ): Promise<google.maps.places.PlaceResult> => {
      try {
        return await getPlacesDetails(map, placeId, ['address_component']);
      } catch (e) {
        console.error(e);
      }
      return null;
    },
    [map],
  );

  useEffect(() => {
    if (
      isValidString(query) &&
      !isSkipQueryChanges &&
      query !== pickedAddressStreet &&
      query !== initialQuery
    ) {
      setAddress(query);
    }
  }, [
    query,
    setAddress,
    isSkipQueryChanges,
    pickedAddressStreet,
    initialQuery,
  ]);

  if (!inputRef.current) return <>{children}</>;

  const PopOverContent = createPortal(
    <div className={styles.container} key="placesOptions">
      {placesSuggestions.map(({ placeId, label }) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={styles.option}
          onClick={async () => {
            setSkipQueryChanges(true);
            const place = await getPlace(placeId);
            if (place) {
              const pickedAddress = getAddressFromPlace(place);
              onPlaceSelected(pickedAddress, place);
              setPickedStreet(pickedAddress.streetLine1);
            }
            clearOptions();
            setSkipQueryChanges(false);
          }}
          key={placeId}
          data-testid={placeId}
        >
          {label}
        </div>
      ))}
    </div>,
    inputRef.current.parentElement,
  );
  const PopOverMask = createPortal(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={styles.selectMask}
      onClick={clearOptions}
      data-testid="popoverMask"
    />,
    inputRef.current.parentElement,
  );

  return (
    <Fragment key="autocompleteChildren">
      {children}
      {!isOptionsHidden && PopOverContent}
      {!isOptionsHidden && PopOverMask}
    </Fragment>
  );
};

export default withGoogleMaps(AutocompleteOptions);
