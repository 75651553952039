import { Receipt } from '../../modules/expenses/receipts/models';
import {
  FileType,
  fileTypeFromMime,
  getFileType,
  getFileTypeFromFileName,
} from './types';

const RECEIPT_FILE_TYPES = [
  FileType.JPG,
  FileType.GIF,
  FileType.PDF,
  FileType.PNG,
];

export const isValidReceiptFile = (file: File) =>
  file && RECEIPT_FILE_TYPES.includes(getFileType(file));

export const getReceiptTypeFromMime = (fileMimeType: string) =>
  fileTypeFromMime(fileMimeType);

export const isValidReceiptFileByFileName = (filename: string) =>
  filename && RECEIPT_FILE_TYPES.includes(getFileTypeFromFileName(filename));

export const isPdf = (uri: string) => uri && /\.pdf$/.test(uri);

export const isPdfReceipt = (receipt: Receipt) => receipt && isPdf(receipt.url);
