import { AppConfigReferentialData } from '@neo1/core/modules/app/types';
import { ReferentialActionType, ReferentialType } from './actions';

const initialState: AppConfigReferentialData = {
  countries: undefined,
  currencies: undefined,
};

export default (
  state: AppConfigReferentialData = initialState,
  action: ReferentialActionType = null,
): AppConfigReferentialData => {
  switch (action?.type) {
    case ReferentialType.LoadReferentialOk:
      return {
        ...action.data,
      };

    default:
      return state;
  }
};
