import { wrapResults } from '@neo1/client/lib/rpc/batch';
import { User, UUID, Delegation, normalizeDelegation } from '@neo1/client';
import { CreateDelegationPayload, DeleteDelegationPayload } from './types';
import { getClient, formatDateTimeToUTC } from '../../../utils';

export type DelegationValues = {
  recipientId: UUID;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
};

export const createDelegation = async (
  { id }: User,
  delegation: DelegationValues,
): Promise<Delegation> => {
  const { recipientId, startDate, endDate, startTime, endTime } = delegation;

  const delegationPayload: CreateDelegationPayload = {
    recipient_id: recipientId,
    start_date: formatDateTimeToUTC(startDate, startTime),
    end_date: formatDateTimeToUTC(endDate, endTime),
  };

  const results = await getClient().sendCommand({
    method: 'delegate_account',
    params: {
      user_id: id,
      ...delegationPayload,
    },
  });
  return normalizeDelegation(results);
};

export const deleteDelegation = async (id: Delegation['id']): Promise<void> => {
  const delegationPayload: DeleteDelegationPayload = {
    delegation_id: id,
  };

  await getClient().sendCommand({
    method: 'delete_delegation',
    params: delegationPayload,
  });
};

export const getDelegations = async (
  userId: UUID,
): Promise<{ toMe: Delegation[]; toOthers: Delegation[] }> => {
  const results = await getClient().batchCommands(
    [
      {
        method: 'get_not_expired_delegations',
        params: {
          user_id: userId,
        },
        id: 1,
      },
      {
        method: 'get_active_delegations_by_recipient',
        params: {
          recipient_id: userId,
        },
        id: 2,
      },
    ],
    false,
  );
  const batchResults = wrapResults(results);
  const toOthers = batchResults.get(1, []).map(normalizeDelegation);
  const toMe = batchResults.get(2, []).map(normalizeDelegation);
  return { toMe, toOthers };
};

export const getDelegationsToMe = async (
  userId: UUID,
): Promise<Delegation[]> => {
  const results = await getClient().sendCommand({
    method: 'get_active_delegations_by_recipient',
    params: { recipient_id: userId },
  });
  return results.map(normalizeDelegation);
};
