export enum ApiMethods {
  ATTACH_RECEIPT = 'attach_receipt',
  DETACH_RECEIPT = 'detach_receipt',
  NEW_RECEIPT = 'new_receipt',
  UPDATE_RECEIPT = 'update_receipt',
  MATCH_RECEIPT = 'match_receipt',
  EDIT_TX = 'edit_tx',
  EDIT_CASH_TX = 'edit_cash_tx',
  EMIT_TX = 'create_personal_cash_tx',
  GET_TX = 'get_tx',
  SUBMIT_TX = 'submit_tx',
  UPDATE_TX = 'update_tx',
  UPDATE_TX_IN_REVIEW = 'edit_tx_in_review',
  GET_TX_FOR_INSTRUMENT = 'get_tx_payment_instrument',
  REMOVE_MILEAGE_LINE = 'remove_mileage_line',
  DEL_TX = 'delete_tx',
  REVIEW_TX = 'review_tx',
  APPROVE_TX = 'approve_tx',
  REJECT_TX = 'reject_tx',
  REMOVE_TX_LINE = 'remove_tx_line',
  WRITE_MILEAGE_LINE = 'write_mileage_line',
  WRITE_TX_LINE = 'write_tx_line',
  LINK_BUDGET = 'link_budget',
  UNLINK_BUDGET = 'unlink_budget',
  RUN_PREDEFINED_REPORT = 'run_predefined_report',
  RUN_CHART_REPORT = 'run_chart_report',
  RUN_REPORT_BUILDER = 'run_report_builder',
  NEW_USER_FILE_UPLOAD = 'new_user_file_upload',
  UPDATE_DOMAIN_BRANDING = 'update_domain_branding',
}

export enum ApiCallContexts {
  USER = 'user',
  FINANCE = 'finance',
}

export enum SortTypes {
  DESC = 'desc',
  ASC = 'asc',
}

export enum Model {
  TAX = 'tax',
  PAYMENT_TYPE = 'paymentType',
  PAYMENT_INSTRUMENT = 'paymentInstrument',
  TX = 'tx',
  COMPANY = 'business',
  LINKED_COMPANY = 'linked business',
  USER = 'user',
  DOMAIN = 'domain',
  SPEND_POLICY = 'spendPolicy',
}
