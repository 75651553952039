import { SortTypes } from '@neo1/core/modules/config/constants';
import uniq from 'lodash/uniq';

import { Action, Actions } from './actions';
import { AdminView, HomeViewState } from './types';

export const initialState: HomeViewState = {
  systemAdmins: {
    ids: [],
    filters: {
      sort: {
        prop: 'displayName',
        dir: SortTypes.ASC,
      },
      search: '',
    },
  },
  domainAdmins: {
    ids: [],
    filters: {
      sort: {
        prop: 'displayName',
        dir: SortTypes.ASC,
      },
      search: '',
    },
  },
  domains: {
    ids: [],
    filters: {
      sort: {
        prop: 'name',
        dir: SortTypes.ASC,
      },
      search: '',
    },
  },
  companies: {
    ids: [],
    filters: {
      sort: {
        prop: 'name',
        dir: SortTypes.ASC,
      },
      search: '',
    },
  },
  users: {
    ids: [],
    companyGroupUserIds: [],
    filters: {
      sort: {
        prop: 'displayName',
        dir: SortTypes.ASC,
      },
      search: '',
    },
  },
  linkedCompanies: {
    filters: {
      sort: {
        prop: 'name',
        dir: SortTypes.ASC,
      },
      search: '',
    },
  },
};

export default (
  state: HomeViewState = initialState,
  action: Action = null,
): HomeViewState => {
  switch (action?.type) {
    case Actions.SET_DOMAINS:
      return {
        ...state,
        domains: { ...state.domains, ids: action.domainsIds },
      };
    case Actions.SET_DOMAIN:
      return {
        ...state,
        domains: {
          ...state.domains,
          ids: uniq([...state.domains.ids, action.domainId]),
        },
      };
    case Actions.SET_SYSTEM_ADMINS:
      return {
        ...state,
        systemAdmins: { ...state.systemAdmins, ids: action.adminsIds },
      };
    case Actions.SET_SYSTEM_ADMIN:
      return {
        ...state,
        systemAdmins: {
          ...state.systemAdmins,
          ids: uniq([...state.systemAdmins.ids, action.adminId]),
        },
      };
    case Actions.SET_DOMAIN_ADMINS:
      return {
        ...state,
        domainAdmins: {
          ...state.domainAdmins,
          ids: action.domainAdminsIds,
        },
      };
    case Actions.SET_DOMAIN_ADMIN:
      return {
        ...state,
        domainAdmins: {
          ...state.domainAdmins,
          ids: uniq([...state.domainAdmins.ids, action.domainAdminId]),
        },
      };
    case Actions.SET_COMPANIES:
      return {
        ...state,
        companies: { ...state.companies, ids: action.companiesIds },
      };
    case Actions.SET_COMPANY:
      return {
        ...state,
        companies: {
          ...state.companies,
          ids: uniq([...state.companies.ids, action.companyId]),
        },
      };
    case Actions.SET_USERS:
      return {
        ...state,
        users: { ...state.users, ids: action.usersIds },
      };
    case Actions.SET_USER:
      return {
        ...state,
        users: {
          ...state.users,
          ids: uniq([...state.users.ids, action.userId]),
        },
      };
    case Actions.DELETE_USER: {
      return {
        ...state,
        users: {
          ...state.users,
          ids: state.users.ids.filter((id) => id !== action.userId),
        },
      };
    }
    case Actions.SET_COMPANY_GROUP_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          companyGroupUserIds: action.companyGroupUserIds,
        },
      };
    case Actions.SET_VIEW_FILTERS: {
      const newState = { ...state };
      if (action.view === AdminView.System) {
        if (action.subView === 'users') {
          newState.systemAdmins.filters = {
            ...newState.systemAdmins.filters,
            ...action.filters,
          };
        } else {
          newState.domains.filters = {
            ...newState.domains.filters,
            ...action.filters,
          };
        }
      } else if (action.view === AdminView.Domain) {
        if (action.subView === 'users') {
          newState.domainAdmins.filters = {
            ...newState.domainAdmins.filters,
            ...action.filters,
          };
        } else
          newState.companies.filters = {
            ...newState.companies.filters,
            ...action.filters,
          };
      } else if (action.view === AdminView.Company) {
        if (action.subView === 'users') {
          newState.users.filters = {
            ...newState.users.filters,
            ...action.filters,
          };
        } else {
          newState.linkedCompanies.filters = {
            ...newState.linkedCompanies.filters,
            ...action.filters,
          };
        }
      }
      return {
        ...newState,
      };
    }

    default:
      return state;
  }
};
