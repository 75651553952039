type IconSizes = {
  iconXS: number;
  iconS: number;
  iconM: number;
  iconL: number;
  iconXL: number;
};

interface Sizes extends IconSizes {
  spacing0000: number;
  spacing0125: number;
  spacing0250: number;
  spacing0500: number;
  spacing0750: number;
  spacing1000: number;
  spacing1500: number;
  spacing2000: number;
  spacing2500: number;
  spacing3000: number;
  spacing3500: number;
  spacing4000: number;
}

export const iconSizes: IconSizes = {
  iconXS: 16,
  iconS: 20,
  iconM: 24,
  iconL: 32,
  iconXL: 48,
};

const sizes: Sizes = {
  spacing0000: 0,
  spacing0125: 2,
  spacing0250: 4,
  spacing0500: 8,
  spacing0750: 12,
  spacing1000: 16,
  spacing1500: 24,
  spacing2000: 32,
  spacing2500: 40,
  spacing3000: 48,
  spacing3500: 56,
  spacing4000: 64,
  ...iconSizes,
};

export default sizes;
