import { UserPersitedDataKey } from '../types';

export function getUserData(userId: string, key: UserPersitedDataKey) {
  return {
    method: 'get_user_data',
    params: {
      user_id: userId,
      key,
    },
  };
}
