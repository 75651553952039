import { SignupParams, SaveBusinessLeadParams } from './types';
import {
  CompanyActivationData,
  CompanyActivationOwner,
} from '../../persisted/types';
import { getClient, makePayload } from '../../../utils';
import {
  Company,
  CompanyUser,
  normalizeCompany,
  normalizeCompanyNameVerification,
  normalizeUser,
  superagent,
} from '@neo1/client';

export function saveBusinessLead(params: SaveBusinessLeadParams) {
  return getClient().sendCommand({
    method: 'save_business_lead',
    params: {
      email: params.email,
      recaptcha: params.recaptcha,
      country_code: params.countryCode,
      ...params.utmParams,
    },
  });
}

export function signup({
  captcha,
  eulaVersion,
  countryCode,
  companyLocale,
  companyName,
  ownerEmail,
  ownerFirstName,
  ownerLastName,
  ownerLogin,
  ownerPassword,
  ownerTitle,
  template,
  privacyPolicy,
  website,
  ownerJob,
  ownerPhoneNumber,
  utmParams,
}: SignupParams): Promise<{
  owner: CompanyUser;
  company: Company;
}> {
  return getClient()
    .sendCommand({
      method: 'signup',
      params: {
        country_code: countryCode,
        email: ownerEmail,
        first_name: ownerFirstName,
        last_name: ownerLastName,
        locale: companyLocale,
        login: ownerLogin,
        name: companyName,
        password: ownerPassword,
        recaptcha: captcha,
        template_name: template,
        title: ownerTitle,
        eula_version: eulaVersion,
        privacy_policy: privacyPolicy,
        website: website,
        position: ownerJob,
        phone_number: ownerPhoneNumber,
      },
    })
    .then((result) => {
      superagent
        .head('/utils/clt')
        .query({ company_name: encodeURIComponent(companyName) })
        .query({ action: 'signup' })
        .query(utmParams)
        .end();

      return {
        owner: normalizeUser(result.owner) as CompanyUser,
        company: normalizeCompany(result.company),
      };
    });
}

export function activateUser(activationToken: string): Promise<string> {
  return getClient().sendCommand({
    method: 'activate_user',
    params: { token: activationToken },
  });
}

export function activateCompany({
  id,
  registrationNumber,
  registrationNumberType,
  numberOfEmployee,
  yearOfCreation,
  companyKind,
  tradeName,
  name,
  industry,
  vatNumber,
  address,
  address2,
  zipCode,
  city,
  state,
  hasOwners,
  owners,
  country,
  expenseFeatureUsage,
  purchasingFeatureUsage,
  travelFeatureUsage,
  contactEmail,
  phoneNumber,
}: CompanyActivationData): Promise<Company> {
  const mapOwnerPayload = (owner: CompanyActivationOwner) =>
    makePayload({
      first_name: owner.firstName,
      last_name: owner.lastName,
      date_of_birth: owner.birthDate,
      country_of_birth_code: owner.countryOfBirth,
      address: makePayload(
        {
          street_line_1: owner.street,
          street_line_2: null,
          zip_code: owner.zipCode,
          city: owner.city,
          state: owner.state,
          country_code: owner.country,
          name: null,
        },
        { state: null },
      ),
    });

  const companyNumberPayload = registrationNumber
    ? {
        number: registrationNumber,
        number_type: registrationNumberType,
      }
    : null;

  const ownersPayload = hasOwners ? owners.map(mapOwnerPayload) : [];

  return getClient()
    .sendCommand({
      method: 'activate_company',
      params: makePayload(
        {
          company_number: companyNumberPayload,
          number_of_employee: numberOfEmployee,
          year_of_creation: yearOfCreation,
          kind: companyKind,
          trade_name: tradeName,
          name: name,
          industry,
          vat_number: vatNumber,
          company_id: id,
          address: {
            name,
            street_line_1: address,
            street_line_2: address2 || null,
            zip_code: zipCode,
            city,
            state: state || null,
            country_code: country,
          },
          owners: ownersPayload,
          expense_feature_usage: expenseFeatureUsage,
          purchasing_feature_usage: purchasingFeatureUsage,
          travel_feature_usage: travelFeatureUsage,
          contact_email: contactEmail,
          phone_number: phoneNumber,
        },
        {
          company_number: null,
          trade_name: null,
          vat_number: null,
        },
      ),
    })
    .then(normalizeCompany);
}

export async function verifyCompanyName(name: string) {
  return getClient()
    .sendCommand({
      method: 'verify_company_name',
      params: {
        name,
      },
    })
    .then(normalizeCompanyNameVerification);
}
