import get from 'lodash/get';
import { isValidAddress } from '../../addresses/utils';
import { getClient } from '../../../utils/client';
import { UserPersitedDataKey, UserPersistedData } from '../types';
import * as payload from './payload';

export function setUserData(userId: string, key: string, data: any) {
  return getClient().sendCommand({
    method: 'set_user_data',
    params: {
      user_id: userId,
      key,
      data: JSON.stringify(data),
    },
  });
}

export function deleteUserData(userId: string, key: string, data: any) {
  return getClient().sendCommand({
    method: 'delete_user_data',
    params: {
      user_id: userId,
      key,
      data: JSON.stringify(data),
    },
  });
}

/**
 *
 * @param key
 * @param data
 */
export const isUserDataValid = (key: UserPersitedDataKey, data: any) => {
  if (key === 'amazonShippingAddress') {
    return isValidAddress(data);
  }
  return true;
};

export async function getUserData(userId: string): Promise<UserPersistedData> {
  const userKeys: UserPersitedDataKey[] = [
    'companyActivation',
    'reportTemplates',
    'amazonShippingAddress',
    'dashboardLayouts',
  ];

  const results = await getClient().batchCommands(
    userKeys.map((k) => payload.getUserData(userId, k)),
  );

  return results.reduce((userData, r, index) => {
    const key = userKeys[index];
    const data = get(r, 'result.data');

    if (data === null || !isUserDataValid(key, data)) {
      return userData;
    }

    return {
      ...userData,
      [key]: data,
    };
  }, {});
}
