import { Address, UUID } from '@neo1/client';

export enum Action {
  SET_ADDRESS = 'one/profile/SET_ADDRESS',
  SET_ADDRESSES = 'one/profile/SET_ADDRESSES',
  DELETE_ADDRESS = 'one/profile/DELETE_ADDRESS',
}

export type CreateAddressPayload = {
  zip_code: string;
  city: string;
  name: string;
  state: string;
  country_code: string;
  street_line_1: string;
  street_line_2: string;
};

export type UpdateAddressPayload = {
  address_id: string;
  zip_code: string;
  city: string;
  name: string;
  state: string;
  country_code: string;
  street_line_1: string;
  street_line_2: string;
};

export type AddressResults = {
  addresses: Address[];
  defaultHomeAddress: Address;
};

export type SetAddressAction = {
  type: typeof Action.SET_ADDRESS;
  id: UUID;
};

export type SetAddressesAction = {
  type: typeof Action.SET_ADDRESSES;
  ids: UUID[];
};

export type DeleteAddressAction = {
  type: typeof Action.DELETE_ADDRESS;
  id: UUID;
};

export type AddressAction =
  | SetAddressAction
  | SetAddressesAction
  | DeleteAddressAction;

export type AddressState = {
  ids: UUID[];
};
