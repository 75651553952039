import { UUID } from '../common';
import { User, UserLevel } from '../user';
import { Purchase } from '.';

// Tx reject sources
export const REJECT_SOURCE_USER = 'user';
export const REJECT_SOURCE_SYSTEM = 'system';

// Tx reject reasons
export const INVALID_RECEIPT = 'invalid_receipt';
export const INCORRECT_FINANCIAL_CODING = 'incorrect_financial_coding';
export const OUT_OF_POLICY = 'out_of_policy';
export const POLICY_CHANGED = 'policy_changed';
export const SUPERVISOR_NOT_ACTIVE = 'supervisor_not_active';

const INVALID_RECEIPT_LABEL = 'Invalid receipt';
const INCORRECT_FINANCIAL_CODING_LABEL = 'Incorrect financial coding';
const OUT_OF_POLICY_LABEL = 'Out of policy';
const POLICY_CHANGED_LABEL = 'Accounting policy changed';
const SUPERVISOR_NOT_ACTIVE_LABEL = 'Supervisor has been disabled';

export const PURCHASE_REJECT_REASONS = {
  [INVALID_RECEIPT]: INVALID_RECEIPT_LABEL,
  [INCORRECT_FINANCIAL_CODING]: INCORRECT_FINANCIAL_CODING_LABEL,
  [OUT_OF_POLICY]: OUT_OF_POLICY_LABEL,
  [POLICY_CHANGED]: POLICY_CHANGED_LABEL,
  [SUPERVISOR_NOT_ACTIVE]: SUPERVISOR_NOT_ACTIVE_LABEL,
};

export type PurchaseRejectionReason =
  | 'incorrect_financial_coding'
  | 'invalid_receipt'
  | 'out_of_policy'
  | 'policy_changed'
  | 'supervisor_not_active'
  | 'other';

export enum PurchaseStatusCode {
  InProgress = 'in_progress',
  WaitingforApproval = 'waiting_for_approval',
  Approved = 'approved',
  Rejected = 'rejected',
  Frozen = 'frozen',
  Canceled = 'canceled',
}

type SupervisedBy = {
  supervisorId: UUID;
  hasApproved?: boolean;
};

enum RejectedBySource {
  user = 'user',
  system = 'system',
}

type RejectedBy = {
  source: RejectedBySource;
  userId: UUID;
  userFullName: string;
};

export type PurchaseStatus = {
  code: PurchaseStatusCode;
  since?: string;
  reason?: PurchaseRejectionReason;
  comment?: string;
  supervisionData?: SupervisedBy[];
  rejectedBy?: RejectedBy;
};

export const normalizeSupervisionData = (data: any): SupervisedBy => {
  const { supervisor_id: supervisorId, has_approved: hasApproved = undefined } =
    data;
  return { supervisorId, hasApproved };
};

const normalizeRejectedBy = (data: any): RejectedBy => {
  const { source, user_id: userId, user_full_name: userFullName } = data;
  return { source, userId, userFullName };
};

export const normalizePurchaseStatus = (data: any): PurchaseStatus => {
  const {
    code,
    since,
    reason,
    comment,
    supervised_by: supervisedBy,
    approved_by: approvedBy,
    rejected_by: rejectedBy,
  } = data;

  const supervisionData = supervisedBy || approvedBy || [];

  return {
    code,
    since,
    reason,
    comment,
    supervisionData: (supervisionData || []).map(normalizeSupervisionData),
    rejectedBy: rejectedBy && normalizeRejectedBy(rejectedBy),
  };
};

/**
 * Asserts if purchase is in progress
 * @param purchase
 */
export const isInProgressPurchase = (purchase: Purchase) =>
  purchase.status.code === PurchaseStatusCode.InProgress;

/**
 * Asserts if purchase is active
 * @param purchase
 */
export const isActivePurchase = (purchase: Purchase) =>
  purchase.status.code === PurchaseStatusCode.Approved;

/**
 * Asserts if purchase is waiting approval
 * @param purchase
 */
export const isWaitingApprovalPurchase = (purchase: Purchase) =>
  purchase.status.code === PurchaseStatusCode.WaitingforApproval;

/**
 * Asserts if purchase is rejected
 * @param purchase
 */
export const isRejectedPurchase = (purchase: Purchase) =>
  purchase.status.code === PurchaseStatusCode.Rejected;

/**
 * Asserts if purchase is frozen
 * @param purchase
 */
export const isFrozenPurchase = (purchase: Purchase) =>
  purchase.status.code === PurchaseStatusCode.Frozen;

export const isCanceledPurchase = (purchase: Purchase) =>
  purchase.status.code === PurchaseStatusCode.Canceled;

/**
 * Asserts if purchase is asking the specific user's supervision
 * @param purchase
 */
export const isPurchaseSupervisedByUser =
  (user: User) => (purchase: Purchase) => {
    const isPriviledgedCompanyUser =
      user.level === UserLevel.COMPANY && user.roles.length > 0;
    const isPurchaseSupervisor = purchase.status.supervisionData.some(
      ({ supervisorId }) => user.id === supervisorId,
    );
    const isSupervisable =
      isActivePurchase(purchase) || isWaitingApprovalPurchase(purchase);
    return isSupervisable && (isPriviledgedCompanyUser || isPurchaseSupervisor);
  };

/**
 * Asserts if purchase is waiting approval of a specific user
 * @param purchase
 */
export const isPurchaseWaitingApprovalFrom =
  (user: User) => (purchase: Purchase) =>
    isWaitingApprovalPurchase(purchase) &&
    purchase.status.supervisionData.some(
      ({ supervisorId, hasApproved }) =>
        user.id === supervisorId && !hasApproved,
    );

/**
 * Asserts if purchase is owned by a specific user
 * @param purchase
 */
export const isPurchaseOwnedBy = (user: User) => (purchase: Purchase) =>
  purchase.ownerId === user.id;

/**
 * Asserts if purchase is read only
 * @param purchase
 */
export const isReadOnlyPurchase = (purchase: Purchase) =>
  purchase.status.code === PurchaseStatusCode.WaitingforApproval ||
  purchase.status.code === PurchaseStatusCode.Approved ||
  purchase.status.code === PurchaseStatusCode.Frozen ||
  purchase.status.code === PurchaseStatusCode.Canceled;
