import classNames from 'classnames';
import FormLabel from 'components/elements/form/Label';
import Icon, { IconName } from 'components/elements/Icon';
import RadioGroupTimeline, {
  Props as RadioGroupTimelineProps,
} from 'components/composed/RadioGroupTimeline';
import FieldTooltip from 'components/elements/form/FieldTooltip';
import {
  COMPANY_FEATURE_USAGE_LABELS,
  COMPANY_FEATURE_USAGE_VALUES,
} from '../constants';
import styles from './FeatureUsage.module.css';

interface Props extends Omit<RadioGroupTimelineProps, 'values'> {
  label: string;
  iconName: IconName;
  error?: string;
  showLabelsOnTop?: boolean;
}

const { container, labelContainer, iconContainer, errorWarning, hideWarning } =
  styles;

function FeatureUsage({
  label,
  iconName,
  error,
  showLabelsOnTop,
  name,
  ...props
}: Props) {
  return (
    <div className={styles.outerContainer}>
      {showLabelsOnTop && (
        <div className={styles.labelsOnTopContainer}>
          <div className={styles.labelContainer} />

          <div className={styles.radioButtonLabels}>
            {COMPANY_FEATURE_USAGE_VALUES.map((v) => (
              <div key={v} className={styles.radioButtonLabel}>
                {COMPANY_FEATURE_USAGE_LABELS[v]}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={container}>
        <div className={labelContainer}>
          <div className={iconContainer}>
            <Icon name={iconName} />
          </div>
          <FormLabel title={label} className={styles.label} />

          <FieldTooltip
            type="error"
            message={error}
            className={classNames(errorWarning, { [hideWarning]: !error })}
            testId={`${name}ErrorWarning`}
          />
        </div>
        <RadioGroupTimeline
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          name={name}
          values={COMPANY_FEATURE_USAGE_VALUES.map(String)}
          className={styles.radioGroup}
        />
      </div>
    </div>
  );
}

FeatureUsage.defaultProps = {
  error: undefined,
  showLabelsOnTop: false,
};

export default FeatureUsage;
